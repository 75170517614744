<template>
  <div class="d-flex align-center">
    <v-btn icon @click="openDialog = true" color="transparent" flat size="small">
      <v-icon color="primary"> mdi-help-circle </v-icon>
    </v-btn>
    <div
      class="rounded-pill bg-grey-lighten-3 flex-grow-1 flex-shrink-1"
      :style="`
        width: 100%;
        height: 18px;
        box-shadow: inset -2px 3px 2px rgba(0, 0, 0, 0.2);
        overflow: hidden;`"
    >
      <div class="bg-grey rounded-pill" style="width: 1%; height: 100%"></div>
    </div>
    <GenericDialog :activator="openDialog" @close="openDialog = false">
      <v-card class="pa-5" flat color="transparent">
        <v-card-text>
          Looks like we do not have any valid data for your vehicles state of charge.

          <ul style="list-style: none; padding-left: 0" class="pt-5 pb-2">
            <li class="text-subtitle-2">Common reasons for this:</li>
            <li>Your vehicle dose not have a telematics device installed.</li>
            <li>Your telematics provider does not support state of charge data.</li>
            <li>
              Your vehicles last known state of charge is invalid/in a format that was not expected.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </GenericDialog>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import GenericDialog from '../../dialog-wrappers/GenericDialog.vue'

const openDialog = ref(false)
</script>
