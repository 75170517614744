<template>
  <GenericDialog :activator="activator" @close="() => emit('close')">
    <v-card-title> Add new favourite location </v-card-title>
    <FavLocationForm @close="() => emit('close')" />
  </GenericDialog>
</template>
<script lang="ts" setup>
import GenericDialog from '../dialog-wrappers/GenericDialog.vue'
import FavLocationForm from './FavLocationForm.vue'

const { activator } = defineProps<{ activator: boolean }>()
const emit = defineEmits<{ (e: 'close'): void }>()
</script>
