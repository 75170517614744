<template>
  <v-card
    outlined
    class="mb-2 rounded-lg elevation-2 bg-grey-lighten-3"
    max-width="100%"
    @click="openVehicleDetails"
  >
    <v-row no-gutters>
      <v-col cols="1">
        <v-img class="my-3 ml-2" contain max-width="50" :src="imageSrc"></v-img>
      </v-col>
      <v-col cols="11" class="d-flex justify-space-between align-center">
        <div>
          <v-card-title class="pt-1"> {{ displayName }} </v-card-title>
          <v-card-subtitle class="pb-2">
            {{ vehicle.evModel ? vehicle.evModel.name : 'model not specified' }}
          </v-card-subtitle>
        </div>
        <div>
          <v-btn
            v-if="selected"
            color="primary"
            class="text-none rounded-lg mr-2"
            disabled
            style="min-width: 101px"
          >
            Selected
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="text-none rounded-lg mr-2"
            style="min-width: 101px"
            @click.stop="select"
          >
            Select
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { ActionTypes, MainDialogContent, MutationTypes } from '@/store/store_types'
import { useDisplay } from 'vuetify'

const store = useStore()
const { smAndDown } = useDisplay()

const { selected, vehicle } = defineProps<{ selected: boolean; vehicle: Vehicle }>()

const displayName = computed(() => {
  if (vehicle.licensePlate) return vehicle.licensePlate
  if (vehicle.name) return vehicle.name
  if (smAndDown) return 'no license plate'
  return 'unspecified license plate'
})

const imageSrc = computed(() => store.state.company?.fleet?.logoSrc)

const SOHVal = ref(50)

const select = () => {
  store.dispatch(ActionTypes.selectVehicle, vehicle)
}

const setInitialValues = (vehicle: Vehicle) => {
  SOHVal.value = vehicle.stateOfHealth ? vehicle.stateOfHealth * 100 : 50
}

const openVehicleDetails = () => {
  store.commit(MutationTypes.setViewedFleetVehicle, vehicle.localId)
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.FLEET_VEHICLE_DETAILS)
}

onMounted(() => {
  if (vehicle) {
    setInitialValues(vehicle)
  }
})
</script>
