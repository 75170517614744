<template>
  <v-dialog
    v-model="showDialog"
    :width="mdAndUp ? '70%' : '80%'"
    :content-class="xs ? '' : 'rounded-lg'"
    style="z-index: 1200"
    @click:outside="close"
    @keydown="keyDown($event)"
    @after-leave="clear"
    scrollable
    :max-width="mdAndUp ? '800px' : '650px'"
    :min-width="mdAndUp ? '600px' : '350px'"
    :fullscreen="xs"
    :max-height="mdAndUp ? '90vh' : undefined"
  >
    <v-card
      class="overflow-hidden pb-5 rounded-lg bg-background"
      :style="xs ? 'min-height: 100vh;' : 'min-height: 85vh;'"
    >
      <!-- conditionally rendered tool bar -->
      <v-toolbar
        color="background"
        flat
        v-if="
          // mainDialogContent !== 'charging station details' &&
          mainDialogContent !== 'fleetVehicleDetails' &&
          mainDialogContent !== 'vehicle settings' &&
          mainDialogContent !== 'vehicle booking' &&
          mainDialogContent !== 'vehicle gallery custom' &&
          mainDialogContent !== 'vehicle gallery connected' &&
          mainDialogContent !== 'vehicle gallery generic'
        "
        extension-height="24"
      >
        <!-- conditionally rendered icons -->
        <v-icon v-if="!!icon" class="mx-3" color="primary" large>{{ icon }}</v-icon>

        <!-- conditionally rendered titles -->
        <h2 v-if="title" class="text-primary font-weight-regular">
          {{ title }}
        </h2>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()" class="align-self-start">
          <v-icon color="grey-lighten-1">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden px-2 px-sm-0"
        :style="
          mainDialogContent !== 'charging station details' &&
          mainDialogContent !== 'fleetVehicleDetails' &&
          mainDialogContent !== 'vehicle settings' &&
          mainDialogContent !== 'vehicle booking' &&
          mainDialogContent !== 'vehicle gallery custom' &&
          mainDialogContent !== 'vehicle gallery connected' &&
          mainDialogContent !== 'vehicle gallery generic'
            ? 'height: calc(100% - 64px)'
            : 'height: 100%'
        "
      >
        <!-- conditionally rendered content -->
        <ChargingStationDetailsContent v-if="mainDialogContent === 'charging station details'" />
        <AllVehiclesGalleryContent v-if="mainDialogContent === 'vehicle gallery'" />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery custom'"
          :type="VehicleGalleryType.CUSTOM_VEHICLES"
        />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery connected'"
          :type="VehicleGalleryType.CONNECTED_VEHICLES"
        />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery generic'"
          :type="VehicleGalleryType.GENERIC_VEHICLES"
        />
        <FleetVehicleDetailsContent v-if="mainDialogContent === 'fleetVehicleDetails'" />
        <AddVehicleContent v-if="mainDialogContent === 'add vehicle'" />
        <VehicleSettingsContent v-if="mainDialogContent === 'vehicle settings'" />
        <!-- <VehicleBookingContent v-if="mainDialogContent === 'vehicle booking'" /> -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ChargingStationDetailsContent from '../charging-stations/ChargingStationDetailsContent.vue'
import VehicleGalleryContent from '../vehicles/VehicleGalleryContent.vue'
import AllVehiclesGalleryContent from '../vehicles/AllVehiclesGalleryContent.vue'
import FleetVehicleDetailsContent from '../vehicles/VehicleDetailsContent.vue'
import AddVehicleContent from '../vehicles/AddVehicleContent.vue'
import VehicleSettingsContent from '../vehicles/VehicleSettingsContent.vue'
// import VehicleBookingContent from '../vehicles/VehicleBookingContent.vue'
import { MainDialogContent, MutationTypes, VehicleGalleryType } from '@/store/store_types'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { useDisplay } from 'vuetify'

const store = useStore()
const { xs, mdAndUp } = useDisplay()
const showDialog = ref(false)
const mainDialogContent = computed(() => store.state.mainDialogContent)
const title = computed(() => {
  switch (store.state.mainDialogContent) {
    case MainDialogContent.VEHICLE_GALLERY:
      return 'Vehicle Categories'
    case MainDialogContent.ADD_VEHICLE:
      return 'Add a new custom vehicle'
    default:
      return undefined
  }
})

const icon = computed(() => {
  switch (store.state.mainDialogContent) {
    case MainDialogContent.VEHICLE_GALLERY:
    case MainDialogContent.ADD_VEHICLE:
      return 'custom:vehiclesIcon'
    default:
      return undefined
  }
})

const clear = () => {
  store.commit(MutationTypes.setMainDialogContent, undefined)
}

function close() {
  showDialog.value = false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const keyDown = (event: any) => {
  if (event.key === 'Escape') close()
}

watch(mainDialogContent, (val: MainDialogContent) => {
  if (val) {
    showDialog.value = true
    // Notify analytics server
    notifyAnalytics({
      type: 'track_pageview',
      data: val,
    })
  } else {
    close()
  }
})
</script>

<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
