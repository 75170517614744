<template>
  <v-form ref="favLocationForm" v-if="!status">
    <v-card-text class="pb-6 px-6">
      <p class="text-grey text-h6">Location Details</p>
      <v-text-field
        label="Name"
        v-model="name"
        clearable
        :error-messages="nameErrorMsg"
        @change="validateName"
      />
      <AddressAutocompleteInput
        :initialValue="address"
        @update="updateAddress"
        id="favLocationAddress"
        :errorMsg="addressErrorMsg"
        class="mb-5"
        label="Address"
      />
      <v-select
        v-model="locationType"
        label="Location Type"
        :items="['Work', 'Home', 'Depot', 'Other']"
      ></v-select>
      <v-select
        v-model="visibility"
        lang="Visibility"
        :items="['private', 'group', 'fleet']"
      ></v-select>
      <p class="text-grey text-h6">Trip Planning Defaults</p>
      <v-text-field
        v-model="weightChange"
        label="load Weight Change"
        suffix="kg"
        type="number"
      ></v-text-field>
      <v-slider
        min="0"
        max="1"
        step="0.01"
        :label="'Idle energy used ' + energyUsed + 'kWh'"
        v-model="energyUsed"
        track-color="grey-lighten-2"
        color="primary"
        class="mt-2"
        thumb-size="16"
        track-size="6"
      />
      <v-row no-gutters align="center">
        <span class="mr-2"> Normal stay duration </span>
        <StayDurationInput
          :initialValue="stayDuration"
          :identifier="initialValue ? initialValue.localId : ''"
          @update="handleDurationChange"
        />
      </v-row>
      <v-checkbox label="charge here" v-model="chargeHere" class="mt-5"></v-checkbox>
      <v-row no-gutters>
        <v-text-field
          v-model="rating"
          label="rating"
          :disabled="!chargeHere"
          type="number"
          class="mr-5"
          :error-messages="ratingErrorMsg"
        ></v-text-field>
        <v-select
          v-model="currentType"
          label="current type"
          :items="['AC', 'DC']"
          :disabled="!chargeHere"
          :error-messages="currentTypeErrorMsg"
        ></v-select>
      </v-row>
      <v-slider
        min="0"
        max="100"
        step="1"
        :label="'Normal charge after charging ' + SOCAfterCharging + '%'"
        :disabled="!chargeHere"
        v-model="SOCAfterCharging"
        track-color="grey-lighten-2"
        color="primary"
        thumb-size="16"
        track-size="6"
      />
    </v-card-text>
    <v-card-actions class="px-6">
      <ElevatedBlockBtn @click="saveFavLocation"> Save as favourite Location </ElevatedBlockBtn>
    </v-card-actions>
  </v-form>
  <v-alert type="success" v-else-if="status === 'SUCCESS'" class="mx-2">
    Favourite location was created successfully and saved to the database
  </v-alert>
  <v-alert type="error" v-else-if="status === 'FAILED'" class="mx-2">
    Favourite location was created locally but failed to be saved to the database
  </v-alert>
</template>
<script lang="ts" setup>
import AddressAutocompleteInput, {
  type AddressAutocompleteInputUpdateObj,
} from '../ui-elements/inputs/AddressAutocompleteInput.vue'
import FavouriteLocation, { type PlanningData } from '@/classes/favouriteLocation'
import { type processedAddressObj } from '@/utils/processAddressSearchResults'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'
import { MutationTypes } from '@/store/store_types'
import Coordinate from '@/classes/common_classes/coordinate'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import StayDurationInput, {
  type StayDurationInputUpdateObj,
} from '../ui-elements/inputs/StayDurationInput.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits<{
  (e: 'update', value: FavouriteLocation): void
  (e: 'close'): void
}>()

const { initialValue } = defineProps<{
  initialValue?: FavouriteLocation | undefined
}>()

const store = useStore()

const status = ref<'PROCESSING' | 'SUCCESS' | 'FAILED' | null>(null)
// form data
const name = ref<string | null | undefined>(undefined)
const nameErrorMsg = ref<string | null>(null)
const address = ref<processedAddressObj | undefined>(undefined)
const addressErrorMsg = ref<string | null>(null)
const chargeHere = ref(false)
const rating = ref<number | string | null>(null)
const ratingErrorMsg = ref<string | null>(null)
const currentType = ref<'AC' | 'DC' | null>(null)
const currentTypeErrorMsg = ref<string | null>(null)
const weightChange = ref<number | string | null>(null)
const locationType = ref<'Work' | 'Home' | 'Depot' | 'Other'>('Home')
const visibility = ref<'private' | 'group' | 'fleet'>('private')
const SOCAfterCharging = ref(0)
const energyUsed = ref(0)
const stayDuration = ref(0)

onMounted(() => {
  if (initialValue) {
    name.value = initialValue.name
    address.value = {
      address: initialValue.address ?? 'Unknown Address',
      coordinates: initialValue.coordinates.asCapitalizedObj,
    }
    chargeHere.value = initialValue.planningData?.chargeHere ?? false
    rating.value = initialValue.planningData?.rating ?? null
    weightChange.value = initialValue.planningData?.loadWeightChange ?? null
    currentType.value = initialValue.planningData?.currentType ?? null
  }
})

onBeforeUnmount(() => {
  name.value = undefined
  nameErrorMsg.value = null
  address.value = undefined
  addressErrorMsg.value = null
  chargeHere.value = false
  rating.value = null
  ratingErrorMsg.value = null
  currentType.value = null
  currentTypeErrorMsg.value = null
  weightChange.value = null
  status.value = null
  SOCAfterCharging.value = 0
  energyUsed.value = 0
  stayDuration.value = 0
})

// validation methods

/**
 * Validates the name input and sets an error message if the name is empty.
 *
 * @return {boolean} Returns true if the name is not empty, false otherwise.
 */
function validateName(): boolean {
  nameErrorMsg.value = null
  if (!name.value) {
    nameErrorMsg.value = 'Please enter a name'
  }
  return !!name.value
}

/**
 * Validates the address input and sets an error message if the address is empty.
 *
 * @return {boolean} Returns true if the address is not empty, false otherwise.
 */
function validateAddress(): boolean {
  addressErrorMsg.value = null
  if (!address.value) {
    addressErrorMsg.value = 'Please enter an address'
  }
  return !!address.value
}

/**
 * Validates the rating based on the charge selection.
 *
 * @return {boolean} Returns true if the rating is provided, false otherwise.
 */
function validateRating(): boolean {
  ratingErrorMsg.value = null
  if (!chargeHere.value)
    return true // rating is not required if charge here is not selected.
  else if (!rating.value) {
    ratingErrorMsg.value = 'Please enter a rating if charging here'
  } else if (parseIntOrFloat(rating.value) == undefined) {
    ratingErrorMsg.value = 'Rating must be a number'
  }
  return !!rating.value
}

/**
 * A description of the entire function.
 *
 * @return {boolean} description of return value
 */
function validateCurrentType(): boolean {
  currentTypeErrorMsg.value = null
  if (!chargeHere.value)
    return true // current type is not required if charge here is not selected.
  else if (!currentType.value) {
    currentTypeErrorMsg.value = 'Please enter a current type if charging here'
  } else if (!['AC', 'DC'].includes(currentType.value)) {
    currentTypeErrorMsg.value = 'Current type must be AC or DC'
    return false
  }
  return !!currentType.value
}

// event handlers

function handleDurationChange(val: StayDurationInputUpdateObj) {
  stayDuration.value = val.duration
}

/**
 * Updates the address based on the input data and validates the address.
 *
 * @param {AddressAutocompleteInputUpdateObj} address - The updated address data.
 * @return {void} This function does not return anything.
 */
function updateAddress(addressValue: AddressAutocompleteInputUpdateObj): void {
  address.value = addressValue.addressData ?? undefined
  validateAddress()
}

/**
 * Resets the name, address, and error messages to default values and emits a close event after a delay.
 *
 * @return {void} This function does not return anything.
 */
function closeAndReset(): void {
  name.value = undefined
  nameErrorMsg.value = null
  address.value = undefined
  addressErrorMsg.value = null
  chargeHere.value = false
  rating.value = null
  ratingErrorMsg.value = null
  currentType.value = null
  currentTypeErrorMsg.value = null
  weightChange.value = null
  setTimeout(() => {
    emit('close')
    status.value = null
  }, 1000)
}

/**
 * Saves the favourite location after validating the name and address inputs.
 *
 * @return {Promise<void>} This function does not return anything.
 */
async function saveFavLocation(): Promise<void> {
  status.value = 'PROCESSING'
  let isValid = false
  isValid = validateName()
  isValid = validateAddress()
  isValid = validateRating()
  isValid = validateCurrentType()
  if (!isValid) {
    status.value = null
    return
  }
  let newFavLocation: FavouriteLocation | undefined = undefined
  const newFormattedName = name.value!.trim() // already validated above
  const newFormattedAddress = address.value!.address // already validated above
  const newLatitude = address.value?.coordinates.Latitude
  const newLongitude = address.value?.coordinates.Longitude
  const newCoordinates =
    newLatitude && newLongitude
      ? new Coordinate({ latitude: newLatitude, longitude: newLongitude })
      : undefined
  if (!newCoordinates) {
    return
  }

  let newPlanningData: PlanningData | undefined = undefined
  // add charging data if needed
  if (chargeHere.value) {
    // assumes that if charge here is true, then rating is also true
    newPlanningData = {
      chargeHere: chargeHere.value,
      rating: parseIntOrFloat(rating.value!), // already validated above
      currentType: currentType.value!, // already validated above
      SOCAfterCharging: SOCAfterCharging.value
        ? parseIntOrFloat(SOCAfterCharging.value)
        : undefined,
    }
  }

  if (energyUsed.value) {
    if (newPlanningData) {
      newPlanningData.energyUsed = parseIntOrFloat(energyUsed.value)
    } else {
      newPlanningData = {
        energyUsed: parseIntOrFloat(energyUsed.value),
      }
    }
  }

  if (weightChange.value) {
    if (newPlanningData) {
      newPlanningData.loadWeightChange = parseIntOrFloat(weightChange.value)
    } else {
      newPlanningData = {
        loadWeightChange: parseIntOrFloat(weightChange.value),
      }
    }
  }

  if (stayDuration.value) {
    if (newPlanningData) {
      newPlanningData.stayDuration = parseIntOrFloat(stayDuration.value)
    } else {
      newPlanningData = {
        stayDuration: parseIntOrFloat(stayDuration.value),
      }
    }
  }

  if (initialValue && newCoordinates) {
    newFavLocation = initialValue!
    newFavLocation.name = newFormattedName
    newFavLocation.address = newFormattedAddress
    newFavLocation.coordinates = newCoordinates
    newFavLocation.planningData = newPlanningData
    newFavLocation.type = locationType.value
    newFavLocation.visibility = visibility.value
  } else {
    newFavLocation = new FavouriteLocation({
      name: newFormattedName,
      address: newFormattedAddress,
      coordinates: newCoordinates,
      planningData: newPlanningData,
      type: locationType.value,
      visibility: visibility.value,
    })
  }

  if (newFavLocation) {
    store.commit(MutationTypes.updateIndividualFavLocation, newFavLocation)

    const saveRes = await newFavLocation.saveFavouriteLocation()
    if (saveRes === 'success') {
      status.value = 'SUCCESS'
    } else {
      status.value = 'FAILED'
    }
  } else {
    status.value = 'FAILED'
  }

  closeAndReset()
}
</script>
<style scoped>
* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
