import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { EV_NAV_ENERGY_ENDPOINT } from '@/api/clients/ev-nav-client'
import type { EVNavEnergy, EVNavEnergyParams, EVNavRouteParams } from '@/types/ev_nav_types'

/**
 * Fetches the energy needed for a given route or energy parameters.
 *
 * @param {EVNavRouteParams | EVNavEnergyParams} routeParams - The parameters for the route or energy.
 * @returns {Promise<EVNavEnergy | undefined>} A promise that resolves to the energy needed for the route or undefined.
 */
export async function fetchEnergyNeeded(
  routeParams: EVNavRouteParams | EVNavEnergyParams,
): Promise<EVNavEnergy | undefined> {
  try {
    const response = await fetch(EV_NAV_ENERGY_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(routeParams),
      headers: { 'Content-Type': 'application/json' },
    })
    if (response.status === 200) {
      const convertedResponse: {
        result: EVNavEnergy
      } = await response.json()
      if (convertedResponse.result.Status === 'OK') {
        return convertedResponse.result
      } else {
        throw new Error('EV Nav Res was not successful')
      }
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
