<template>
  <v-card flat class="pa-5 bg-background">
    <!-- selected vehicle card -->
    <DefaultVehicleCard @view-vehicle="emitViewVehicle" />
    <!-- saved vehicles side scroller list -->
    <v-card-text class="text-h6 px-0 px-md-4">
      <v-row no-gutters justify="space-between">
        <span> Saved vehicles </span>
        <TextBtn @click="handleAddVehicle"> Add vehicle </TextBtn>
      </v-row>
      <v-slide-group class="pt-2" center-active>
        <v-slide-group-item
          v-for="(vehicle, index) in customVehicles"
          :key="'saved-vehicle-' + index"
          class="pr-2"
          :class="index == 0 ? 'pl-1' : ''"
        >
          <DashboardVehicleListCard
            style="width: 300px"
            :vehicle="vehicle"
            class="ma-1"
            @click="emitViewVehicle(vehicle)"
          />
        </v-slide-group-item>
      </v-slide-group>
    </v-card-text>
    <!-- fleet vehicles two column list -->
    <v-card-text class="text-h6 px-0 px-md-4">
      Fleet vehicles
      <v-text-field
        v-if="connectedVehicles.length"
        v-model="searchQuery"
        bg-color="background"
        clearable
        placeholder="Search fleet for vehicles available to you..."
        class="mt-4"
      />
      <v-row v-if="connectedVehicles.length" no-gutters>
        <v-card
          flat
          v-for="(vehicle, index) in connectedVehicles"
          :key="'fleet-vehicle-' + index"
          :style="smAndDown ? 'width: 100%' : 'width: 50%'"
          class="py-1 pa-md-1"
        >
          <DashboardVehicleListCard :vehicle="vehicle" @click="emitViewVehicle(vehicle)" />
        </v-card>
      </v-row>
      <v-row
        v-else-if="searchQuery && unfilteredData.length"
        class="text-body-1 text-grey my-4"
        no-gutters
      >
        No vehicles match your search query
      </v-row>
      <v-row v-else class="text-body-1 text-grey my-4" no-gutters>
        You have no connected vehicles
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { GettersTypes, MainDialogContent, MutationTypes } from '@/store/store_types'
import DefaultVehicleCard from './DefaultVehicleCard.vue'
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import DashboardVehicleListCard from './DashboardVehicleListCard.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useDisplay } from 'vuetify'

const { smAndDown } = useDisplay()
const store = useStore()
const emit = defineEmits<{
  (e: 'view-vehicle', vehicle: Vehicle): void
  (e: 'add-vehicle'): void
}>()

const searchQuery = ref('')
const unfilteredData = computed<Vehicle[]>(() => store.getters[GettersTypes.connectedVehicles])
const connectedVehicles = computed<Vehicle[]>(() => {
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    return unfilteredData.value.filter((vehicle) => {
      if (vehicle.name && vehicle.name.toLowerCase().includes(query)) return vehicle
      if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query)) return vehicle
      if (vehicle.licensePlate && vehicle.licensePlate.toLowerCase().includes(query)) return vehicle
      if (vehicle.evModel) {
        if (vehicle.evModel.name.toLowerCase().includes(query)) return vehicle
      }
    })
  }
  return unfilteredData.value
})
const customVehicles = computed<Vehicle[]>(() => store.getters[GettersTypes.customVehicles])

/**
 * Emits an event to view a vehicle. If the vehicle being viewed is the
 * same as the selected vehicle, the event emits `undefined` as the vehicle
 * ID. Otherwise, the event emits the local ID of the vehicle.
 *
 * @param {Vehicle} vehicle - The vehicle to be viewed.
 * @return {void} This function does not return anything.
 */
function emitViewVehicle(vehicle: Vehicle) {
  emit('view-vehicle', vehicle)
}

function handleAddVehicle() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.ADD_VEHICLE)
}
</script>
