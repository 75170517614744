import type { ConnectorDetailsData } from '@/data/connectorDetailsData'
import EVModel from '../classes/vehicle_classes/evModel'
import type { FuelType } from './sheared_local_types'

export enum VehicleType {
  TELEMATICS_VEHICLE = 'TELEMATICS_VEHICLE',
  CUSTOM_VEHICLE = 'CUSTOM_VEHICLE',
  GENERIC_VEHICLE = 'GENERIC_VEHICLE',
}

export interface VehicleCreationFormData {
  evModel: EVModel | null | undefined
  batterySize: string | number | null | undefined
  SOH: number
  fuelType: FuelType
  vehicleName: string | null | undefined
  plate: string | null | undefined
  VIN: string | null | undefined
  Mass: string | number | null | undefined
  DragCoefficient: string | number | null | undefined
  RollingResistanceCoefficient: string | number | null | undefined
  RegenerativeBreakingEfficiency: string | number | null | undefined
  PowerChainEfficiency: string | number | null | undefined
  MaxElectricPowerAc: string | number | null | undefined
  MaxElectricPowerDc: string | number | null | undefined
  selectedConnectors: ConnectorDetailsData[]
  companyDirectusID: number | undefined
  driverDirectusID: number | undefined
}

export interface VehicleConnectorsDisplayData {
  displayName: string
  imageSrc?: string
}
