<template>
  <v-dialog
    v-model="showDialog"
    :content-class="xs ? '' : 'rounded-lg'"
    style="z-index: 1400"
    @click:outside="close()"
    @after-leave="cleanup"
    @keydown="keyDown($event)"
    :width="mdAndUp ? '50%' : '70%'"
    max-width="600px"
    min-width="300px"
    :fullscreen="xs"
    scrollable
  >
    <v-card
      class="overflow-hidden py-5"
      id="pwt-card"
      :style="xs ? 'height: 100vh;' : 'height: 50vh;'"
    >
      <v-card-title class="d-flex justify-space-between" style="word-break: normal">
        <v-row no-gutters align="center">
          <v-col cols="2" md="1">
            <v-icon color="primary" class="pb-1">
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col cols="8" md="10">
            <span class="text-primary"> {{ title }} </span>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="close()" flat>
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden pb-0"
        style="height: calc(100% - 96px)"
      >
        <!-- conditionally rendered content. -->
        <LocationDetailsContent v-if="content === 'location details'" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { TertiaryDialogContent, MutationTypes } from '@/store/store_types'
import LocationDetailsContent from '../trips/itinerary/LocationDetailsContent.vue'
import { useDisplay } from 'vuetify'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'

const { mdAndUp, xs } = useDisplay()
const store = useStore()
const showDialog = ref(false)

const content = computed(() => store.state.tertiaryDialogContent)
const title = computed(() => {
  switch (store.state.tertiaryDialogContent) {
    case TertiaryDialogContent.LOCATION_DETAILS:
      return 'Location details'
    default:
      return null
  }
})
const icon = computed(() => {
  switch (store.state.tertiaryDialogContent) {
    case TertiaryDialogContent.LOCATION_DETAILS:
      return 'mdi-map-marker'
    default:
      return null
  }
})

const cleanup = () => {
  store.commit(MutationTypes.setTertiaryDialogContent, undefined)
}

function close() {
  showDialog.value = false
}

const keyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') close()
}

watch(content, () => {
  if (content.value) {
    showDialog.value = true
    notifyAnalytics({
      type: 'track_pageview',
      data: content.value,
    })
  } else {
    close()
  }
})
</script>
<style scoped>
#pwt-card {
  background-color: var(--v-theme-background);
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
