<template>
  <v-card flat class="bg-background">
    <v-card-text>
      <OutlinedBlockBtn v-if="smAndDown" @click="() => emit('back')" class="mb-3 mt-10">
        back to saved trips
      </OutlinedBlockBtn>
    </v-card-text>
    <v-row
      no-gutters
      :align="smAndDown ? 'start' : 'center'"
      :class="smAndDown ? 'flex-column' : 'flex-row'"
    >
      <v-col md="8">
        <v-card-title v-if="trip.name">
          <span class="text-truncate"> {{ trip.name }} </span>
        </v-card-title>
        <v-card-title v-else>
          <span class="text-truncate">
            Trip to
            {{
              trip.locations[trip.locations.length - 1].name ??
              trip.locations[trip.locations.length - 1].address
            }}
          </span>
        </v-card-title>
      </v-col>
      <v-col md="4" class="pr-3">
        <TextBtn v-if="smAndDown" @click="showTrip"> Open trip plan on map </TextBtn>
        <TextBlockBtn v-else @click="showTrip"> Open trip plan on map </TextBlockBtn>
      </v-col>
    </v-row>
    <v-card-subtitle v-if="!trip.name">
      <span class="text-truncate">
        from
        {{ trip.locations[0].name ?? trip.locations[0].address }}
      </span>
    </v-card-subtitle>

    <v-card-text>
      <!-- vehicle section -->
      <p v-if="trip.vehicle_data && trip.vehicle_data.licensePlate" class="pb-0">
        Using vehicle {{ trip.vehicle_data?.licensePlate }}
      </p>
      <p v-if="trip.vehicle_data && trip.vehicle_data.evModel" class="pb-0">
        {{ trip.vehicle_data?.evModel?.name }}
      </p>
      <v-card class="py-4 pl-md-12 rounded-lg bg-background mb-10">
        <v-row class="flex-column flex-md-row">
          <v-col md="3">
            <!-- image section -->
            <v-skeleton-loader type="image" width="100%" height="140px" v-if="!trip.vehicle_data" />
            <v-img
              v-else
              contain
              :src="imageSrc(trip.vehicle_data)"
              aspect-ratio="1"
              :width="mdAndUp ? '100%' : '40%'"
              :height="mdAndUp ? '100%' : 'auto'"
              style="margin: auto"
              class="flex-grow-0"
            ></v-img>
          </v-col>
          <v-col md="9">
            <!-- details section -->
            <div v-if="trip.vehicle_data">
              <v-card-title>
                {{ trip.vehicle_data.name ?? 'unnamed vehicle' }}
              </v-card-title>
              <v-card-text class="pr-12">
                <p class="mb-0">
                  {{ trip.vehicle_data.licensePlate ?? 'unrecorded license plate' }}
                </p>
                <p v-if="trip.vehicle_data.evModel">
                  {{ trip.vehicle_data.evModel.name }}
                </p>
                <NotConnectedBatteryDisplay v-if="!trip.vehicle_data.stateOfCharge" />
                <BatteryDisplay v-else :battery="trip.vehicle_data.stateOfCharge" />
              </v-card-text>
            </div>
            <div v-else>
              <v-row no-gutters class="mb-4">
                <v-skeleton-loader type="heading" width="70%" />
                <v-skeleton-loader type="heading" width="30%" />
              </v-row>
              <v-skeleton-loader type="text" width="40%" />
              <v-skeleton-loader type="text" width="70%" class="mb-4" />
              <v-skeleton-loader type="heading" width="100%" />
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- stats section -->
      <v-card class="mb-5 rounded-lg">
        <v-card-text>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Total travel time </v-col>
            <v-col md="8">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalTime) }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Total travel time </v-col>
            <v-col md="4" class="pr-3">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalTravelTime) }}
              </div>
            </v-col>
            <v-col md="4">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ Math.round(trip.itinerary.totalDrivingDistance / 1000) }} km
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Charging time </v-col>
            <v-col md="4" class="pr-3">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalChargingTime) }}
              </div>
            </v-col>
            <v-col md="4">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ Math.round(trip.itinerary.totalEnergyAdded) }} kWh
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="trip.frequency">
            <v-col md="4"> Frequency </v-col>
            <v-col md="8">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ trip.frequency.numberOfTimes + 'x ' + trip.frequency.timeFrame }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- emissions section -->
      <v-card class="mb-5 rounded-lg pa-4">
        <v-row no-gutters>
          <v-col md="4"> Emissions avoided </v-col>
          <v-col md="8">
            <div style="border-bottom: 1px solid lightgray; width: 100%">
              {{ Math.round(stats?.avoidedCO2 ?? 0) }} kg CO₂
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- cost section -->
      <v-card class="mb-5 rounded-lg pa-4">
        <v-card-title>
          <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
          ${{
            (
              (stats?.fuelCost ?? 0) -
              (stats?.privateChargingCost ?? 0) -
              (stats?.publicChargingCost ?? 0)
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          }}
          fuel savings for this trip
        </v-card-title>
        <v-card class="bg-background" flat>
          <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-water-outline </v-icon>
          <span>
            ${{
              (stats?.fuelCost ?? 0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}
            equivalent fuel cost
          </span>
        </v-card>
        <v-row no-gutters class="my-4">
          <v-col md="6">
            <v-card class="bg-background mr-md-1" flat>
              <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
              <span>
                ${{
                  (stats?.publicChargingCost ?? 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
                spent on public charging
              </span>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card class="bg-background ml-md-1" flat>
              <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
              <span>
                ${{
                  (stats?.privateChargingCost ?? 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
                spent on private charging
              </span>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <OutlinedBtn @click="showStats"> See trip's full performance summary </OutlinedBtn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts" setup>
import Trip from '@/classes/trip_classes/trip'
import TextBlockBtn from '../ui-elements/buttons/TextBlockBtn.vue'
import getAssetSrc from '@/utils/getAssetSrc'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { ActionTypes, MutationTypes, type State } from '@/store/store_types'
import BatteryDisplay from '../ui-elements/displays/BatteryDisplay.vue'
import OutlinedBlockBtn from '../ui-elements/buttons/OutlinedBlockBtn.vue'
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import { getNiceDuration } from '@/utils/timeUtils'
import OutlinedBtn from '../ui-elements/buttons/OutlinedBtn.vue'
import { RouteNames } from '@/router'
import NotConnectedBatteryDisplay from '../ui-elements/displays/NotConnectedBatteryDisplay.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

const { trip } = defineProps<{ trip: Trip }>()
const store = useStore<State>()
const router = useRouter()
const emit = defineEmits(['view-stats', 'view-trip', 'back'])
const { smAndDown, mdAndUp } = useDisplay()

const stats = computed(() =>
  trip.getTripStats({
    calcVs: store.state.calcVs,
    dieselCostPerLitre: store.state.dieselCostPerLitre,
    dieselKmPerLitre: store.state.dieselKmPerLitre,
    kWhCostHome: store.state.defaultHomeCostPerKWh,
    petrolCostPerLitre: store.state.petrolCostPerLitre,
    petrolKmPerLitre: store.state.petrolKmPerLitre,
  }),
)

/**
 * Returns the source URL of the image associated with the vehicle.
 *
 * @param {Vehicle} vehicle - The vehicle object.
 * @return {string} The source URL of the image.
 */
function imageSrc(vehicle: Vehicle): string {
  // get uploaded image to car in first instance.
  const images = vehicle.imageSrcPaths
  if (images.length) return images[0]
  // get model image in second instance.
  if (vehicle.evModel)
    return vehicle.evModel?.imageSrc ?? getAssetSrc('car_images/No_Image_Powersell.png')
  // get company logo image in third instance.
  const company = store.state.fleet
  if (company && company.logoSrc) {
    return company.logoSrc
  }
  // get default image in fourth instance.
  return getAssetSrc('car_images/No_Image_Powersell.png')
}
function showTrip() {
  store.dispatch(ActionTypes.showTrip, trip)
  emit('view-trip')
}
function showStats() {
  store.commit(MutationTypes.setSelectedTrip, trip)
  router.push({ name: RouteNames.tripStats })
  emit('view-stats')
}
function niceDuration(seconds: number): string {
  return getNiceDuration(seconds)
}
</script>
