/**
 * returns a number of the passed value if it is a number, stringified int or stringified float without erroring out.
 *
 * @param value target stringified number.
 * @returns a number if successful, undefined if not.
 */
export default function parseIntOrFloat(value: string | number): number | undefined {
  // check if is already a number
  if (typeof value === 'number') return value
  try {
    // check if value possibly includes a decimal point
    // NOTE: checks if float first as parseInt will trim anything after initial number e.g: 2nan = 2 or 2.5 = 2 if not checked prior to using parseInt.
    if (value.includes('.')) {
      // try as float
      const number = parseFloat(value)
      if (isNaN(number)) return undefined // check for silent fail
      return number
    }
    // try as int
    const number = parseInt(value)
    if (isNaN(number)) return undefined // check for silent fail
    return number
  } catch (error) {
    console.error(error)
    return undefined
  }
}
