<template>
  <v-card flat class="bg-background">
    <v-card-title>Saved trips</v-card-title>
    <v-card-text>
      <v-slide-group>
        <v-slide-group-item v-for="(trip, index) in savedTrips" :key="index">
          <v-card
            style="max-width: 200px"
            class="my-2 mr-2 pa-5 rounded-lg bg-background pwt-hover-container"
            @click="fetching ? null : showTrip(trip)"
            :loading="fetching"
            :disabled="fetching"
          >
            <v-card-title class="text-truncate pwt-hover-underline">
              <span class="text-truncate" v-if="trip.name">{{ trip.name }}</span>
              <span class="text-truncate" v-else-if="trip.itinerary.destination">
                Trip to {{ trip.itinerary.destination.name }}
              </span>
            </v-card-title>
            <v-card-subtitle style="min-height: 20px">
              <span class="text-truncate" v-if="trip.itinerary.destination && trip.name">
                Trip to {{ trip.itinerary.destination.name }}
              </span>
            </v-card-subtitle>

            <v-card-text>
              <p class="mb-0">{{ niceDuration(trip.itinerary.totalTime) }}</p>
              <p class="mb-0">{{ trip.chargingStopCDBIDs.length }} charging stops</p>
            </v-card-text>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
    </v-card-text>
    <v-card-actions class="px-4">
      <v-spacer></v-spacer>
      <OutlinedBtn @click="showAllTrips"> View all saved trips </OutlinedBtn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import Trip from '@/classes/trip_classes/trip'
import OutlinedBtn from '@/components/ui-elements/buttons/OutlinedBtn.vue'
import { ActionTypes, GettersTypes } from '@/store/store_types'
import { getNiceDuration } from '@/utils/timeUtils'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const emit = defineEmits<{
  (e: 'view-trip'): void
  (e: 'view-all-trips'): void
}>()

const savedTrips = computed<Trip[]>(() => store.getters[GettersTypes.savedTrips])
const fetching = computed<boolean>(() => store.getters[GettersTypes.fetching])

function niceDuration(seconds: number): string {
  return getNiceDuration(seconds)
}

function showTrip(trip: Trip) {
  store.dispatch(ActionTypes.showTrip, trip)
  emit('view-trip')
}

function showAllTrips() {
  emit('view-all-trips')
}
</script>
<style scoped>
.pwt-hover-container:hover .pwt-hover-underline {
  text-decoration: underline;
}
</style>
