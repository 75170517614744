import { directusAssetsRoute } from '@/api/clients/directus-client'

/**
 * Returns a src path for the passed directus image UUID.
 *
 * @param imgFileName directus image file UUID.
 * @returns full src path string.
 */
export default function getDirectusImgUrl(imgFileName: string): string {
  return `${directusAssetsRoute}${imgFileName}`
}
