import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus, { type CollectionNames } from '@/api/clients/directus-client'

/**
 * Fetches a list of items from directus.
 *
 * @param collection the name of the directus collection
 * @param query the directus query to use with this request
 * @returns a list of items of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusReadByQuery<T>(
  collection: CollectionNames,
  query: Record<string, unknown>,
): Promise<T[] | undefined> {
  try {
    const res = await directus.items(collection).readByQuery(query)
    return res.data as T[]
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return
  }
}

/**
 * Fetches a single item from directus.
 *
 * @param collection the name of the directus collection
 * @param id the id of the item
 * @param query optional - the directus query to use with this request
 * @returns a single item of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusReadOne<T>(
  collection: CollectionNames,
  id: string | number,
  query?: Record<string, unknown>,
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).readOne(id, query)
    return res as T
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return
  }
}

/**
 * Creates a new item in a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param item the object to be created can be an empty object if intending directus to apply defaults to the item.
 * @param query optional - the directus query to use with this request (alters the returned item by query).
 * @returns a single item of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusCreateOne<T>(
  collection: CollectionNames,
  item: Partial<T>,
  query?: Record<string, unknown>,
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).createOne(item, query)
    return res as T
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return
  }
}

/**
 * Deletes a single item from a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param id the id of the item to be deleted
 * @returns "ok" if successfully deleted or "failed" if not
 */
export async function directusDeleteOne(
  collection: CollectionNames,
  id: string | number,
): Promise<'ok' | 'failed'> {
  try {
    await directus.items(collection).deleteOne(id)
    return 'ok'
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return 'failed'
  }
}

/**
 * Updated a single item in a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param id the id of the item to be updated.
 * @param item the partial item object fo the item containing the fields that need to be updated.
 * @param query optional - the directus query to use with this request (alters the returned item by query).
 * @returns the updated item if successful undefined if not.
 */
export async function directusUpdateOne<T>(
  collection: CollectionNames,
  id: string | number,
  item: Partial<T>,
  query?: Record<string, unknown>,
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).updateOne(id, item, query)
    return res as T
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return
  }
}

/**
 * Cleans a passed record/partial record item and removes the system generated properties directus will handle the updating of.
 *
 * @param data full/partial item.
 * @returns cleaned partial item.
 */
export function cleanOutSystemGeneratedProperties<T extends Directus_base_item>(
  data: Partial<T>,
): Partial<T> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preparedData = {
    ...data,
  }

  const fieldsToDelete = [
    'id',
    'user_created',
    'date_created',
    'user_updated',
    'date_updated',
    'sort',
  ]

  fieldsToDelete.forEach((field) => {
    if (field in preparedData) {
      delete (preparedData as Record<string, unknown>)[field]
    }
  })

  return preparedData
}

interface Directus_base_item {
  id?: string | number
  user_created?: string | null
  date_created?: string | null
  user_updated?: string | null
  date_updated?: string | null
  sort?: number | null
}
