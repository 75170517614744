import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { VALHALLA_OPTIMIZED_ROUTE_ENDPOINT } from '@/api/clients/valhalla-client'
import type {
  Valhalla_CostingModel,
  Valhalla_Location,
  Valhalla_RouteError,
  Valhalla_RouteRes,
} from '@/types/valhalla_types'

/**
 * Fetches an optimized route plan from the Valhalla routing service.
 *
 * Sends a request to the Valhalla routing API with the provided locations and costing model.
 * If the response is successful (200), a `Valhalla_RouteRes` object is returned.
 * If the response is a 400 error, a `Valhalla_RouteError` object is returned.
 * If any error occurs, the function returns `undefined`.
 * @param locations The list of locations to fetch the route plan for.
 * @param costing The costing model to use. Defaults to "auto".
 * @returns A promise that resolves with the route plan, or undefined if an error occurs.
 */
export async function fetchValhallaOptimizedRoutePlan(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = 'auto',
): Promise<Valhalla_RouteRes | Valhalla_RouteError | undefined> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    }

    const res = await fetch(`${VALHALLA_OPTIMIZED_ROUTE_ENDPOINT}?json=${JSON.stringify(json)}`)

    if (res.status === 200) {
      const convertedResponse: Valhalla_RouteRes = await res.json()
      return convertedResponse
    }

    if (res.status === 400) {
      const convertedResponse: Valhalla_RouteError = await res.json()
      return convertedResponse
    }
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
