<template>
  <v-card flat v-if="trip">
    <v-card-title class="text-primary font-weight-regular" v-if="orderedList.length">
      Top {{ Math.min(orderedList.length, 3) }} suitable EVs for this trip
    </v-card-title>
    <v-card-title class="text-primary font-weight-regular" v-else>
      There are no EVs in your fleet suitable for this trip
    </v-card-title>

    <v-card-text class="d-flex justify-space-between flex-column flex-lg-row">
      <span> Rough time you have for charging on this trip </span>
      <span class="text-primary font-weight-bold">
        {{ getNiceDuration(allowedChargingTime) }}
      </span>
    </v-card-text>
    <v-slider
      v-model="allowedChargingTime"
      :min="min"
      :max="max"
      class="mx-5"
      color="primary"
      track-color="grey-lighten-2"
      @end="findSuggestedEVs()"
      step="1"
      thumb-size="16"
      track-size="6"
    ></v-slider>

    <template v-if="loadingRoutePlan">
      <v-card class="d-flex justify-center align-center flex-column" flat>
        <v-card-title class="text-primary"> Recalculating <PulseLoader /> </v-card-title>
        <LoadingCard />
      </v-card>
    </template>
    <template v-else>
      <VehicleListCard
        v-if="orderedList.length >= 1"
        :selected="orderedList[0].localId === selectedId"
        :vehicle="getOrderedListVehicle(0)"
      />
      <VehicleListCard
        v-if="orderedList.length >= 2"
        :selected="orderedList[1].localId === selectedId"
        :vehicle="getOrderedListVehicle(1)"
      />
      <VehicleListCard
        v-if="orderedList.length >= 3"
        :selected="orderedList[2].localId === selectedId"
        :vehicle="getOrderedListVehicle(2)"
      />
    </template>
  </v-card>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import VehicleListCard from './VehicleListCard.vue'
import PulseLoader from '../ui-elements/loaders/PulseLoader.vue'
import LoadingCard from '../ui-elements/loaders/LoadingCard.vue'
import { GettersTypes } from '@/store/store_types'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import recommendEV from '@/utils/recommendEV'
import Trip from '@/classes/trip_classes/trip'
import { Duration } from 'luxon'

const store = useStore()

const orderedList = ref<Vehicle[]>([])
const allowedChargingTime = ref(60)
const min = ref(0)
const max = ref(120)

const trip = computed<Trip | undefined>(() => store.getters[GettersTypes.selectedTripData])
const vehicles = computed(() => store.state.vehicles)
const selectedId = computed(() => store.state.selectedVehicle)
const loadingRoutePlan = computed(() => store.state.routePlanningFlag)
const chargers = computed(() => store.state.chargers)

function getOrderedListVehicle(index: number): Vehicle {
  return orderedList.value[index] as Vehicle // Warning: only use if showingTrip is defined. Workaround foe TS cant match class private properties see https://github.com/microsoft/TypeScript/issues/42683 for unhelpful but working as intended behaviour
}

const findSuggestedEVs = () => {
  if (trip.value && vehicles.value) {
    const data = recommendEV(
      trip.value,
      vehicles.value,
      allowedChargingTime.value / 60,
      chargers.value,
    )
    orderedList.value = data.rankedList.map((item) => item.vehicle)
    min.value = data.min / 60
    max.value = Math.min(
      data.max / 60,
      trip.value.itinerary.totalTime ? trip.value.itinerary.totalTime / 60 : 300,
    )
    if (allowedChargingTime.value > data.max / 60) {
      allowedChargingTime.value = data.max / 60
    }
  }
}

const getNiceDuration = (minutes: number | undefined): string => {
  const time = minutes ?? 0
  return Duration.fromObject({
    hours: 0,
    minutes: Math.round(time),
  })
    .normalize()
    .toHuman({ listStyle: 'narrow' })
    .replace(',', '')
}

watch([trip, vehicles, selectedId], () => {
  findSuggestedEVs()
})

onMounted(() => {
  findSuggestedEVs()
})
</script>

<style scoped>
* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
