import 'vuetify/styles'
import { createVuetify, type IconProps, type IconSet, type ThemeDefinition } from 'vuetify'
import {
  powerTripBlue,
  powerTripDarkBlue,
  powerTripDarkGrey,
  powerTripLightGrey,
  powerTripRed,
  powerTripWhite,
} from '@/data/const'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import VehiclesIcon from '@/components/ui-elements/custom-icons/VehiclesIcon.vue'
import { h } from 'vue'
import { mdi } from 'vuetify/iconsets/mdi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customSvgNameToComponent: any = {
  vehiclesIcon: VehiclesIcon,
}

const customSVGs: IconSet = {
  component: (props: IconProps) => h(customSvgNameToComponent[props.icon as string]),
}

const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: powerTripWhite,
    primary: powerTripBlue,
    secondary: powerTripLightGrey,
    tertiary: powerTripDarkGrey,
    accent: powerTripDarkBlue,
    error: powerTripRed,
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
}

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  components: {
    VTimePicker,
  },
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme,
    },
  },
})
