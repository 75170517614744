import parseIntOrFloat from '@/utils/parseIntOrFloat'

/** Standardised format for coordinate values with supported converters and validation. */
export default class Coordinate {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** The latitude for this point. */
  latitude: number
  /** The longitude for this point. */
  longitude: number

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    latitude,
    longitude,
  }: {
    /** The latitude for this point. */
    latitude: number
    /** The longitude for this point. */
    longitude: number
  }) {
    this.latitude = latitude
    this.longitude = longitude
  }

  /**
   * Parses latitude and longitude from an unparsed object and creates a Coordinate instance if both are successfully parsed.
   *
   * @param {Object} obj - An object containing latitude and longitude as strings.
   * @return {Coordinate | undefined} A Coordinate instance if both latitude and longitude are successfully parsed, otherwise undefined.
   */
  static fromUnparsedObj(obj: { latitude: string; longitude: string }): Coordinate | undefined {
    const parsedLat = parseIntOrFloat(obj.latitude)
    const parsedLng = parseIntOrFloat(obj.longitude)

    if (parsedLat && parsedLng) {
      return new Coordinate({
        latitude: parsedLat,
        longitude: parsedLng,
      })
    }
  }

  /**
   * Creates a new Coordinate instance from a GeoJSON Point object.
   *
   * @param {GeoJSON.Point} obj - The GeoJSON Point object to convert.
   * @return {Coordinate} The newly created Coordinate instance.
   */
  static fromGEOJsonPoint(obj: GeoJSON.Point): Coordinate {
    return new Coordinate({
      latitude: obj.coordinates[1],
      longitude: obj.coordinates[0],
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Tests if the coordinate is with in the bounds of earth and not the default null island.
   *
   * @returns true if is a valid coordinate.
   */
  public get isValid(): boolean {
    if (this.latitude === 0 && this.longitude === 0) return false // null island is a default not a drivable location.
    if (
      this.latitude > 180 ||
      this.latitude < -180 ||
      this.longitude > 180 ||
      this.longitude < -180
    )
      return false // coordinate is outside the bounds of the planet.
    return true
  }

  /**
   * Converts to leaflet supported [lat,lng] format.
   *
   * @returns a latitude longitude point array
   */
  public get asLatLng(): [number, number] {
    return [this.latitude, this.longitude]
  }

  /**
   * Converts to haversine supported [lng,lat] format.
   *
   * @returns a longitude latitude point array
   */
  public get asLngLat(): [number, number] {
    return [this.longitude, this.latitude]
  }

  /**
   * Converts to Valhalla supported lat lon obj format.
   *
   * @returns an obj containing a lat and lon properties.
   */
  public get asAbbreviatedObj(): { lat: number; lon: number } {
    return { lat: this.latitude, lon: this.longitude }
  }

  /**
   * Converts to EV Nav supported Latitude Longitude obj format.
   *
   * @returns an obj containing a Latitude and Longitude properties.
   */
  public get asCapitalizedObj(): { Latitude: number; Longitude: number } {
    return { Latitude: this.latitude, Longitude: this.longitude }
  }
}
