<template>
  <v-card flat class="bg-background">
    <v-card-text>{{ modelName || 'Unknown' }}</v-card-text>
    <template v-if="savedStyle">
      <v-card-text>
        <v-row no-gutters>
          <v-col v-for="(trip, i) in groupedTrips.trips" :key="'saved-trip-' + i" cols="12" md="6">
            <div :class="mdAndUp ? (i % 2 === 0 ? 'pr-1' : 'pl-1') : ''">
              <SavedTripCard
                :trip="trip"
                :style="mdAndUp ? 'height: 100%' : ''"
                emitOnly
                @view-trip="() => emit('view-trip', trip)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-else>
      <UnsavedTripCard
        v-for="(trip, index) in groupedTrips.trips"
        :key="`unsaved-trip-card-${index}`"
        :trip="trip"
      />
    </template>
  </v-card>
</template>
<script lang="ts" setup>
import { type State } from '@/store/store_types'
import Trip from '@/classes/trip_classes/trip'
import SavedTripCard from './SavedTripCard.vue'
import UnsavedTripCard from './UnsavedTripCard.vue'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import { computed } from 'vue'

interface SortedTripsGroup {
  evModel: string
  trips: Trip[]
}

const { mdAndUp } = useDisplay()
const { groupedTrips, savedStyle = false } = defineProps<{
  groupedTrips: SortedTripsGroup
  savedStyle?: boolean
}>()
const emit = defineEmits<{ (e: 'view-trip', trip: Trip): void }>()
const store = useStore<State>()

const modelName = computed(
  () => store.state.evModels?.find((model) => model.id === groupedTrips.evModel)?.name,
)
</script>
