import type { CollectionNames } from '@/api/clients/directus-client'
import {
  directusCreateOne,
  directusDeleteOne,
  directusReadByQuery,
  directusUpdateOne,
} from './helperFunctions'

const collectionName: CollectionNames = 'Favourite_Locations'
export interface Directus_Favourite_Location {
  id: number
  user_created: string
  Coordinates: GeoJSON.Point
  Name: string
  Address: string
  Visibility: 'private' | 'group' | 'fleet'
  Type: 'Work' | 'Home' | 'Depot' | 'Other'
  Charge_Here: boolean
  Rating?: null | number
  Load_Weight_Change?: null | number
  Current_Type?: null | 'AC' | 'DC'
  SOC_after_charging?: null | number
  energy_used?: null | number
  stay_duration_seconds?: null | number
}

/**
 * Creates a new favourite location in the database.
 *
 * @param {Omit<Directus_Favourite_Location, "id" | "user_created">} data - The data for the new favourite location.
 * @return {Promise<Directus_Favourite_Location | undefined>} The created favourite location data.
 */
export async function createFavouriteLocation(
  data: Omit<Directus_Favourite_Location, 'id' | 'user_created'>,
): Promise<Directus_Favourite_Location | undefined> {
  return await directusCreateOne<Directus_Favourite_Location>(collectionName, data)
}

/**
 * Fetches favourite locations from the database.
 *
 * @return {Promise<Directus_Favourite_Location[] | undefined>} Favourite locations data from the database.
 */
export async function fetchFavouriteLocations(): Promise<
  Directus_Favourite_Location[] | undefined
> {
  return await directusReadByQuery<Directus_Favourite_Location>(collectionName, {})
}

/**
 * Updates a favourite location in the database.
 *
 * @param {number} favouriteLocationId - The id of the favourite location to update.
 * @param {Omit<Directus_Favourite_Location, "id" | "user_created">} dataToUpdate - The data to update for the favourite location.
 * @return {Promise<Directus_Favourite_Location | undefined>} The updated favourite location data.
 */
export async function updateFavouriteLocation(
  favouriteLocationId: number,
  dataToUpdate: Omit<Directus_Favourite_Location, 'id' | 'user_created'>,
): Promise<Directus_Favourite_Location | undefined> {
  return await directusUpdateOne<Directus_Favourite_Location>(
    collectionName,
    favouriteLocationId,
    dataToUpdate,
  )
}

/**
 * Deletes a favourite location from the database.
 *
 * @param {number} favouriteLocationId - The id of the favourite location to be deleted.
 * @return {Promise<"ok" | "failed">} Either "ok" if successfully deleted or "failed" if not.
 */
export async function deleteFavouriteLocation(
  favouriteLocationId: number,
): Promise<'ok' | 'failed'> {
  return await directusDeleteOne(collectionName, favouriteLocationId)
}
