<template>
  <v-card class="rounded-lg my-5 pb-5" elevation="4" v-if="station">
    <v-card-text class="d-flex justify-space-between px-5 text-grey-lighten2 font-weight-medium">
      <span>{{ station.simpleDisplayRating }} </span>
      <span>{{ `${range[0]}% - ${range[1]}%` }}</span>
    </v-card-text>
    <!-- display data if compatible with this charging station -->
    <template v-if="isCompatibleWithStation !== 'incompatible'">
      <v-card-text class="pb-0">
        <v-range-slider
          v-model="range"
          min="1"
          max="100"
          track-color="grey-lighten-2"
          @end="calcDetails"
          hid-details
          dense
          color="primary"
          thumb-size="16"
          track-size="6"
        />
      </v-card-text>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="4">
            <p class="text-grey-lighten2 text-center">Charge time</p>
            <v-row class="justify-center" no-gutters>
              <v-icon color="primary" class="mr-1"> mdi-clock-outline </v-icon>
              <p class="text-primary mb-0">{{ chargingTime }}</p>
            </v-row>
          </v-col>
          <v-col cols="4">
            <p class="text-grey-lighten2 text-center">Charge cost</p>
            <v-row class="justify-center" no-gutters>
              <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
              <p class="text-primary mb-0">
                {{ `around $${chargingCost}` }}
              </p>
            </v-row>
          </v-col>
          <v-col cols="4">
            <p class="text-grey-lighten2 text-center">Energy Added</p>
            <v-row class="justify-center" no-gutters>
              <v-icon color="primary" class="mr-1"> mdi-lightning-bolt </v-icon>
              <p class="text-primary mb-0">
                {{ `${energyAdded}kWh (+${percentageCharged}%)` }}
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <!-- display message if not compatible with this charging station -->
    <v-card-text v-else class="pl-5">
      Your selected EV is not compatible with this charging station
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { GettersTypes } from '@/store/store_types'
import Charger from '@/classes/charger_classes/charger'
import Connector from '@/classes/charger_classes/connector'
import type { isCompatibleReturn } from '@/types/sheared_local_types'
import type Vehicle from '@/classes/vehicle_classes/vehicle'

const { connector } = defineProps<{ connector?: Connector }>()

const store = useStore()

const range = ref([10, 90])
const energyAdded = ref(0)
const percentageCharged = ref(0)
const chargingTime = ref('')
const chargingCost = ref(0)
const isCompatibleWithStation = ref<isCompatibleReturn>('incompatible')

const station = computed<Charger | undefined>(() => store.getters[GettersTypes.selectedChargerData])
const vehicle = computed<Vehicle | undefined>(() => store.getters[GettersTypes.selectedVehicleData])

const min = computed(() => store.state.SOCMin)
const max = computed(() => store.state.SOCMax)
const defaultPublicCostPerKWh = computed(() => store.state.defaultPublicCostPerKWh)
const defaultCostPerMinDC = computed(() => store.state.defaultCostPerMinDC)

function calcDetails() {
  if (!station.value || !vehicle.value) {
    isCompatibleWithStation.value = 'incompatible'
    return
  }

  const isCompatible = station.value.isCompatible(vehicle.value)
  isCompatibleWithStation.value = isCompatible

  if (isCompatible === 'incompatible') {
    return
  }

  const details = connector?.getChargingEstimateDetails(
    vehicle.value,
    {
      min: range.value[0],
      max: range.value[1],
    },
    defaultPublicCostPerKWh.value,
    defaultCostPerMinDC.value,
  )

  if (!details) {
    isCompatibleWithStation.value = 'incompatible'
    return
  }

  energyAdded.value = details.energyAdded
  chargingCost.value = details.chargingCost
  chargingTime.value = details.chargingTime
  percentageCharged.value = details.percentageCharged
}

watch(
  () => connector,
  () => {
    calcDetails()
  },
)

onMounted(() => {
  nextTick(() => {
    if (min.value && max.value) {
      range.value = [min.value * 100, max.value * 100]
    }
    nextTick(() => {
      calcDetails()
    })
  })
})
</script>
