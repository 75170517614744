import { v4 as uuidV4 } from 'uuid'

/**
 * Generates a unique id to be used as a placeholder during this session.
 *
 * @param usedIds the list of currently used id's
 * @param prefix the prefix to place at start of the id for easy identification of type of id. e.g. charger's with charger prefix returning `charger-123`
 * @returns a session scoped unique id for the not already included in the past list of `usedIds`.
 *
 * NOTE: It is expected that this format is different from the UUID normally provided for this type of item and that any saved data will be allow whatever
 * database it is being sent to to set its own unique id witch should be respected rather than this once set.
 */
export default function generateUniqueLocalID(
  usedIds: (string | number)[],
  prefix: string,
): string {
  // while loop control variable.
  let isUnique = false

  // outside of while loop variable to catch the return value.
  let uniqueID = ''

  // loop through random id's until a unique id is found.
  while (!isUnique) {
    // generate random id
    const randomGeneratedID = `${prefix}-${uuidV4()}`

    // check random generated id is unique
    if (!usedIds.includes(randomGeneratedID)) {
      uniqueID = randomGeneratedID
      isUnique = true // NOTE: all ways ensure in any refactoring that the while loop has an exit trigger.
    }
  }

  // return unique location id string
  return uniqueID
}
