<template>
  <v-row v-if="connectors.length">
    <!-- conditionally rendered plug images -->
    <v-col
      cols="auto"
      v-for="(connector, index) in connectors"
      :key="'vehicle-connector-img-' + index"
    >
      <v-img
        :src="connector.imageSrc || fallbackImgSrc"
        width="50"
        height="50"
        contain
        class="mx-auto"
      />
      <v-card-text>{{ connector.displayName }}</v-card-text>
    </v-col>
  </v-row>
  <!-- default if no connectors -->
  <v-card-text v-else class="text-subtitle-2"> None selected </v-card-text>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import type { VehicleConnectorsDisplayData } from '@/types/vehicle_specific_types'
import getAssetSrc from '@/utils/getAssetSrc'

const { connectors = [] } = defineProps<{
  connectors: VehicleConnectorsDisplayData[]
}>()

const fallbackImgSrc = computed(() => getAssetSrc('car_images/No_Image_Powersell.png'))
</script>
