<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <!-- address search section -->
      <v-card flat class="mb-10">
        <v-card-text>
          <AddressAutocompleteInput
            placeholder="Search Optimiser for a location..."
            :initialValue="addressData"
            :allowFavLocations="true"
            @update="updateAddress"
          />
          <v-row no-gutters>
            <v-spacer />
            <ElevatedBtn @click="toPlanning"> Continue </ElevatedBtn>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- history preview section -->
      <v-card-title class="pt-5 text-tertiary">
        <v-row no-gutters align="center">
          Unsaved trips
          <v-spacer></v-spacer>
          <StyledToolTip v-if="unsavedTripsTooltip" :data="unsavedTripsTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title class="pt-5 text-tertiary">
        <v-row no-gutters align="center">
          Saved trips
          <v-spacer></v-spacer>
          <StyledToolTip v-if="savedTripsTooltip" :data="savedTripsTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <FrequentTripSavingsOverview class="mb-5" />
        <SavedTripCard v-if="savedTrips.length" :trip="savedTrips[0]" />
        <SavedTripCard v-if="savedTrips.length >= 2" :trip="savedTrips[1]" />
        <SavedTripCard v-if="savedTrips.length >= 3" :trip="savedTrips[2]" />
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import InfoPanelCardWrapper from '@/components/ui-elements/wrappers/InfoPanelCardWrapper.vue'
import { GettersTypes } from '@/store/store_types'
import AddressAutocompleteInput, {
  type AddressAutocompleteInputUpdateObj,
} from '../components/ui-elements/inputs/AddressAutocompleteInput.vue'
import ElevatedBtn from '../components/ui-elements/buttons/ElevatedBtn.vue'
import type { processedAddressObj } from '@/utils/processAddressSearchResults'
import FrequentTripSavingsOverview from '../components/trips/statistics/FrequentTripSavingsOverview.vue'
import SavedTripCard from '../components/trips/history/SavedTripCard.vue'
import UnsavedTripCarousel from '../components/trips/history/UnsavedTripCarousel.vue'
import StyledToolTip from '../components/ui-elements/displays/StyledToolTip.vue'
import { RouteNames } from '@/router'
import { useRouter, type LocationQueryRaw } from 'vue-router'

const store = useStore()
const router = useRouter()

const savedTrips = computed(() => store.getters[GettersTypes.savedTrips])

const unsavedTripsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip history explanation tooltip',
  ),
)
const savedTripsTooltip = computed(() =>
  store.state.managedContent.find((content) => content.Description === 'favourite trips tooltip'),
)

const addressData = ref<processedAddressObj | undefined>(undefined)

function pushRoute(routeName: RouteNames, query: LocationQueryRaw) {
  router.push({ name: routeName, query: query })
}

function toPlanning() {
  const address = addressData.value?.address
  const lat = addressData.value?.coordinates.Latitude
  const lon = addressData.value?.coordinates.Longitude
  const name = addressData.value?.name
  const newQuery = {
    ...router.currentRoute.value.query,
  }

  if (address) {
    newQuery.destAddress = encodeURI(address)
  }

  if (lat) {
    newQuery.destLat = lat.toString()
  }

  if (lon) {
    newQuery.destLon = lon.toString()
  }

  if (name) {
    newQuery.destName = name
  }

  pushRoute(RouteNames.tripAddDestination, newQuery)
}

function updateAddress(val: AddressAutocompleteInputUpdateObj) {
  addressData.value = val.addressData
}
</script>
