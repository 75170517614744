import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import {
  MAPS_LITTLE_MONKEY_API_KEY,
  MAPS_LITTLE_MONKEY_AUTOCOMPLETE_ENDPOINT,
} from '@/api/clients/maps-little-monkey-client'
import type Coordinate from '@/classes/common_classes/coordinate'

/**
 * Fetches a list of location objects for use in auto complete inputs and in calling routing calculations
 * based on objects latitude and longitude values.
 *
 * This list is prioritized by the passed latitude and longitude.
 *
 * @param searchValue string value of user typed search input.
 * @param geoLocation the users current latitude and longitude.
 * @returns an array of objects containing location details.
 */
export async function fetchLocationAutoCompleteDetails(
  searchValue: string,
  geoLocation?: Coordinate | null,
  bias = 0.1,
) {
  const urlEncodedVal = encodeURI(searchValue)
  const geoLocationStr =
    geoLocation && geoLocation.isValid
      ? `&lat=${geoLocation.latitude}&lon=${geoLocation.longitude}`
      : ''

  try {
    const response = await fetch(
      `${MAPS_LITTLE_MONKEY_AUTOCOMPLETE_ENDPOINT}/?q=${urlEncodedVal}` +
        // auth
        `&api_key=${MAPS_LITTLE_MONKEY_API_KEY}` +
        // location bias
        geoLocationStr +
        `&location_bias_scale=${bias}` +
        '&zoom=7' +
        // exclusion tags
        '&osm_tag=!boundary' +
        '&osm_tag=!natural' +
        '&osm_tag=!waterway' +
        '&osm_tag=!highway:path' +
        '&osm_tag=!highway:footpath' +
        '&osm_tag=!highway:footway' +
        '&osm_tag=!highway:footway;crossing' +
        '&osm_tag=!highway:foot' +
        '&osm_tag=!highway:service' +
        '&osm_tag=!highway:street_lamp' +
        '&osm_tag=!highway:crossing' +
        '&osm_tag=!tunnel' +
        '&osm_tag=!bridge' +
        // formatting
        '&layer=city' +
        '&layer=locality' +
        '&layer=street' +
        '&layer=house' +
        '&layer=district' +
        '&layer=county' +
        // limit
        '&limit=200',
    )
    const convertedResponse = await response.json()
    return convertedResponse.features
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
  }
}
