<template>
  <v-dialog
    v-if="smAndDown"
    v-model="showMenu"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
    content-class="bg-background"
    class="bg-background"
  >
    <template v-slot:activator="{ props }">
      <v-avatar color="primary" class="mr-3 mt-1" v-bind="props">
        <img v-if="avatarSrc" :src="avatarSrc" />
        <v-icon v-else dark> mdi-account-circle </v-icon>
      </v-avatar>
    </template>
    <v-card class="bg-background">
      <v-toolbar flat class="bg-background">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="showMenu = false">
            <v-icon color="grey-lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <AvatarMenuListContent />
    </v-card>
  </v-dialog>
  <!-- normal size avatar and menu -->
  <v-menu v-else v-model="showMenu" absolute offset-y content-class="rounded-lg">
    <template v-slot:activator="{ props }">
      <v-avatar color="primary" class="mr-3 mt-1" v-bind="props" :size="small ? 36 : 48">
        <img v-if="avatarSrc" :src="avatarSrc" />
        <v-icon v-else dark :small="small"> mdi-account-circle </v-icon>
      </v-avatar>
    </template>

    <AvatarMenuListContent />
  </v-menu>
</template>
<script lang="ts" setup>
import AvatarMenuListContent from './AvatarMenuListContent.vue'
import Driver from '@/classes/user_classes/driver'
import { GettersTypes } from '@/store/store_types'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { useDisplay } from 'vuetify'

const { small = false } = defineProps<{ small?: boolean }>()
const store = useStore()
const { smAndDown } = useDisplay()

const showMenu = ref(false)
const driver = computed<Driver | undefined>(() => store.getters[GettersTypes.currentDriver])
const avatarSrc = computed(() => driver.value?.profilePicSrc)
</script>
