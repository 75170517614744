<template>
  <v-card flat style="height: 100%" class="bg-transparent">
    <v-card-text v-if="status === 'PROCESSING'" class="text-primary">
      <v-progress-circular :width="3" indeterminate color="primary" />
      Processing...
    </v-card-text>
    <v-card-text v-else-if="status === 'SUCCESS'" class="text-success">
      <v-icon color="success"> mdi-check-circle </v-icon>
      Deleted successfully.
    </v-card-text>
    <v-card-text v-else-if="status === 'FAILED'" class="text-error">
      <v-icon color="error"> mdi-alert-circle </v-icon>
      Whoops! Something went wrong and this trip was not deleted.
    </v-card-text>
    <v-card-text v-else>
      Are you sure you want to delete your trip{{
        tripToDelete
          ? ` to ${tripToDelete.locations[tripToDelete.locations.length - 1].address}`
          : ''
      }}. This can not be undone.
    </v-card-text>
    <v-card-actions>
      <ElevatedBlockBtn @click="handleDelete" v-if="status === undefined">
        Delete
      </ElevatedBlockBtn>
      <ElevatedBlockBtn @click="handleClose" v-else-if="status !== 'PROCESSING'">
        Ok
      </ElevatedBlockBtn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { ActionTypes } from '@/store/store_types'
import { GettersTypes, MutationTypes } from '@/store/store_types'
import ElevatedBlockBtn from '@/components/ui-elements/buttons/ElevatedBlockBtn.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const tripToDelete = computed(() => store.getters[GettersTypes.queuedForDeleteTripData])
const status = computed(() => store.state.deleteTripStatus)

const handleDelete = () => {
  notifyAnalytics({
    type: 'add_event',
    data: {
      key: 'Trip Deleted',
      count: 1,
    },
  })
  if (tripToDelete.value) {
    store.dispatch(ActionTypes.deleteTrip)
  }
}

const handleClose = () => {
  store.commit(MutationTypes.setSecondaryDialogContent, undefined)
  store.commit(MutationTypes.setQueuedTripForDelete, undefined)
}
</script>
