<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :loading="loading"
    class="rounded-pill text-none px-5"
    :class="darkText ? '' : 'text-white'"
    @click="emitClick"
    variant="elevated"
  >
    <slot></slot>
  </v-btn>
</template>
<script setup lang="ts">
import { powerTripDarkBlue } from '@/data/const'

interface Props {
  loading?: boolean
  disabled?: boolean
  color?: string
  darkText?: boolean
}

const {
  loading = false,
  disabled = false,
  color = powerTripDarkBlue,
  darkText = false,
} = defineProps<Props>()

const emit = defineEmits(['click'])

function emitClick() {
  emit('click')
}
</script>
