/** Base url for ev nav endpoints to be used with final end point strings e.g. ``` ROUTE_ENDPOINT=`${evNavEndpoint}/route` ```. */
const baseUrl = 'https://evnav.pt.appstack.me/api/v1'

/** Endpoint for ev nav route planing service. */
export const EV_NAV_ROUTE_ENDPOINT = baseUrl + '/route'

/** Endpoint for ev nav route planing service. */
export const EV_NAV_MULTI_ROUTES_ENDPOINT = baseUrl + '/multiroute'

/** Endpoint for ev nav range queries. */
export const EV_NAV_RADAR_ENDPOINT = baseUrl + '/radar'

/** Endpoint for ev nav nearby queries */
export const EV_NAV_NEARBY_ENDPOINT = baseUrl + '/nearby'

/** Endpoint for ev nav energy queries */
export const EV_NAV_ENERGY_ENDPOINT = baseUrl + '/energy'
