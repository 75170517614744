<template>
  <v-card flat style="height: 100%" class="d-flex flex-column bg-transparent">
    <v-card-text>
      Filter networks to be used for the map display and trip planning
      <v-checkbox
        label="select all"
        :indeterminate="selectAllValue === undefined"
        v-model="selectAllValue"
      />
      <v-checkbox
        v-for="(network, index) in networks"
        :key="index"
        :label="network.displayName"
        class="mt-0"
        color="primary"
        hide-details
        :value="network"
        v-model="selectedNetworks"
      />
    </v-card-text>
    <v-card-actions class="mt-auto mb-0 pl-4">
      <ElevatedBtn @click="handleUpdateNetwork"> Update network settings </ElevatedBtn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import NetworkBranding from '@/classes/charger_classes/networkBranding'
import { MutationTypes } from '@/store/store_types'
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'

const store = useStore()

const networks = computed<NetworkBranding[]>(() => store.state.networkBranding)

const selectedNetworks = ref<NetworkBranding[]>([])
const selectAllValue = ref<undefined | boolean>(undefined)

onMounted(() => {
  setInitialValue()
})

const setInitialValue = () => {
  if (store.state.networkSelectionOverride) {
    const tempArray: NetworkBranding[] = []
    store.state.networkSelectionOverride.forEach((networkName) => {
      const networkData = store.state.networkBranding.find((item) =>
        item.isThisNetwork(networkName),
      )
      if (networkData) {
        const hasBeenUsed = !!tempArray.find((branding) => branding.localId === networkData.localId)
        if (!hasBeenUsed) {
          tempArray.push(networkData)
        }
      }
    })
    if (tempArray.length) {
      selectedNetworks.value = tempArray
      return
    }
  }
  selectedNetworks.value = store.state.networkBranding
}

const handleUpdateNetwork = () => {
  // Notify analytics server
  notifyAnalytics({
    type: 'add_event',
    data: {
      key: 'Preferred charger networks settings adjusted',
      count: 1,
      segments: {
        'Number of networks selected': selectedNetworks.value.length.toString(),
      },
    },
  })

  const currentlySelectedNetworks = selectedNetworks.value
  const allNetworks = networks.value

  const useAllNetworks = allNetworks.every((v) => currentlySelectedNetworks.includes(v))

  if (useAllNetworks) {
    // if all networks there is no need for an override so return to default.
    store.commit(MutationTypes.setNetworkSelectionOverride, undefined)
  } else {
    const tempArray: string[] = []
    currentlySelectedNetworks.forEach((network) => {
      tempArray.push(...network.allKnownAliases)
    })

    // apply selected override.
    store.commit(
      MutationTypes.setNetworkSelectionOverride,
      tempArray.length ? tempArray : undefined, // prevent empty results being committed to sate. THIS ASSUMES: case of invalidate all chargers and cause routing to fail if not with in a single charge range is still unwanted.
    )
  }

  // close modal
  handleClose()
}

const handleClose = () => {
  store.commit(MutationTypes.setSecondaryDialogContent, undefined)
}

watch(selectAllValue, (val) => {
  if (val) {
    selectedNetworks.value = networks.value
  } else if (selectedNetworks.value.length === networks.value.length) {
    selectedNetworks.value = []
  }
})

watch(selectedNetworks, (val: NetworkBranding[]) => {
  const useAllNetworks = networks.value.every((v) => val.includes(v))
  if (useAllNetworks) {
    selectAllValue.value = true
  } else {
    selectAllValue.value = false
  }
})
</script>
