import type { SavedRouteData } from '@/types/trip_specific_types'
import {
  cleanOutSystemGeneratedProperties,
  directusCreateOne,
  directusDeleteOne,
  directusReadByQuery,
  directusUpdateOne,
} from './helperFunctions'

const collectionName = 'SavedRoutePlans'

/**
 * Fetches all records from the `SavedRoutePlans` directus collection created by the the given user.
 *
 * @param userID the UUID for the directus user.
 * @returns a list of saved route plans if successful, undefined if not.
 */
export async function fetchSavedRoutePlansByUser(
  userID: string,
): Promise<Directus_SavedRoutePlan[] | undefined> {
  return await directusReadByQuery<Directus_SavedRoutePlan>(collectionName, {
    filter: {
      user_created: { _eq: userID },
    },
  })
}

/**
 * Create a new directus `SavedRoutePlans` collection record.
 *
 * @param saveData partial object to create
 * @returns the newly created record if successful, undefined if not.
 */
export async function createSavedRoutePlan(
  saveData: Partial<Directus_SavedRoutePlan>,
): Promise<Directus_SavedRoutePlan | undefined> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preparedData = cleanOutSystemGeneratedProperties<Directus_SavedRoutePlan>(saveData)
  // check there is still something to update. Exit early if not.
  if (!Object.keys(preparedData).length) return
  return await directusCreateOne<Directus_SavedRoutePlan>(collectionName, saveData)
}

/**
 * Updates an existing directus `SavedRoutePlans` collection record.
 *
 * @param recordId the directus `SavedRoutePlans` collection record id.
 * @param data partial object to update
 * @returns the updated record if successful, undefined if not.
 */
export async function updatedSavedRoutePlan(
  recordId: string | number,
  data: Partial<Directus_SavedRoutePlan>,
): Promise<Directus_SavedRoutePlan | undefined> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preparedData = cleanOutSystemGeneratedProperties<Directus_SavedRoutePlan>(data)

  // check there is still something to update. Exit early if not.
  if (!Object.keys(preparedData).length) return

  // update directus record.
  return await directusUpdateOne<Directus_SavedRoutePlan>(collectionName, recordId, preparedData)
}

/**
 * Delete the target record from the directus `SavedRoutePlans`
 * collection.
 *
 * @param directusId the record id of the target record.
 * @returns status message outlining the outcome of the operation.
 */
export async function deleteSavedRoutePlans(directusId: string | number): Promise<'ok' | 'failed'> {
  return await directusDeleteOne(collectionName, directusId)
}

export interface Directus_SavedRoutePlan extends SavedRouteData {
  id: string | number
  user_created: string | null
  date_created: string | null
  user_updated: string | null
  date_updated: string | null
}
