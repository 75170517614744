import TripsHomeView from '@/views/TripsHomeView.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

export enum RouteNames {
  home = 'home',
  trips = 'trips',
  tripAddDestination = 'tripAddDestination',
  tripAddOrigin = 'tripAddOrigin',
  tripAddDetails = 'tripAddDetails',
  tripAddStops = 'tripAddStops',
  tripSelectVehicle = 'tripSelectVehicle',
  tripItinerary = 'tripItinerary',
  tripStats = 'tripStats',
}

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: RouteNames.home,
      redirect: '/trips',
    },
    {
      path: '/trips',
      children: [
        {
          path: '',
          name: RouteNames.trips,
          component: TripsHomeView,
        },
      ],
    },
    {
      path: '/login/location=:location',
      redirect(to) {
        if (to.params.location && !Array.isArray(to.params.location)) {
          const substring = to.params.location.split('&state=')[0].slice(1)
          return substring
        }
        return '/trips'
      },
    },
    {
      path: '/trip/planning',
      children: [
        {
          path: 'add-destination',
          name: RouteNames.tripAddDestination,
          component: () => import('@/views/PlanningAddDestinationView.vue'),
        },
        {
          path: 'add-origin',
          name: RouteNames.tripAddOrigin,
          component: () => import('@/views/PlanningAddOriginView.vue'),
        },
        {
          path: 'add-details',
          name: RouteNames.tripAddDetails,
          component: () => import('@/views/PlanningAddDetailsView.vue'),
        },
        {
          path: 'add-stops',
          name: RouteNames.tripAddStops,
          component: () => import('@/views/PlanningAddStopsView.vue'),
        },
        {
          path: 'select-vehicle',
          name: RouteNames.tripSelectVehicle,
          component: () => import('@/views/PlanningSelectVehicleView.vue'),
        },
        {
          path: 'itinerary',
          name: RouteNames.tripItinerary,
          component: () => import('@/views/PlanningItineraryView.vue'),
        },
        {
          path: 'trip-stats',
          name: RouteNames.tripStats,
          component: () => import('@/views/TripStatsView.vue'),
        },
      ],
    },
  ],
})

export default router
