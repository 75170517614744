<template>
  <v-card
    class="rounded-lg mb-10 pa-4"
    flat
    :style="numberOfBookingsNotExpired > 0 ? 'background-color: #2EAEFF1A !important' : ''"
  >
    <v-row no-gutters align="center">
      <v-icon v-if="numberOfBookingsNotExpired > 0" color="#2E46ff" class="mr-2">
        mdi-bell-badge-outline
      </v-icon>
      <span v-if="numberOfBookingsNotExpired > 0"> {{ numberOfBookingsNotExpired }} Bookings </span>
      <v-spacer />
      <ElevatedBtn @click="showBooking = true">Book this vehicle</ElevatedBtn>
    </v-row>
    <!-- booking dialog -->
    <GenericDialog
      v-if="vehicle && vehicle.directusId"
      :activator="showBooking"
      @close="showBooking = false"
    >
      <VehicleBookingContent :vehicle="vehicle" />
    </GenericDialog>
  </v-card>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import {
  type DirectusAssetBooking,
  fetchAssetBookingsByAssetID,
} from '@/api/calls/directus-calls/asset-bookings-calls'
import GenericDialog from '../dialog-wrappers/GenericDialog.vue'
import VehicleBookingContent from './VehicleBookingContent.vue'

const { vehicle } = defineProps<{ vehicle: Vehicle }>()

const bookings = ref<DirectusAssetBooking[]>([])
const showBooking = ref(false)
const today = Date.now()
const numberOfBookingsNotExpired = computed(() => {
  return bookings.value.reduce((count, booking) => {
    if (Date.parse(booking.end) > today) {
      return count + 1
    }
    return count
  }, 0)
})

async function fetchBookingsData() {
  if (!vehicle.directusId) return
  const directusFormatEvents = await fetchAssetBookingsByAssetID(vehicle.directusId)
  if (directusFormatEvents) bookings.value = directusFormatEvents
}

onMounted(() => {
  fetchBookingsData()
})

watch(
  () => vehicle,
  () => {
    fetchBookingsData()
  },
)
watch(showBooking, (value) => {
  if (!value) fetchBookingsData() // update booking numbers when dialog is closed
})
</script>
