<template>
  <!-- processing card -->
  <DescriptiveLoader
    v-if="processing"
    message="Power Trip is updating your vehicles saved settings"
    class="bg-background"
  />
  <!-- save successful card -->
  <v-card flat class="pa-5 bg-background" v-else-if="saveStatus === 'success'">
    <v-alert type="success" class="mx-2">
      Vehicle updated successfully and saved to the database
    </v-alert>
  </v-card>
  <!-- save unsuccessful card -->
  <v-card flat class="pa-5 bg-background" v-else-if="saveStatus === 'failed'">
    <v-alert type="error" class="mx-2">
      Vehicle was updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
  <!-- vehicle details card -->
  <v-card flat class="pa-5 bg-background" v-else>
    <VehicleBookingBand v-if="vehicle && vehicle.directusId" :vehicle="vehicle" />
    <!-- vehicle details card -->
    <v-card class="py-4 pl-md-12 rounded-lg bg-background mb-10" flat>
      <v-row class="flex-column flex-md-row">
        <v-col md="3">
          <!-- image section -->
          <v-skeleton-loader type="image" width="100%" height="140px" v-if="!vehicle" />
          <v-img
            v-else
            contain
            :src="imageSrc()"
            aspect-ratio="1"
            :width="mdAndUp ? '100%' : '40%'"
            :height="mdAndUp ? '100%' : 'auto'"
            style="margin: auto"
          ></v-img>
        </v-col>
        <v-col md="9">
          <!-- details section -->
          <div v-if="vehicle">
            <v-card-title>
              {{ isSelected ? 'Current vehicle' : 'Unselected vehicle' }}
              <v-spacer></v-spacer>
              <OutlinedBtn
                @click="isSelected ? handleSelectOther() : handleSelect()"
                v-if="mdAndUp"
              >
                {{ isSelected ? 'Change selected vehicle' : 'Select this vehicle' }}
              </OutlinedBtn>
            </v-card-title>
            <v-card-subtitle class="font-weight-medium pr-12">
              {{ vehicle.name ?? 'unnamed vehicle' }}
            </v-card-subtitle>
            <v-card-text class="pr-12">
              <p class="mb-0">
                {{ vehicle.licensePlate ?? 'unrecorded license plate' }}
              </p>
              <p v-if="vehicle.evModel">
                {{ vehicle.evModel.name }}
              </p>
              <NotConnectedBatteryDisplay v-if="!vehicle.stateOfCharge" />
              <BatteryDisplay
                v-else
                :battery="vehicle.stateOfCharge"
                :style="mdAndUp ? 'max-width: 50%' : ''"
              />
              <OutlinedBlockBtn
                @click="isSelected ? handleSelectOther() : handleSelect()"
                v-if="smAndDown"
                class="mt-3"
              >
                {{ isSelected ? 'Change selected vehicle' : 'Select this vehicle' }}
              </OutlinedBlockBtn>
            </v-card-text>
          </div>
          <div v-else>
            <v-row no-gutters class="mb-4">
              <v-skeleton-loader type="heading" width="70%" />
              <v-skeleton-loader type="heading" width="30%" />
            </v-row>
            <v-skeleton-loader type="text" width="40%" />
            <v-skeleton-loader type="text" width="70%" class="mb-4" />
            <v-skeleton-loader type="heading" width="100%" />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- settings section -->
    <v-form :disabled="processing || loading">
      <!-- weight section  of settings -->
      <v-card class="py-4 px-12 rounded-lg bg-background mb-10" elevation="1">
        <!-- vehicle weight -->
        <v-row class="flex-column flex-md-row">
          <v-col md="4" align-self="center">
            <p class="text-grey pt-2">
              Vehicle Weight
              <StyledToolTip v-if="vehicleWeightTooltip" :data="vehicleWeightTooltip" />
            </p>
          </v-col>
          <v-col md="8" align-self="center">
            <v-text-field
              v-model="vehicleWeight"
              type="number"
              hide-spin-buttons
              suffix="kg"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- additional weight -->
        <v-row class="flex-column flex-md-row">
          <v-col md="4" align-self="center">
            <p class="text-grey pt-2">
              Additional Weight
              <StyledToolTip v-if="additionalWeightTooltip" :data="additionalWeightTooltip" />
            </p>
          </v-col>
          <v-col md="8" align-self="center">
            <v-text-field
              v-model="additionalWeight"
              type="number"
              hide-spin-buttons
              suffix="kg"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- fuel section of settings -->
      <v-card class="py-4 px-12 rounded-lg bg-background mb-10" elevation="1">
        <!-- fuel type -->
        <v-row class="flex-column flex-md-row">
          <v-col md="4" align-self="center">
            <p class="text-grey pt-2">
              Fuel Type
              <StyledToolTip v-if="fuelTypeTooltip" :data="fuelTypeTooltip" />
            </p>
          </v-col>
          <v-col md="8" align-self="center">
            <v-select
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric', 'Plug in hybrid']"
              v-model="fuelType"
              :rules="[validateFuelType]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- if ev battery size -->
        <v-row v-if="fuelType === 'Electric'" class="flex-column flex-md-row">
          <v-col md="4" align-self="center">
            <p class="text-grey pt-2">
              Battery Size
              <StyledToolTip v-if="batterySizeTooltip" :data="batterySizeTooltip" />
            </p>
          </v-col>
          <v-col md="8" align-self="center">
            <v-text-field
              v-model="batterySize"
              type="number"
              clearable
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              suffix="kWh"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- if ev range adjustment/SoH slider -->
        <v-row v-if="fuelType === 'Electric'" class="mb-5 flex-column flex-md-row">
          <v-col md="4" align-self="center">
            <p class="text-grey pt-2">
              Range Adjustment
              <StyledToolTip v-if="sohToolTipContent" :data="sohToolTipContent" />
            </p>
          </v-col>
          <v-col md="8" align-self="center">
            <v-slider
              v-model="SoH"
              :label="SoH + '%'"
              min="1"
              max="100"
              track-color="grey-lighten-2"
              :messages="batteryAgeMessage()"
              @end="flagAsDirty"
              :disabled="processing || loading"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- charging section -->
      <v-card
        class="py-4 px-8 rounded-lg bg-background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- AC (slow) charging speed/AC max kW rating -->
        <v-row class="flex-column flex-md-row">
          <v-col md="5" xl="4" align-self="center">
            <p class="text-grey pt-2">
              AC (slow) charging speed
              <StyledToolTip v-if="acChargingSpeedTooltip" :data="acChargingSpeedTooltip" />
            </p>
          </v-col>
          <v-col md="7" xl="8" align-self="center">
            <v-text-field
              v-model="acRating"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- DC (slow) charging speed/DC max kW rating -->
        <v-row class="flex-column flex-md-row">
          <v-col md="5" xl="4" align-self="center">
            <p class="text-grey pt-2">
              DC (slow) charging speed
              <StyledToolTip v-if="dcChargingSpeedTooltip" :data="dcChargingSpeedTooltip" />
            </p>
          </v-col>
          <v-col md="7" xl="8" align-self="center">
            <v-text-field
              v-model="dcRating"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- energy section -->
      <v-card
        class="py-4 px-12 rounded-lg bg-background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- Energy used when stopped in kW -->
        <v-row class="flex-column flex-md-row">
          <v-col md="5" xl="4" align-self="center">
            <p class="text-grey pt-2">
              Energy used when stopped
              <StyledToolTip
                v-if="energyUsedWhenStoppedTooltip"
                :data="energyUsedWhenStoppedTooltip"
              />
            </p>
          </v-col>
          <v-col md="7" xl="8" align-self="center">
            <v-text-field
              v-model="energyUsedWhenStopped"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- Energy used when moving in kW -->
        <v-row class="flex-column flex-md-row">
          <v-col md="5" xl="4" align-self="center">
            <p class="text-grey pt-2">
              Energy used when moving
              <StyledToolTip
                v-if="energyUsedWhenMovingTooltip"
                :data="energyUsedWhenMovingTooltip"
              />
            </p>
          </v-col>
          <v-col md="7" xl="8" align-self="center">
            <v-text-field
              v-model="energyUsedWhenMoving"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- physics profile section -->
      <v-card
        class="py-4 px-12 rounded-lg bg-background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- Aerodynamics slider -->
        <v-row class="flex-column flex-md-row">
          <v-col md="6" xl="5" align-self="center">
            <p class="text-grey pt-2">
              Aerodynamics (drag)
              <StyledToolTip v-if="dragTooltip" :data="dragTooltip" />
            </p>
          </v-col>
          <v-col md="6" xl="7" align-self="center">
            <v-slider
              v-model="aerodynamics"
              :label="(aerodynamics / 100).toFixed(5)"
              min="10"
              max="1000"
              track-color="grey-lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            />
          </v-col>
        </v-row>
        <!-- Tyre dynamics slider -->
        <v-row class="flex-column flex-md-row">
          <v-col md="6" xl="5" align-self="center">
            <p class="text-grey pt-2">
              Tyre dynamics (grip)
              <StyledToolTip v-if="gripTooltip" :data="gripTooltip" />
            </p>
          </v-col>
          <v-col md="6" xl="7" align-self="center">
            <v-slider
              v-model="tyreDynamics"
              :label="(tyreDynamics / 1000).toString()"
              min="2"
              max="20"
              track-color="grey-lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            />
          </v-col>
        </v-row>
        <!-- Regenerative breaking efficiency slider -->
        <v-row class="flex-column flex-md-row">
          <v-col md="6" xl="5" align-self="center">
            <p class="text-grey pt-2">
              Regenerative braking efficiency
              <StyledToolTip
                v-if="regenerativeBrakingEfficiencyTooltip"
                :data="regenerativeBrakingEfficiencyTooltip"
              />
            </p>
          </v-col>
          <v-col md="6" xl="7" align-self="center">
            <v-slider
              v-model="regenerativeBraking"
              :label="regenerativeBraking + '%'"
              min="1"
              max="100"
              track-color="grey-lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            />
          </v-col>
        </v-row>
        <!-- Power train efficiency slider -->
        <v-row class="flex-column flex-md-row">
          <v-col md="6" xl="5" align-self="center">
            <p class="text-grey pt-2">
              Power train efficiency
              <StyledToolTip
                v-if="powerTrainEfficiencyTooltip"
                :data="powerTrainEfficiencyTooltip"
              />
            </p>
          </v-col>
          <v-col md="6" xl="7" align-self="center">
            <v-slider
              v-model="powerTrain"
              :label="powerTrain + '%'"
              min="1"
              max="100"
              track-color="grey-lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- connectors and cables -->
      <div v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'">
        <v-card-title>
          <v-row no-gutters align="center">
            Connector Settings
            <v-spacer></v-spacer>
            <StyledToolTip v-if="connectorSettingsTooltip" :data="connectorSettingsTooltip" />
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          These settings override the default settings based on your selected EV model.
        </v-card-subtitle>
        <v-card class="mb-8 rounded-lg background">
          <v-card-title> Tethered Charging Stations </v-card-title>
          <v-card-subtitle>
            Plan trips with compatible chargers by selecting desired connectors.
          </v-card-subtitle>
          <v-slide-group class="py-2">
            <v-slide-group-item
              v-for="(connector, index) in connectors"
              :key="'connector-styled-btn-' + index"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2 rounded-lg background"
                style="width: 95px; height: 140px"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded pa-1 d-flex align-center justify-center mb-2"
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="connectorIsSelected(connector) ? 'svg_selected' : 'svg_unselected'"
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc()"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                    :class="connectorIsSelected(connector) ? 'svg_selected' : 'svg_unselected'"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-card>
        <v-card class="mb-8 rounded-lg background">
          <v-card-title> Your Cables </v-card-title>
          <v-card-subtitle> Select the cables you are bringing with you </v-card-subtitle>
          <v-slide-group class="py-2">
            <v-slide-group-item
              v-for="(connector, index) in cables"
              :key="'connector-styled-btn-' + index"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2 rounded-lg background"
                style="width: 95px; height: 140px"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded pa-1 d-flex align-center justify-center mb-2"
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="connectorIsSelected(connector) ? 'svg_selected' : 'svg_unselected'"
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc()"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                    :class="connectorIsSelected(connector) ? 'svg_selected' : 'svg_unselected'"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-card>
      </div>
    </v-form>
    <!-- Actions section -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <TextBtn :disabled="!(vehicle && vehicle.evModel)" @click="handelReset">
        Reset to vehicle defaults
      </TextBtn>
      <ElevatedBtn :disabled="!dirty" @click="saveData"> Save changes </ElevatedBtn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import {
  ActionTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
  type State,
} from '@/store/store_types'
import StyledToolTip from '../ui-elements/displays/StyledToolTip.vue'
import getAssetSrc from '@/utils/getAssetSrc'
import EVModel from '@/classes/vehicle_classes/evModel'
import OutlinedBtn from '../ui-elements/buttons/OutlinedBtn.vue'
import type { FuelType } from '@/types/sheared_local_types'
import type {
  DirectusAdvancedConfig,
  DirectusVehicleVariableData,
  DirectusUserSelectedPlug,
} from '@/types/directus-types'
import to2DP from '@/utils/to2DP'
import DescriptiveLoader from '../ui-elements/loaders/DescriptiveLoader.vue'
import type { ConnectorFormat, ConnectorType } from '@/types/charger_Db_types'
import {
  type ConnectorDetailsData,
  connectorDetailsDataMap,
  type ConnectorDetailsIconData,
  getAllSocketedConnectors,
  getAllTetheredConnectors,
} from '@/data/connectorDetailsData'
import OutlinedBlockBtn from '../ui-elements/buttons/OutlinedBlockBtn.vue'
import BatteryDisplay from '../ui-elements/displays/BatteryDisplay.vue'
import VehicleBookingBand from './VehicleBookingBand.vue'
import NotConnectedBatteryDisplay from '../ui-elements/displays/NotConnectedBatteryDisplay.vue'
import { useStore } from 'vuex'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'

interface LocalConnectorData {
  iconURL?: string
  displayName: string
  standard: ConnectorType
  format: ConnectorFormat
}

const { vehicle, isSelected = false } = defineProps<{
  vehicle?: Vehicle | undefined
  isSelected?: boolean
}>()

const { smAndDown, mdAndUp } = useDisplay()

const store = useStore<State>()
// control refs
const dirty = ref(false)
const loading = ref(true)
const processing = ref(false)
const saveStatus = ref<undefined | 'failed' | 'success' | undefined>(undefined)
// weights refs
const vehicleWeight = ref(1000)
const additionalWeight = ref(0)
// fuel refs
const fuelType = ref<FuelType>('Electric')
const batterySize = ref(25)
const SoH = ref(100)
// charging refs
const acRating = ref(10)
const dcRating = ref(50)
// energy refs
const energyUsedWhenStopped = ref(0)
const energyUsedWhenMoving = ref(0)
// physics profile refs
const aerodynamics = ref(50)
const tyreDynamics = ref(50)
const regenerativeBraking = ref(50)
const powerTrain = ref(50)
// connectors refs
const selectedConnectors = ref<LocalConnectorData[]>([])
// tooltip content
const sohToolTipContent = computed(() =>
  store.state.managedContent.find((content) => content.Name === ManagedContentNames.SoHToolTip),
)
const vehicleWeightTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'vehicle details vehicle weight tooltip',
  ),
)
const additionalWeightTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'vehicle details additional weight tooltip',
  ),
)
const fuelTypeTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'vehicle details fuel type tooltip',
  ),
)
const batterySizeTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model battery size tooltip',
  ),
)
const acChargingSpeedTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model ac charging speed tooltip',
  ),
)
const dcChargingSpeedTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model dc charging speed tooltip',
  ),
)
const energyUsedWhenStoppedTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model energy used when stopped tooltip',
  ),
)
const energyUsedWhenMovingTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model additional energy used when moving tooltip',
  ),
)
const dragTooltip = computed(() =>
  store.state.managedContent.find((content) => content.Description === 'ev model drag tooltip'),
)
const gripTooltip = computed(() =>
  store.state.managedContent.find((content) => content.Description === 'ev model grip tooltip'),
)
const regenerativeBrakingEfficiencyTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model regenerative braking efficiency tooltip',
  ),
)
const powerTrainEfficiencyTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model power train efficiency tooltip',
  ),
)
const connectorSettingsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'ev model connector settings tooltip',
  ),
)
// cables and connectors

/**
 * Orders the given array of LocalConnectorData objects based on whether they are selected or not.
 *
 * @param {LocalConnectorData[]} connectors - The array of LocalConnectorData objects to order.
 * @return {LocalConnectorData[]} The ordered array of LocalConnectorData objects.
 */
function orderConnectors(connectors: LocalConnectorData[]): LocalConnectorData[] {
  const returnArray: LocalConnectorData[] = []
  connectors.forEach((connector) => {
    if (connectorIsSelected(connector)) {
      returnArray.unshift(connector)
    } else {
      returnArray.push(connector)
    }
  })
  return returnArray
}

/**
 * Checks if a given connector is already selected.
 *
 * @param {LocalConnectorData} connector - The connector to check for selection.
 * @return {boolean} True if the connector is selected, false otherwise.
 */
function connectorIsSelected(connector: LocalConnectorData): boolean {
  return !!selectedConnectors.value.find(
    (selectedConnector) =>
      selectedConnector.standard === connector.standard &&
      selectedConnector.format === connector.format,
  )
}

/**
 * Converts ConnectorDetailsData to LocalConnectorData.
 *
 * @param {ConnectorDetailsData} connectorData - The connector data to be converted.
 * @return {LocalConnectorData} The converted local connector data.
 */
function convertConnectorDataToLocalConnectorData(
  connectorData: ConnectorDetailsData,
): LocalConnectorData {
  return {
    displayName: connectorData.displayName,
    format: 'CABLE',
    standard: connectorData.standard,
    iconURL: getSrc(connectorData.iconURL, 'cable'),
  }
}

/**
 * Returns the target connectors icon asset url if one exist
 *
 * @param iconUrlData the 'ConnectorDetailsData' `iconUrl` property.
 * @param format the target format
 * @returns complete file path if it exist or undefined if not.
 */
function getSrc(
  iconUrlData: string | ConnectorDetailsIconData | undefined,
  format: 'cable' | 'socket',
): string | undefined {
  let partialFilePath: string | undefined = undefined
  if (!iconUrlData) return undefined
  if (typeof iconUrlData === 'string') partialFilePath = iconUrlData
  if (typeof iconUrlData === 'object') {
    if (Object.hasOwn(iconUrlData, format)) partialFilePath = iconUrlData[format]
  }
  if (!partialFilePath) return undefined
  return getAssetSrc(partialFilePath)
}

/** List of all possible hard point connectors */
const connectors = computed(() => {
  // convert connector data to locally used connector data objs and order selected connectors at the start of the list.
  return orderConnectors(
    getAllTetheredConnectors().map((connector) =>
      convertConnectorDataToLocalConnectorData(connector),
    ),
  )
})
/** List of all possible cables */
const cables = computed(() => {
  // convert connector data to locally used connector data objs and order selected connectors at the start of the list.
  return orderConnectors(
    getAllSocketedConnectors().map((connector) =>
      convertConnectorDataToLocalConnectorData(connector),
    ),
  )
})

// Form validation

/**
 * Validates the fuel type selected.
 *
 * @returns {true | string} Returns true if the fuel type is valid, otherwise returns an error message.
 */
function validateFuelType(): true | string {
  // Check if the fuel type is one of the valid options
  const isValidFuelType =
    fuelType.value === 'Electric' ||
    fuelType.value === 'Petrol' ||
    fuelType.value === 'Diesel' ||
    fuelType.value === 'Hybrid'

  // Return true if the fuel type is valid, otherwise return an error message
  return isValidFuelType ? true : 'Please select a fuel type'
}

/**
 * Validates that the input value is not negative.
 *
 * @param {number|string|null} value - The value to be validated.
 * @return {true|string} Returns true if the value is not negative, otherwise returns an error message.
 */
function validateNotNegative(value: number | string | null): true | string {
  if (value) {
    // Validate that the value is not negative.
    const parsedNumber = parseIntOrFloat(value)
    if (parsedNumber === 0) return true // prevent false negative from 0 being valid but not truthy.
    if (parsedNumber && parsedNumber >= 0) {
      return true
    } else {
      return 'Please enter a value greater than or equal to 0'
    }
  } else {
    return 'Please enter a value'
  }
}

/**
 * Validates that the input value is not NaN.
 *
 * @param {number|string|null} value - The value to be validated.
 * @return {true|string} Returns true if the value is not NaN, otherwise returns an error message.
 */
function validateNotNan(value: number | string | null): true | string {
  if (value) {
    // Validate that the value is not NaN
    const parsedNumber = parseIntOrFloat(value)
    if (!parsedNumber || !isNaN(parsedNumber)) {
      return true
    } else {
      return 'Please enter a value'
    }
  } else {
    return 'Please enter a value'
  }
}

/**
 * Validates that the input value is not zero.
 *
 * @param {number|string|null} value - The value to be validated.
 * @return {true|string} Returns true if the value is not zero, otherwise returns an error message.
 */
function validateNotZero(value: number | string | null): true | string {
  if (value) {
    // Validate that the value is not 0
    const parsedNumber = parseIntOrFloat(value)
    if (parsedNumber && parsedNumber !== 0) {
      return true
    } else {
      return 'Please enter a value greater than 0'
    }
  } else {
    return 'Please enter a value'
  }
}

// Display helpers

/**
 * Calculates the battery age based on the state of health (SoH).
 *
 * @return {string} A message describing the battery age.
 */
function batteryAgeMessage(): string {
  const degradedPercentage = 100 - SoH.value
  if (degradedPercentage >= 70) return 'this looks like a poorly functioning battery'
  const yearsOld = Math.floor(degradedPercentage / 3)
  if (yearsOld <= 0) return 'equivalent to a new battery'
  return 'equivalent to a ' + yearsOld + ' year old battery with normal usage'
}

/**
 * Returns the source URL of the image associated with the vehicle.
 *
 * @return {string} The source URL of the image.
 */
function imageSrc(): string {
  if (vehicle) {
    // get uploaded image to car in first instance.
    const images = vehicle.imageSrcPaths
    if (images.length) return images[0]
    // get model image in second instance.
    if (vehicle.evModel)
      return vehicle.evModel?.imageSrc ?? getAssetSrc('car_images/No_Image_Powersell.png')
    // get company logo image in third instance.
    const company = store.state.fleet
    if (company && company.logoSrc) {
      return company.logoSrc
    }
  }
  // get default image in fourth instance.
  return getAssetSrc('car_images/No_Image_Powersell.png')
}

// data setup functions

/**
 * Initializes the component's data properties with default values from the vehicle object.
 *
 * @return {void} No return value.
 */
function setInitialValues(): void {
  loading.value = true
  dirty.value = false
  if (vehicle) {
    // weights
    vehicleWeight.value = vehicle.mass
    additionalWeight.value = 0
    // fuel
    fuelType.value = vehicle.fuelType ?? 'Petrol'
    batterySize.value = vehicle.batterySize
    SoH.value = vehicle.stateOfHealth ? vehicle.stateOfHealth * 100 : 100
    // charging
    acRating.value = vehicle.maxElectricPowerAC
    dcRating.value = vehicle.maxElectricPowerDC
    // energy
    energyUsedWhenStopped.value = 0
    energyUsedWhenMoving.value = 0
    // physics profile
    aerodynamics.value = vehicle.dragCoefficient * 100
    tyreDynamics.value = vehicle.rollingResistanceCoefficient * 1000
    regenerativeBraking.value = vehicle.regenerativeBreakingEfficiency * 100
    powerTrain.value = vehicle.powerChainEfficiency * 100
  }
  selectedConnectors.value = getInitialConnectors()
  nextTick(() => {
    loading.value = false
  })
}

/**
 * Retrieves the initial connectors for a vehicle based on its user-selected plugs or EV model.
 *
 * @return {LocalConnectorData[]} An array of LocalConnectorData objects representing the initial connectors.
 */
function getInitialConnectors(): LocalConnectorData[] {
  if (vehicle) {
    if (vehicle.userSelectedPlugs && vehicle.userSelectedPlugs.length)
      return getUserSelectedConnectors(vehicle.userSelectedPlugs)
    if (vehicle.evModel) return getModelConnectors(vehicle.evModel)
  }
  return []
}

/**
 * Converts an array of user selected plugs into an array of LocalConnectorData objects.
 *
 * @param {UserSelectedPlug[]} userSelectedPlugs - An array of user selected plugs to be converted.
 * @return {LocalConnectorData[]} An array of LocalConnectorData objects representing the user selected connectors.
 */
function getUserSelectedConnectors(
  userSelectedPlugs: DirectusUserSelectedPlug[],
): LocalConnectorData[] {
  const tempArray: LocalConnectorData[] = []
  userSelectedPlugs.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    if (extraData) {
      tempArray.push({
        format: connector.format,
        standard: connector.standard,
        displayName: extraData.displayName,
        iconURL: getSrc(extraData.iconURL, connector.format === 'CABLE' ? 'cable' : 'socket'),
      })
    }
  })
  return tempArray
}

/**
 * Returns an array of LocalConnectorData objects representing the model's compatible connectors.
 *
 * @param {EVModel} model - The EV model to retrieve connectors for.
 * @return {LocalConnectorData[]} An array of LocalConnectorData objects.
 */
function getModelConnectors(model: EVModel): LocalConnectorData[] {
  const tempArray: LocalConnectorData[] = []
  model.compatibleConnectors.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    const alreadyAdded = !!tempArray.find((dataItem) => dataItem.standard === connector.standard)
    if (extraData && !alreadyAdded) {
      tempArray.push({
        format: 'CABLE',
        standard: connector.standard,
        displayName: extraData.displayName,
        iconURL: getSrc(extraData.iconURL, 'cable'),
      })
    }
  })
  return tempArray
}

/**
 * Returns the fallback image source URL.
 *
 * @return {string} The fallback image source URL.
 */
function fallbackImgSrc(): string {
  return getAssetSrc('plug_images/Empty-state-plug-02.svg')
}

/**
 * Converts the selected connectors to an array of user selected plugs.
 *
 * @return {UserSelectedPlug[]} An array of user selected plugs.
 */
function convertSelectedConnectorsToUserSelectedPlugs(): DirectusUserSelectedPlug[] {
  const tempArray: DirectusUserSelectedPlug[] = []

  selectedConnectors.value.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    if (extraData) {
      tempArray.push({
        standard: connector.standard,
        format: connector.format,
        powerType: extraData.powerType,
      })
    }
  })
  return tempArray
}

// control functions

/**
 * Flags the component's data as dirty, indicating that it has been modified.
 *
 * @return {void} No return value.
 */
function flagAsDirty(): void {
  if (loading.value) return
  dirty.value = true
}

// event handlers

/**
 * Handles the selection of a vehicle by dispatching the selectVehicle action.
 *
 * @return {void} This function does not return a value.
 */
function handleSelect(): void {
  store.dispatch(ActionTypes.selectVehicle, vehicle)
}

/**
 * Handles the selection of the "Other" option in the vehicle details dialog.
 *
 * @return {void} This function does not return a value.
 */
function handleSelectOther(): void {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_GALLERY)
}

async function handelReset(): Promise<void> {
  // guard clause.
  if (!vehicle) return
  // indicate async process has begun.
  processing.value = true
  // update DB.
  saveStatus.value = await vehicle.updateDirectusData({
    soh: null, // remove user provided SOH.
    AdvancedConfig: null, // remove advanced config.
    UserSelectedPlugs: null, // remove user provided connectors.
  })
  // update global state.
  store.commit(MutationTypes.updateIndividualVehicle, vehicle)
  // indicate async process has completed.
  processing.value = false
  // clean up.
  setInitialValues()
  setTimeout(() => {
    saveStatus.value = undefined
  }, 2500)
}

/** Adds/removes connector from selected connectors list.
 *
 * @param connector the target `LocalConnectorData` object for the connector.
 */
function toggleConnectorSelect(connector: LocalConnectorData): void {
  flagAsDirty()
  if (connectorIsSelected(connector)) {
    // filter out matching connector from array
    selectedConnectors.value = selectedConnectors.value.filter((selectedConnector) => {
      if (selectedConnector.standard !== connector.standard) return true
      if (
        selectedConnector.standard === connector.standard &&
        selectedConnector.format !== connector.format
      )
        return true
      return false // assumes this will be the selected connector that matches both standard and format.
    })
  } else {
    // add connector to array
    selectedConnectors.value.push(connector)
  }
}

/**
 * Saves the vehicle data to the database and updates the global state.
 *
 * This function checks for changes in the vehicle's properties and updates the
 * corresponding fields in the database. It also updates the global state with
 * the latest vehicle data.
 *
 * @return {void} No return value.
 */
async function saveData(): Promise<void> {
  if (!vehicle) return
  processing.value = true
  const copyVehicle: Vehicle = vehicle
  const dataToUpdate: DirectusVehicleVariableData = {}
  const newAdvConf: DirectusAdvancedConfig = vehicle.advancedConfig
    ? { ...vehicle.advancedConfig }
    : {}
  // weights
  if (vehicleWeight.value && parseIntOrFloat(vehicleWeight.value) !== vehicle.mass) {
    newAdvConf.Mass = parseIntOrFloat(vehicleWeight.value)
  }
  // fuel
  if (fuelType.value !== (vehicle.fuelType ?? null)) {
    copyVehicle.fuelType = fuelType.value ?? undefined
    dataToUpdate.fuel_type = fuelType.value
  }
  if (batterySize.value && parseIntOrFloat(batterySize.value) !== vehicle.batterySize) {
    newAdvConf.BatterySize = parseIntOrFloat(batterySize.value)
  }
  if (SoH.value !== (vehicle.stateOfHealth ? vehicle.stateOfHealth! * 100 : undefined)) {
    const convertedSOH = to2DP(SoH.value / 100)
    copyVehicle.userProvidedStateOfHealth = convertedSOH
    dataToUpdate.soh = convertedSOH
  }
  // charging
  if (acRating.value && parseIntOrFloat(acRating.value) !== vehicle.maxElectricPowerAC) {
    newAdvConf.MaxElectricPowerAc = parseIntOrFloat(acRating.value)
  }
  if (dcRating.value && parseIntOrFloat(dcRating.value) !== vehicle.maxElectricPowerDC) {
    newAdvConf.MaxElectricPowerDc = parseIntOrFloat(dcRating.value)
  }
  // energy
  if (energyUsedWhenStopped.value) {
    const parsedVal = parseIntOrFloat(energyUsedWhenStopped.value)
    if (parsedVal) {
      newAdvConf.EnergyUsedWhenStopped = energyUsedWhenStopped.value
    }
  }
  if (energyUsedWhenMoving.value) {
    const parsedVal = parseIntOrFloat(energyUsedWhenMoving.value)
    if (parsedVal) {
      newAdvConf.EnergyUsedWhenMoving = energyUsedWhenMoving.value
    }
  }
  // physics profile
  if (aerodynamics.value && parseIntOrFloat(aerodynamics.value / 100) !== vehicle.dragCoefficient) {
    newAdvConf.DragCoefficient = parseIntOrFloat(aerodynamics.value / 100)
  }

  if (
    tyreDynamics.value &&
    parseIntOrFloat(tyreDynamics.value / 1000) !== vehicle.rollingResistanceCoefficient
  ) {
    newAdvConf.RollingResistanceCoefficient = parseIntOrFloat(tyreDynamics.value / 1000)
  }
  if (regenerativeBraking.value) {
    const parsedVal = parseIntOrFloat(regenerativeBraking.value)
    if (parsedVal && parsedVal !== vehicle.rollingResistanceCoefficient * 100) {
      newAdvConf.RegenerativeBreakingEfficiency = to2DP(parsedVal / 100)
    }
  }
  if (powerTrain.value) {
    const parsedVal = parseIntOrFloat(powerTrain.value)
    if (parsedVal && parsedVal !== vehicle.powerChainEfficiency * 100) {
      newAdvConf.PowerChainEfficiency = to2DP(parsedVal / 100)
    }
  }
  // compile connectors and cables

  // check if just model defaults
  if (vehicle.evModel) {
    // find models default connectors
    const modelDefaultConnectors = getModelConnectors(vehicle.evModel)
    if (
      selectedConnectors.value.length !== modelDefaultConnectors.length ||
      !modelDefaultConnectors.every((connector) => connectorIsSelected(connector))
    ) {
      // ASSUME: not the same and needs override
      const userSelectedPlugs = convertSelectedConnectorsToUserSelectedPlugs()
      copyVehicle.userSelectedPlugs = userSelectedPlugs
      dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) => JSON.stringify(obj))
    } else {
      // ASSUMES: is default for model and userSelectedPlugs can be removed.
      copyVehicle.userSelectedPlugs = undefined
      dataToUpdate.UserSelectedPlugs = null
    }
  } else {
    // ASSUME: just straight override as no model for comparison.
    const userSelectedPlugs = convertSelectedConnectorsToUserSelectedPlugs()
    copyVehicle.userSelectedPlugs = userSelectedPlugs
    dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) => JSON.stringify(obj))
  }

  // check advanced config has any entries
  if (Object.keys(newAdvConf).length) {
    copyVehicle.advancedConfig = newAdvConf
    dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf)
  }

  // update DB
  saveStatus.value = await copyVehicle.updateDirectusData(dataToUpdate)
  // update global state
  store.commit(MutationTypes.updateIndividualVehicle, copyVehicle)
  // indicate async process has completed
  processing.value = false
  // clean up
  setInitialValues()
  setTimeout(() => {
    saveStatus.value = undefined
  }, 2500)
}

onMounted(() => nextTick(() => setInitialValues()))

watch(
  () => vehicle,
  () => {
    setInitialValues()
  },
)
</script>

<style scoped>
* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}

.svg_base {
  width: 56px;
  height: 56px;
}

.svg_selected {
  transform: scale(1.2);
}

.svg_unselected {
  opacity: 0.4;
}
</style>
