<template>
  <v-card
    id="pwt-info-panel"
    :width="mobile ? 'initial' : '40%'"
    height="100%"
    :class="classes"
    :min-width="mobile ? `100%` : 400"
    max-width="600"
  >
    <!-- Account details card -->
    <v-card
      elevation="0"
      class="d-flex justify-space-between align-center pl-5 mb-1 rounded-0"
      style="
        height: 100px;
        border-bottom: 2px solid #dadada;
        box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.07);
      "
      @click="emitShowDashboard"
      @keydown="keyDown($event)"
    >
      <div class="d-flex align-center justify-center">
        <v-avatar color="primary mt-2">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card flat color="transparent">
          <v-card-title class="pb-0" style="font-size: 20px">
            {{ usersName ?? 'Name' }}
          </v-card-title>
          <v-card-subtitle class="text-black" style="font-size: 14px">
            {{ emailAddress ?? 'email' }}
          </v-card-subtitle>
        </v-card>
      </div>
      <v-btn color="#F3F3F3" flat icon class="align-self-start ma-2" @click.stop="emitMinimize">
        <v-icon>mdi-window-minimize </v-icon>
      </v-btn>
    </v-card>
    <!-- <v-divider
      color="#DADADA"
      style="
        box-shadow:
          2px 2px 13px,
          rgba(#dadada, 0.7);
      "
    /> -->
    <v-card
      flat
      class="pwt-scroll-container overflow-y-auto pwt-scrollbar-styles"
      style="padding-bottom: 100px"
      id="info-panel-scroll-container"
    >
      <router-view />
    </v-card>
  </v-card>
</template>

<script setup lang="ts">
import { computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { GettersTypes } from '@/store/store_types'
import Driver from '@/classes/user_classes/driver'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

const { animation } = defineProps<{ animation: 'slide-left' | 'slide-right' | null }>()

const store = useStore()

const avatarSrc = computed(() => {
  const driver: Driver | undefined = store.getters[GettersTypes.currentDriver]
  return driver?.profilePicSrc
})

const { mobile } = useDisplay()

const usersName = computed(() => store.state.user?.fullName)
const emailAddress = computed(() => store.state.user?.email)

const classes = computed(() => {
  if (animation == 'slide-left') {
    return mobile.value
      ? 'overflow-hidden ma-0 rounded-0'
      : 'overflow-hidden ma-0 rounded-0 pwt-info-panel-right-to-left-animation'
  }

  if (animation == 'slide-right') {
    return mobile.value
      ? 'overflow-hidden ma-0 rounded-0'
      : 'overflow-hidden ma-0 rounded-0 pwt-info-panel-left-to-right-animation'
  }

  return mobile.value ? 'overflow-hidden ma-0 rounded-0' : 'overflow-hidden ma-0 rounded-0'
})

const emit = defineEmits(['minimize', 'showDashboard'])

const emitMinimize = () => {
  emit('minimize')
}

const emitShowDashboard = () => {
  emit('showDashboard')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const keyDown = (event: any) => {
  if (event.key === 'Enter') emitShowDashboard()
}

const resetScrollPosition = () => {
  nextTick(() => {
    const myDiv = document.getElementById('info-panel-scroll-container')
    if (myDiv) myDiv.scrollTop = 0
  })
}

const route = useRoute()

watch(route, (to, from) => {
  if (to.name !== from.name) {
    resetScrollPosition()
  }
})
</script>

<style scoped>
#pwt-info-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1102;
  border-radius: 10px;
}

#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

#pwt-info-panel-expand-btn-mobile {
  z-index: 1102;
  position: absolute;
  top: 5px;
  left: 5px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
