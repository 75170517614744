import directus from '@/api/clients/directus-client'
import type {
  DirectusModelImageLink,
  DirectusUpdateVehicleImagesRes,
  DirectusUserSelectedPlug,
  DirectusVehicle,
  DirectusVehicleVariableData,
} from '@/types/directus-types'
import { uploadFile } from './file-calls'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'

export async function updateVehicleImages(
  directus_vehicle_id: number,
  imageToAdd: File,
): Promise<DirectusUpdateVehicleImagesRes> {
  try {
    const uploadRes = await uploadFile(imageToAdd, true)
    if (uploadRes.success && uploadRes.UUID) {
      await directus.items('Car_Record_files').createOne({
        Car_Record_id: directus_vehicle_id,
        directus_files_id: uploadRes.UUID,
      })
      return {
        success: true,
        imageUUID: uploadRes.UUID,
      }
    } else {
      return { success: false }
    }
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return { success: false }
  }
}

/** API Call to update a vehicle records model in directus. */
export async function updateVehicleSOH(vehicleID: number, newSOH: number) {
  try {
    const res = await directus.items('Car_Record').updateOne(vehicleID, { soh: newSOH })
    return res
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/** API Call to update a vehicle records model in directus. */
export async function updateVehicleModel(vehicleID: number, modelID?: string) {
  try {
    const res = await directus
      .items('Car_Record')
      .updateOne(vehicleID, { EVModel: modelID ? modelID.toString() : null })
    return res
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/** API Call to update a `Vehicle` collection records `UserSelectedPlugs` array in directus. */
export async function updateVehiclePlugs(
  vehicleID: number,
  plugs: DirectusUserSelectedPlug[] | null,
) {
  let convertedPlugs: string[] | null = null
  if (plugs) {
    convertedPlugs = plugs.map((plug) => JSON.stringify(plug))
  }

  try {
    const res = await directus
      .items('Car_Record')
      .updateOne(vehicleID, { UserSelectedPlugs: convertedPlugs })

    return res
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/**
 * API call to fetch fleet vehicles.
 *
 * @returns a list of directus `VehicleData` collection objects.
 */
export async function fetchFleetVehicles(): Promise<DirectusVehicle[] | undefined> {
  try {
    const res = await directus.items('Car_Record').readByQuery({
      limit: -1,
      fields: [
        'id',
        'Driver',
        'rego',
        'vin',
        'name',
        'fuel_type',
        'soh',
        'UserSelectedPlugs',
        'Latitude',
        'Longitude',
        'speed',
        'LastKnown',
        'StateOfCharge',
        'Ignition_On',
        'Images.directus_files_id',
        'CDB_Model_ID',
        'AdvancedConfig',
        'from_slurper',
      ],
    })
    return res.data as DirectusVehicle[]
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/**
 * API call to fetch image asset links for all supported EV models from directus.
 *
 * @returns a list of `DirectusModelImageLink` link objects.
 */
export async function fetchEVModelImageLinks(): Promise<DirectusModelImageLink[] | undefined> {
  try {
    const res = await directus.items('EV_Model_Assets').readByQuery({ limit: -1 })
    return res.data as DirectusModelImageLink[]
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return
  }
}

/**
 * API call to create a new directus `Vehicle` collection record.
 *
 * @param vehicleData the whole `DirectusVehicleCreationData` object.
 * @returns a new directus `Vehicle` collection record if successful (`VehicleData` object).
 */
export async function createNewVehicle(
  vehicleData: DirectusVehicleVariableData,
): Promise<DirectusVehicle | undefined> {
  try {
    const res = await directus.items('Car_Record').createOne(vehicleData)
    return res as unknown as DirectusVehicle
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

export async function updateVehicle(directusId: number, dataToUpdate: DirectusVehicleVariableData) {
  try {
    const res = await directus.items('Car_Record').updateOne(directusId, dataToUpdate)
    return res as unknown as DirectusVehicle
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}
