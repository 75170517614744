<template>
  <v-card class="pa-5 my-5 rounded-lg" v-if="chargingStopDetails && charger">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6" class="d-flex flex-column align-start justify-center pr-2">
          <span class="text-h6">Charge from ...</span>
          <span class="underlined-div"> {{ chargingStopDetails.percentageBeforeCharging }}%</span>
        </v-col>
        <v-col cols="6" class="d-flex flex-column align-start justify-center pl-2">
          <span class="text-h6">Charge to ...</span>
          <span class="underlined-div">{{ chargingStopDetails.percentageAfterCharging }}%</span>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters class="bg-grey-lighten-3 rounded-lg pa-3">
            <v-icon color="primary" class="mr-1"> mdi-clock-outline </v-icon>
            <span> Charge for {{ chargingStopDetails.chargingTime }} </span>
          </v-row>
        </v-col>
        <v-col cols="4" class="px-2">
          <v-row no-gutters class="bg-grey-lighten-3 rounded-lg pa-3">
            <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
            <span>
              ${{
                chargingStopDetails.chargingCost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </span>
          </v-row>
        </v-col>
        <v-col cols="4" class="pl-2">
          <v-row no-gutters class="bg-grey-lighten-3 rounded-lg pa-3">
            <v-icon color="primary" class="mr-1"> mdi-lightning-bolt </v-icon>
            <span>
              {{ `+${chargingStopDetails.energyAdded}kWh` }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import Charger from '@/classes/charger_classes/charger'
import { GettersTypes } from '@/store/store_types'
import type { ChargingDetails } from '@/types/sheared_local_types'
import type Trip from '@/classes/trip_classes/trip'

const store = useStore()

const charger = computed<Charger | undefined>(() => store.getters[GettersTypes.selectedChargerData])

const chargingStopDetails = computed<ChargingDetails | undefined>(() => {
  const trip: Trip | undefined = store.getters[GettersTypes.selectedTripData]
  if (trip && charger.value) {
    return charger.value.getTripChargingStopData(trip)
  }
  return undefined
})
</script>

<style scoped>
.underlined-div {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}
</style>
