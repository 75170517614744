import type { DirectusUser } from '@/types/directus-types'

export default class User {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Directus `Company` collection record id. */
  directusId?: string

  /** The users first name. */
  firstName?: string

  /** The users last name. */
  lastName?: string

  /** The users email address. */
  email?: string

  /** Related directus `Driver` collection record id. */
  driverDirectusId?: number

  /** Related directus `Company` collection record id. */
  companyDirectusId?: number

  /** Related directus `Role` collection record id. */
  directusRoleUUID?: string

  /** List of related directus car record collection ids */
  cars: number[]

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Creates a new `User` class instance.
   *
   * @param {Object} [options] - Optional configuration options.
   * @param {string} [options.directusId] - Directus `directus_users` collection record id.
   * @param {string} [options.firstName] - The users first name.
   * @param {string} [options.lastName] - The users last name.
   * @param {string} [options.email] - The users email address.
   * @param {number} [options.driverDirectusId] - Related directus `Driver` collection record id.
   * @param {number} [options.companyDirectusId] - Related directus `Company` collection record id.
   * @param {string} [options.directusRoleUUID] - Related directus `Role` collection record id.
   * @param {number[]} [options.cars] - List of related directus car record collection ids
   */
  constructor({
    directusId = undefined,
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    driverDirectusId = undefined,
    companyDirectusId = undefined,
    directusRoleUUID = undefined,
    cars = [],
  }: {
    /** Directus `directus_users` collection record id. */
    directusId?: string
    /** The users first name. */
    firstName?: string
    /** The users last name. */
    lastName?: string
    /** The users email address. */
    email?: string
    /** Related directus `Driver` collection record id. */
    driverDirectusId?: number
    /** Related directus `Company` collection record id. */
    companyDirectusId?: number
    /** Related directus `Role` collection record id. */
    directusRoleUUID?: string
    /** List of related directus car record collection ids */
    cars?: number[]
  }) {
    this.directusId = directusId
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.driverDirectusId = driverDirectusId
    this.companyDirectusId = companyDirectusId
    this.directusRoleUUID = directusRoleUUID
    this.cars = cars
  }

  /**
   * Creates a new `User` class instance populated with data from a directus `User` collections record.
   *
   * @param data directus `User` collections record.
   * @returns a new `User` class instance
   */
  static fromDirectusData(data: DirectusUser): User {
    return new User({
      directusId: data.id,
      firstName: data.first_name ?? undefined,
      lastName: data.last_name ?? undefined,
      email: data.email ?? undefined,
      driverDirectusId: data.Driver ?? undefined,
      companyDirectusId: data.Company ?? undefined,
      directusRoleUUID: data.role ?? undefined,
      cars: data.Cars ?? undefined,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the users full name in a displayable string. */
  public get fullName(): string {
    return (this.firstName ? this.firstName + ' ' : '') + (this.lastName ?? '')
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
