<template>
  <div class="pwt-custom-overlay">
    <v-card
      class="px-4 pb-3 rounded-0 d-flex flex-column align-items-start justify-start"
      light
      :max-width="mobile ? '' : '800px'"
      :min-width="mobile ? '' : '350px'"
      :min-height="mobile ? '' : '100px'"
      :width="mobile ? '100vw' : '40%'"
      height="100vh"
      @keydown="keyDown($event)"
    >
      <template v-if="checkingToken">
        <v-card-text v-if="mobile" class="d-flex flex-column align-items-center justify-center">
          <span style="width: 100%" class="text-h5 text-center"> Cheeking authentication </span>
          <div style="width: 100%" class="d-flex justify-center">
            <LoadingCard />
          </div>
        </v-card-text>
        <v-card-text
          style="height: 100%; min-height: 100px; position: relative"
          class="d-flex justify-center align-center px-8 pt-5"
          v-else
        >
          <v-progress-circular indeterminate color="primary" class="mr-2" />
          <span class="text-h5">Checking authentication</span>
          <PulseLoader />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title class="text-h3" style="font-family: Public Sans !important">
          Optimiser
        </v-card-title>
        <v-card-text class="text-body-1 font-weight-medium" style="">
          Power Trip's complete EV management suite
        </v-card-text>
        <v-card v-if="status === 'FAILED' || status === undefined" flat class="px-4 pt-10">
          <v-btn :href="redirectUrl" color="primary" class="text-none rounded-pill" outlined block>
            Login with {{ ssoName }}
          </v-btn>
        </v-card>
        <v-card-text class="d-flex text-error" v-if="status === 'FAILED'"
          ><v-icon color="red" class="mr-1">mdi-alert-circle</v-icon> Whoops! Looks like your login
          details are not correct. Please check your details and try again.</v-card-text
        >
        <v-card-text class="d-flex" v-if="status === 'SUCCESS'">
          <v-icon color="green" class="mr-1">mdi-check-circle</v-icon>
          Successfully authenticated.
        </v-card-text>
        <template v-if="status === 'PROCESSING'">
          <v-card-text v-if="mobile" class="d-flex align-items-center justify-center">
            <LoadingCard />
          </v-card-text>
          <v-card-text v-else class="d-flex align-items-center">
            <v-progress-circular indeterminate color="primary" class="mr-1" size="22" />
            <span class="d-flex align-center">
              <span> Processing </span>
              <PulseLoader />
            </span>
          </v-card-text>
        </template>
      </template>
    </v-card>
  </div>
</template>
<script lang="ts" setup>
import PulseLoader from '../../ui-elements/loaders/PulseLoader.vue'
import { useStore } from 'vuex'
import LoadingCard from '../../ui-elements/loaders/LoadingCard.vue'
import { type State } from '@/store/store_types'
import { directusBaseURL, passedSSOName } from '@/api/clients/directus-client'
import { capitalizeFirstLetter } from '@/utils/stringUtils'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

const store = useStore<State>()

const status = computed(() => store.state.authStatus)
const checkingToken = computed(() => store.state.checkingToken)
const redirectUrl = computed(
  () =>
    `https://${directusBaseURL}/auth/login/sso?prompt=login&redirect=${window.location.origin + window.location.pathname}`,
)
const ssoName = computed(() => (passedSSOName ? capitalizeFirstLetter(passedSSOName) : 'SSO'))

function keyDown(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    window.location.href = redirectUrl.value
  }
}
</script>
<style scoped>
.pwt-custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1400;
  display: flex;
  backdrop-filter: blur(2px);
}
</style>
