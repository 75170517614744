<template>
  <v-dialog
    :model-value="activator"
    :width="mdAndUp ? '70%' : '80%'"
    :content-class="xs ? '' : 'rounded-lg'"
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
    scrollable
    :max-width="mdAndUp ? (small ? '600px' : '800px') : small ? '400px' : '650px'"
    :min-width="mdAndUp ? (small ? '450px' : '600px') : small ? '250px' : '350px'"
    :fullscreen="xs"
    retain-focus
    :max-height="mdAndUp ? '90vh' : undefined"
  >
    <v-card
      class="pa-5 bg-background overflow-hidden"
      :class="xs ? '' : 'rounded-lg'"
      style="height: 100%; position: relative"
    >
      <div class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden">
        <slot></slot>
      </div>
      <v-btn
        icon
        @click="emitClose"
        style="position: absolute; top: 8px; right: 12px"
        color="transparent"
        flat
      >
        <v-icon color="grey-lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp, xs } = useDisplay()

const emit = defineEmits(['close'])

const { activator, small } = defineProps({
  activator: {
    type: Boolean,
  },
  small: {
    type: Boolean,
    default: false,
  },
})

const emitClose = () => {
  emit('close')
}

const keyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') emitClose()
}
</script>
<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
