import type { CollectionNames } from '@/api/clients/directus-client'
import {
  directusCreateOne,
  directusDeleteOne,
  directusReadByQuery,
  directusUpdateOne,
} from './helperFunctions'

const collectionName: CollectionNames = 'Asset_Bookings'

/**
 * Fetches all bookings for a given asset from directus.
 *
 * @param assetID the id of the asset you need to fetch the bookings for
 * @returns a list of `DirectusAssetBooking` objects if successful undefined if it errors out.
 */
export async function fetchAssetBookingsByAssetID(
  assetID: string | number,
): Promise<DirectusAssetBooking[] | undefined> {
  return await directusReadByQuery<DirectusAssetBooking>(collectionName, {
    filter: {
      asset_id: { _eq: assetID },
    },
  })
}

/**
 * Creates an asset booking record in directus.
 *
 * @param bookingData data for the booking to be created
 * @returns the created booking
 */
export async function createAssetBooking(
  bookingData: DirectusAssetBookingCreationData,
): Promise<DirectusAssetBooking | undefined> {
  return await directusCreateOne<DirectusAssetBooking>(collectionName, bookingData)
}

/**
 * Deletes an asset booking record from directus.
 *
 * @param bookingId the record id of the booking to be deleted.
 * @returns status of the operations outcome.
 */
export async function deleteAssetBooking(bookingId: string | number) {
  return await directusDeleteOne(collectionName, bookingId)
}

/**
 * Updates an asset booking record in directus.
 *
 * @param bookingId the record id of the booking to be updated.
 * @param dataToUpdate the booking data to be updated.
 * @returns the updated asset booking record if successful undefined if not.
 */
export async function updateAssetBooking(
  bookingId: string,
  dataToUpdate: Partial<DirectusAssetBooking>,
): Promise<DirectusAssetBooking | undefined> {
  return await directusUpdateOne<DirectusAssetBooking>(collectionName, bookingId, dataToUpdate)
}

export interface DirectusAssetBooking {
  /** UUID for this booking. */
  id: string // UUID
  status: 'Published' | 'Draft' | 'Archived'
  sort: number
  /** Related UUID for user that created this booking. */
  user_created: string // related UUID
  /** The DateTime string for when this booking was created. */
  date_created: string // DateTime
  /** Related UUID for user that updated this booking. */
  user_updated: string // related UUID
  /** The DateTime string for when this booking was updated. */
  date_updated: string // DateTime
  /** The DateTime string for when this booking is meant to start. */
  start: string
  /** The DateTime string for when this booking is meant to end. */
  end: string
  /** The type of asset that has been booked */
  asset_type: 'vehicle' | 'charger'
  /**
   * The id for the asset.
   *
   * NOTE: can be an external identifier with no directus relation. Can also be
   * either a stringified int or UUID, the client will need to process this.
   */
  asset_id: string // UUID or stringified int client will have to handle parsing.
  /**
   * Related UUID for user that this booking is for.
   *
   * NOTE: can be different from the user making the booking.
   */
  user_id: string // related UUID
}

export interface DirectusAssetBookingCreationData {
  /** The DateTime string for when this booking is meant to start. */
  start: string
  /** The DateTime string for when this booking is meant to end. */
  end: string
  /** The type of asset that has been booked */
  asset_type: 'vehicle' | 'charger'
  /**
   * The id for the asset.
   *
   * NOTE: can be an external identifier with no directus relation. Can also be
   * either a stringified int or UUID, the client will need to process this.
   */
  asset_id: string // UUID or stringified int client will have to handle parsing.
  /**
   * Related UUID for user that this booking is for.
   *
   * NOTE: can be different from the user making the booking.
   */
  user_id: string // related UUID
}
