<template>
  <v-card flat class="bg-background pa-5">
    <v-list three-line color="background">
      <v-list-group v-model="selection" multiple class="d-flex flex-wrap justify-start">
        <v-list-item
          v-for="group in savedTripsGroupedByEV"
          :key="group.evModel"
          :style="mdAndUp ? 'max-width: 50%' : ''"
          :model-value="group.evModel"
        >
          <template v-slot:prepend="{ isSelected }">
            <v-icon v-if="isSelected" color="success"> mdi-check-circle </v-icon>
            <v-icon v-else color="primary"> mdi-map-outline </v-icon>
          </template>
          <v-list-item-title>
            {{ getModelName(group.evModel) || 'Unknown' }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row no-gutters>
              {{ group.trips.length }} saved route{{ group.trips.length > 1 ? 's' : '' }}
            </v-row>
            <v-row no-gutters>
              {{ getNumberOfTrips(group).toLocaleString() }}
              frequent trip{{ getNumberOfTrips(group) === 1 ? '' : 's' }}
              per year
            </v-row>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-card-actions>
      <v-spacer></v-spacer>
      <ElevatedBtn @click="applyFilters"> Apply filter </ElevatedBtn>
      <OutlinedBtn @click="clearFilters"> Clear filter </OutlinedBtn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts" setup>
import Trip from '@/classes/trip_classes/trip'
import { GettersTypes, MutationTypes, type State } from '@/store/store_types'
import { type SortedTripsGroup } from '@/types/sheared_local_types'
import sortTripsViaEVGroups from '@/utils/sortTripsViaEVGroups'
import ElevatedBtn from '../../ui-elements/buttons/ElevatedBtn.vue'
import OutlinedBtn from '../../ui-elements/buttons/OutlinedBtn.vue'
import { calcFrequencyAdjustment } from '@/utils/calcYearsSavings'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useDisplay } from 'vuetify'

const emit = defineEmits(['close'])
const store = useStore<State>()
const { mdAndUp } = useDisplay()

const selection = ref<string[]>([])
const evModels = computed(() => store.state.evModels)
const savedTrips = computed<Trip[]>(() => store.getters[GettersTypes.savedTrips])
const savedTripsGroupedByEV = computed(() => sortTripsViaEVGroups(savedTrips.value))

/**
 * Returns the name of the EV model given its ID, or undefined if no match
 * is found.
 * @param id The ID of the EV model to search for.
 * @returns The name of the EV model or undefined.
 */
function getModelName(id: string): string | undefined {
  return evModels.value.find((model) => model.id === id)?.name
}

/**
 * Calculates the total number of trips in a given group, taking into account
 * the frequency of each trip.
 * @param group The group to calculate the total number of trips for.
 * @returns The total number of trips in the given group, rounded up to the
 * nearest integer.
 */
function getNumberOfTrips(group: SortedTripsGroup): number {
  let count = 0
  group.trips.forEach((trip) => {
    if (trip.frequency) {
      count += calcFrequencyAdjustment(trip.frequency)
    }
  })
  return Math.round(count)
}

/**
 * Clears the current filter selection and resets the group selection to
 * all groups.
 */
function clearFilters() {
  selection.value = []
  store.commit(MutationTypes.setTripsGroups, undefined)
}

/**
 * Applies the current filter selection to the trip groups. If a group's EV
 * model ID matches one of the IDs in the selection, it is added to the
 * temporary array. The temporary array is then passed to the Vuex store
 * mutation to update the trip groups. Finally, the group selection dialog
 * is closed.
 */
function applyFilters() {
  const tempArray: SortedTripsGroup[] = []
  selection.value.forEach((id) => {
    const group = savedTripsGroupedByEV.value.find((group) => group.evModel === id)
    if (group) tempArray.push(group)
  })
  store.commit(MutationTypes.setTripsGroups, tempArray)
  emit('close')
}
</script>
