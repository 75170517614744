// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Directus } from '@directus/sdk' // quietly ignore typescript errors, SDK needs to be updated at a latter date this will have better type support

// get base path
const elementData = document?.querySelector('powertrip-optimiser')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const passedSSOName = elementData?.attributes?.['sso' as any]?.nodeValue // this version of ts has issue typing dom objects correctly hence the any. Remove if this is no longer true.

/** Base URL for the directus services and storage. */
export const directusBaseURL =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elementData?.attributes?.['backend' as any]?.nodeValue ?? 'https://backend.powertrip.earth'

export const chargerCustomEndpoint = directusBaseURL + '/charger/locations/all'
export const modelsCustomEndpoint = directusBaseURL + '/charger/vehicles/all'

/** Directus asset storage path extension. */
export const directusAssetsRoute = directusBaseURL + '/assets/'

/**
 * Accepted strings for directus collection names.
 *
 * NOTE: there should also be a file handling all interactions with each collection
 * e.g. `assetBookingsCalls.ts` for `"Asset_Bookings"`.
 */
export type CollectionNames =
  | 'Asset_Bookings'
  | 'Car_Record'
  | 'Company'
  | 'Driver'
  | 'EV_Model_Assets'
  | 'ManagedContent'
  | 'NetworkBranding'
  | 'Optimiser_Settings'
  | 'ScoringConfig'
  | 'Vehicle_files'
  | 'Favourite_Locations'
  | 'Saved_Optimised_Trips'
  | 'Saved_Route_Plans'
  | 'SavedRoutePlans'

// Directus SDK options
const config = {
  auth: {
    mode: 'json',
    autoRefresh: true,
    msRefreshBeforeExpires: 30000,
  },
  storage: {
    prefix: '',
    mode: 'LocalStorage',
  },
  transport: {
    url: directusBaseURL,
    params: {},
    headers: {
      'Cache-Control': 'no-store',
    },
  },
}

/** Local directus client. */
const directus = new Directus(directusBaseURL, config)

export default directus
