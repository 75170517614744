<template>
  <!-- processing update view -->
  <v-card flat class="px-5 d-flex flex-column justify-center align-center" v-if="processing">
    <v-card-title>
      Processing
      <PulseLoader />
    </v-card-title>
    <LoadingCard />
  </v-card>
  <!-- normal view -->
  <v-card flat class="px-5 bg-background" v-else>
    <v-card-text>
      <v-row no-gutters v-if="user">
        <v-avatar color="primary" class="mr-3 mt-1">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card-subtitle class="d-flex flex-column text-grey pt-0">
          <span class="font-weight-medium text-h6">
            {{ user.fullName }}
          </span>
          <span>
            {{ user.email }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
      <v-row no-gutters align="center" v-else>
        <v-skeleton-loader type="avatar" class="mr-3" />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
      </v-row>
    </v-card-text>
    <v-card elevation="1" class="pa-5 rounded-lg mb-5 bg-background">
      <v-card-text class="d-flex pb-0">
        <v-row no-gutters :align="mdAndUp ? 'center' : 'start'" class="flex-column flex-md-row">
          <span class="mr-3">Profile picture</span>
          <v-avatar color="primary" class="mr-3">
            <v-img v-if="avatarSrc" :src="avatarSrc" />
            <v-icon v-else dark> mdi-account-circle </v-icon>
          </v-avatar>
          <v-spacer v-if="!showFileInput" />
          <TextBtn v-if="!showFileInput" @click="showFileInput = true"> Change picture </TextBtn>
          <v-file-input
            v-if="showFileInput"
            accept="image/*"
            label="Upload a new profile image"
            dense
            class="pt-4"
            v-model="avatar"
            prepend-icon="mdi-camera"
            :style="mdAndUp ? '' : 'width: 100%'"
          ></v-file-input>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-text-field
            class="rounded-lg mr-sm-2"
            label="First name"
            v-model="firstName"
          ></v-text-field>
          <v-text-field
            class="rounded-lg ml-sm-2"
            label="Last name"
            v-model="lastName"
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <OutlinedBtn v-if="hasChanges" class="mt-n2 mr-2" @click="cancel"> Cancel </OutlinedBtn>
        <ElevatedBtn class="mt-n2" @click="saveAndUpload" :disabled="!hasChanges">
          Save changes
        </ElevatedBtn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts" setup>
import LoadingCard from '@/components/ui-elements/loaders/LoadingCard.vue'
import PulseLoader from '@/components/ui-elements/loaders/PulseLoader.vue'
import Driver from '@/classes/user_classes/driver'
import { GettersTypes, type State } from '@/store/store_types'
import type { DirectusDataToUpdate } from '@/types/directus-types'
import { ActionTypes } from '@/store/store_types'
import ElevatedBtn from '@/components/ui-elements/buttons/ElevatedBtn.vue'
import TextBtn from '@/components/ui-elements/buttons/TextBtn.vue'
import { useStore } from 'vuex'
import { computed, nextTick, ref } from 'vue'
import { onMounted } from 'vue'
import { useDisplay } from 'vuetify'
import OutlinedBtn from '@/components/ui-elements/buttons/OutlinedBtn.vue'

const store = useStore<State>()
const { mdAndUp } = useDisplay()

const firstName = ref('')
const lastName = ref('')
const avatar = ref<File | null>(null)
const showFileInput = ref(false)

const driver = computed<Driver | undefined>(() => store.getters[GettersTypes.currentDriver])
const processing = computed(() => store.state.updatingUserStatus)
const user = computed(() => store.state.user)
const avatarSrc = computed(() => driver.value?.profilePicSrc)
const hasChanges = computed(() => hasChanged())

function setInitialValues() {
  firstName.value = user.value?.firstName ?? ''
  lastName.value = user.value?.lastName ?? ''
}

function saveAndUpload() {
  // guard clause
  if (!user.value) return
  if (!hasChanges.value) return
  // check if data has changed from initial values and prep object for dispatch.
  const data: DirectusDataToUpdate = {
    avatar: avatar.value ?? undefined,
    firstName: firstName.value !== user.value.firstName ? firstName.value : undefined,
    lastName: lastName.value !== user.value.lastName ? lastName.value : undefined,
  }
  store.dispatch(ActionTypes.updateUser, data)
}

function hasChanged(): boolean {
  return (
    firstName.value !== user.value?.firstName ||
    lastName.value !== user.value?.lastName ||
    avatar.value !== null
  )
}

function cancel() {
  setInitialValues()
  showFileInput.value = false
  avatar.value = null
}

onMounted(() => {
  nextTick(() => {
    setInitialValues()
  })
})
</script>
