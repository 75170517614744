<template>
  <!-- collapsed view -->
  <div v-if="!expanded" id="pwt-info-panel-expand-btn" style="z-index: 1100">
    <v-row class="pt-3">
      <v-btn icon @click="expand" class="bg-background mx-2">
        <v-icon color="grey">mdi-menu </v-icon>
      </v-btn>
      <v-btn
        v-if="!trip"
        class="rounded-pill text-none text-caption mr-2 px-4"
        small
        @click="toggleDC(!showingDC)"
        :color="showingDC ? 'primary' : undefined"
      >
        {{ mobile ? 'DC' : 'Fast chargers' }}
      </v-btn>
      <v-btn
        v-if="!trip"
        class="rounded-pill text-none text-caption mr-2 px-4"
        small
        @click="toggleAC(!showingAC)"
        :color="showingAC ? 'primary' : undefined"
      >
        {{ mobile ? 'AC' : 'Slow chargers' }}
      </v-btn>
      <v-btn
        v-if="trip"
        small
        class="rounded-pill text-none text-caption mr-2 px-4"
        @click="toggleChargers"
        :color="showingChargersAlongRoute || fetchingChargers ? 'primary' : undefined"
        :loading="fetchingChargers"
      >
        <span v-if="!mobile"> All chargers on route </span>
        <v-icon v-else :color="showingChargersAlongRoute ? 'white' : 'grey'" small>
          mdi-map-marker-radius
        </v-icon>
      </v-btn>
    </v-row>
  </div>
  <!-- expanded view -->
  <div v-else>
    <!-- floating controls -->
    <div id="pwt-info-panel-minimize-btn" :class="classes">
      <v-row class="pt-3 pl-2">
        <v-btn
          v-if="!trip"
          dense
          class="rounded-pill text-none text-caption mr-2 px-6"
          small
          @click="toggleDC(!showingDC)"
          :color="showingDC ? '#6DC6FF' : '#FFFFFF'"
          elevation="5"
          :style="showingDC ? `border: 1px solid #2eaeff` : ``"
          height="30px"
        >
          <span
            :style="
              showingDC ? 'color: black; font-weight: normal' : 'color: #161616; font-weight: light'
            "
          >
            Fast chargers
          </span>
        </v-btn>
        <v-btn
          v-if="!trip"
          class="rounded-pill text-none text-caption mr-2 px-6"
          small
          @click="toggleAC(!showingAC)"
          :color="showingAC ? '#6DC6FF' : '#FFFFFF'"
          elevation="5"
          :style="showingAC ? `border: 1px solid #2eaeff` : ``"
          height="30px"
        >
          <span
            :style="
              showingAC ? 'color: black; font-weight: normal' : 'color: #161616; font-weight: light'
            "
          >
            Slow chargers
          </span>
        </v-btn>
        <v-btn
          v-if="trip"
          class="rounded-pill text-none text-caption mr-2 px-4"
          @click="toggleChargers"
          :color="showingChargersAlongRoute || fetchingChargers ? 'primary' : undefined"
          :loading="fetchingChargers"
          small
        >
          All chargers on route
        </v-btn>
      </v-row>
    </div>
    <!-- main card -->
    <InfoPanel @minimize="minimize" :animation="animation" @showDashboard="emitShowDashboard" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import InfoPanel from './InfoPanel.vue'
import { GettersTypes, MutationTypes } from '@/store/store_types'
import Trip from '@/classes/trip_classes/trip'
import { useStore } from 'vuex'
import { useDisplay } from 'vuetify'

const store = useStore()
const { mobile } = useDisplay()

const expanded = ref(true)
const animation = ref<'slide-left' | 'slide-right' | null>(null)
const fetchingChargers = ref(false)

const trip = computed<Trip | undefined>(() => store.getters[GettersTypes.selectedTripData])

const showingChargersAlongRoute = computed(() => store.state.displayAllChargersAlongRoute)
const showingAC = computed(
  () => store.state.showPrivateACChargers && store.state.showPublicACChargers,
)
const showingDC = computed(
  () => store.state.showPrivateDCChargers && store.state.showPublicDCChargers,
)

const classes = computed(() => {
  if (animation.value == 'slide-left') {
    return mobile.value ? 'ma-sm-2' : 'ma-sm-2 pwt-info-panel-btn-right-to-left-animation'
  }
  if (animation.value == 'slide-right') {
    return mobile.value ? 'ma-sm-2' : 'ma-sm-2 pwt-info-panel-btn-left-to-right-animation'
  }

  return 'ma-sm-2'
})

function expand() {
  store.commit(MutationTypes.setInfoPanelAnimation, 'slide-right')
  animation.value = 'slide-right'
  expanded.value = true
  clearAnimation()
}

function minimize() {
  store.commit(MutationTypes.setInfoPanelAnimation, 'slide-left')
  animation.value = 'slide-left'
  setTimeout(() => {
    expanded.value = false
  }, 500)
  clearAnimation()
}

function clearAnimation() {
  setTimeout(() => {
    store.commit(MutationTypes.setInfoPanelAnimation, undefined)
    animation.value = null
  }, 701)
}

const emit = defineEmits(['minimize', 'showDashboard'])

function emitShowDashboard() {
  emit('showDashboard')
}

async function toggleChargers() {
  fetchingChargers.value = true
  if (trip.value?.chargersAlongRouteCDBIDs.length) {
    await trip.value.getRadarData()
  }
  store.commit(MutationTypes.setDisplayAllChargersAlongRoute, !showingChargersAlongRoute.value)
  fetchingChargers.value = false
}

function toggleAC(changeTo: boolean) {
  store.commit(MutationTypes.setShowPrivateACChargers, changeTo)
  store.commit(MutationTypes.setShowPublicACChargers, changeTo)
}

function toggleDC(changeTo: boolean) {
  store.commit(MutationTypes.setShowPrivateDCChargers, changeTo)
  store.commit(MutationTypes.setShowPublicDCChargers, changeTo)
}
</script>

<style scoped>
#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  top: 8px;
  left: 8px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-btn-left-to-right-animation {
  left: -56%;
  -webkit-animation: btn-left-to-right 700ms linear forwards;
  animation: btn-left-to-right 700ms linear forwards;
}

.pwt-info-panel-btn-right-to-left-animation {
  left: 0;
  -webkit-animation: btn-right-to-left 1s linear forwards;
  animation: btn-right-to-left 1s linear forwards;
}

@-webkit-keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@-webkit-keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}

@keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}
</style>
