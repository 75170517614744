<template>
  <v-card flat class="pa-5 bg-background">
    <!-- profile section -->
    <ProfileCard
      @view-account="() => emit('view-account')"
      @view-vehicle="() => emitViewVehicle"
      @view-vehicles="() => emit('view-all-vehicles')"
      @view-defaults="() => emit('view-defaults')"
      @logout="() => emit('logout')"
    />
    <!-- selected vehicle section -->
    <DefaultVehicleCard @view-vehicle="emitViewVehicle" class="mb-10" />
    <!-- saved trips section -->
    <SavedTripCarousel
      @view-trip="() => emit('view-trip')"
      @view-all-trips="() => emit('view-saved-trips')"
      class="mb-10"
    />
    <!-- my fleet section -->
    <MyFleetContent
      @view-vehicles="() => emit('view-all-vehicles')"
      @view-locations="() => emit('view-locations')"
      @view-vehicle="emitViewVehicle"
    />
  </v-card>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import ProfileCard from '../user/profile/ProfileCard.vue'
import DefaultVehicleCard from '../vehicles/DefaultVehicleCard.vue'
import SavedTripCarousel from '../trips/history/SavedTripCarousel.vue'
import MyFleetContent from './MyFleetContent.vue'
import { GettersTypes } from '@/store/store_types'
import type Vehicle from '@/classes/vehicle_classes/vehicle'

const store = useStore()

const emit = defineEmits<{
  (e: 'view-vehicle', id: string | undefined): void
  (e: 'view-account'): void
  (e: 'view-trip'): void
  (e: 'view-all-vehicles'): void
  (e: 'view-locations'): void
  (e: 'view-defaults'): void
  (e: 'logout'): void
  (e: 'view-saved-trips'): void
}>()

const emitViewVehicle = (vehicle?: Vehicle) => {
  const id =
    vehicle?.localId === store.getters[GettersTypes.selectedVehicleData]?.localId
      ? undefined
      : vehicle?.localId
  emit('view-vehicle', id)
}
</script>
