<template>
  <v-container class="px-5 bg-background">
    <v-card-title class="text-tertiary pt-5 d-flex justify-space-between px-0">
      <span> Favourite locations </span>
      <!-- add new location button -->
      <ElevatedBtn @click="showAddDialog = true">Add a location</ElevatedBtn>
    </v-card-title>
    <!-- fav location list -->
    <FavLocationCard
      v-for="favLocation in favLocations"
      :key="favLocation.localId"
      :favLocation="favLocation"
      @remove="deleteFavLocation"
      @edit="editFavLocation"
    />
    <!-- dialog windows -->
    <FavLocationAddDialog :activator="showAddDialog" @close="showAddDialog = false" />
    <FavLocationEditDialog
      v-if="editingFavLocation"
      :activator="showEditDialog"
      :favLocation="typedEditingFavLocation"
      @close="showEditDialog = false"
    />
  </v-container>
</template>
<script lang="ts" setup>
import { MutationTypes, type State } from '@/store/store_types'
import FavLocationCard from './FavLocationCard.vue'
import FavLocationAddDialog from './FavLocationAddDialog.vue'
import FavouriteLocation from '@/classes/favouriteLocation'
import FavLocationEditDialog from './FavLocationEditDialog.vue'
import { deleteFavouriteLocation } from '@/api/calls/directus-calls/favourite-locations-calls'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

const store = useStore<State>()

const favLocations = computed(() => store.state.favLocations)

const showAddDialog = ref(false)
const showEditDialog = ref(false)
const editingFavLocation = ref<FavouriteLocation | undefined>(undefined)
const typedEditingFavLocation = computed(() => editingFavLocation.value as FavouriteLocation) // Warning: only use if showingTrip is defined. Workaround foe TS cant match class private properties see https://github.com/microsoft/TypeScript/issues/42683 for unhelpful but working as intended behaviour

/**
 * Edits a favourite location by opening the edit dialog and setting the
 * editingFavLocation data property to the provided favourite location.
 *
 * @param {FavouriteLocation} favLocation - The favourite location to edit.
 * @return {void} This function does not return anything.
 */
function editFavLocation(favLocation: FavouriteLocation): void {
  editingFavLocation.value = favLocation
  showEditDialog.value = true
}

/**
 * Deletes a favourite location from the store.
 *
 * @param {FavouriteLocation} favLocation - The favourite location to delete.
 * @return {void} This function does not return anything.
 */
function deleteFavLocation(favLocation: FavouriteLocation): void {
  store.commit(MutationTypes.deleteFavLocation, favLocation.localId)
  if (favLocation.directusId) {
    deleteFavouriteLocation(favLocation.directusId)
  }
}
</script>
