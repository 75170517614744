<template>
  <v-card flat>
    <v-row class="mx-2">
      <!-- conditionally rendered plug images -->
      <v-col cols="auto" v-for="(connector, index) in filteredArray" :key="index">
        <v-img
          :src="connector.getPlugIconSrc"
          width="50"
          height="50"
          contain
          class="mx-auto"
          :style="disableGrayScale ? '' : isCompatible(connector) ? '' : 'filter: grayscale(1);'"
        />
        <v-card-text>{{ connector.displayName }}</v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import Connector from '@/classes/charger_classes/connector'
import { GettersTypes } from '@/store/store_types'

interface Props {
  plugs: Connector[]
  disableGrayScale?: boolean
}

const props = defineProps<Props>()

const store = useStore()

const filteredArray = computed(() => {
  const usedArray: string[] = []
  const tempArray: Connector[] = []
  props.plugs.forEach((connector) => {
    if (connector.standard && connector.format) {
      const usedName = connector.standard + connector.format
      if (!usedArray.includes(usedName)) {
        tempArray.push(connector)
        usedArray.push(usedName)
      }
    }
  })
  return tempArray
})

const vehicle = computed(() => store.getters[GettersTypes.selectedVehicleData])

const isCompatible = (connector: Connector): boolean => {
  if (!vehicle.value) return false
  const compatibility = connector.isCompatible(vehicle.value)
  return compatibility !== 'incompatible'
}
</script>
