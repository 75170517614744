<template>
  <v-card flat class="bg-background pa-0 mb-10">
    <v-card class="py-4 pl-md-12 rounded-lg bg-background">
      <v-row class="flex-column flex-md-row">
        <v-col md="3">
          <!-- image section -->
          <v-skeleton-loader
            type="image"
            :width="mdAndUp ? '100%' : '40%'"
            height="140px"
            v-if="!selectedVehicle"
            class="ml-4 ml-md-0"
          />
          <v-row no-gutters v-else-if="smAndDown">
            <v-img
              contain
              :src="imageSrc(selectedVehicle)"
              aspect-ratio="1"
              width="40%"
              height="auto"
              class="ml-4 flex-grow-0"
            ></v-img>
            <v-spacer />
            <v-btn icon @click="emitViewVehicle">
              <v-icon color="grey-lighten-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </v-row>
          <v-img
            v-else
            contain
            :src="imageSrc(selectedVehicle)"
            aspect-ratio="1"
            width="100%"
            height="100%"
          ></v-img>
        </v-col>
        <v-col md="9">
          <!-- details section -->
          <div v-if="selectedVehicle">
            <v-card-title>
              <v-row no-gutters align="center">
                {{ selectedVehicle.name ?? 'unnamed vehicle' }}
                <v-spacer></v-spacer>
                <TextBtn @click="emitViewVehicle" v-if="mdAndUp"> Vehicle settings </TextBtn>
              </v-row>
            </v-card-title>
            <v-card-subtitle class="font-weight-medium pr-12">
              <p class="mb-0">
                {{ selectedVehicle.licensePlate ?? 'unrecorded license plate' }}
              </p>
              <p v-if="selectedVehicle.evModel">
                {{ selectedVehicle.evModel.name }}
              </p>
            </v-card-subtitle>
            <v-card-text class="pr-md-12">
              <NotConnectedBatteryDisplay v-if="!selectedVehicle.stateOfCharge" />
              <BatteryDisplay v-else :battery="selectedVehicle.stateOfCharge" />
              <!-- conditionally rendered change select vehicle button on mobile screens -->
              <OutlinedBlockBtn @click="handleSelectOther()" v-if="smAndDown" class="mt-5">
                Change selected vehicle
              </OutlinedBlockBtn>
            </v-card-text>
          </div>
          <div v-else>
            <v-row no-gutters class="mb-4 ml-5">
              <v-skeleton-loader type="heading" width="70%" />
              <v-skeleton-loader v-if="mdAndUp" type="heading" width="30%" />
            </v-row>
            <v-skeleton-loader type="text" width="40%" class="ml-5" />
            <v-skeleton-loader type="text" width="70%" class="mb-4 ml-5" />
            <v-skeleton-loader type="heading" width="100%" class="ml-5" />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- conditionally rendered change select vehicle button on laptop/desktop size screens -->
    <v-row no-gutters v-if="mdAndUp" class="mt-3">
      <v-spacer />
      <OutlinedBtn @click="handleSelectOther()"> Change selected vehicle </OutlinedBtn>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { GettersTypes, MainDialogContent, MutationTypes, type State } from '@/store/store_types'
import getAssetSrc from '@/utils/getAssetSrc'
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import OutlinedBlockBtn from '../ui-elements/buttons/OutlinedBlockBtn.vue'
import BatteryDisplay from '../ui-elements/displays/BatteryDisplay.vue'
import OutlinedBtn from '../ui-elements/buttons/OutlinedBtn.vue'
import NotConnectedBatteryDisplay from '../ui-elements/displays/NotConnectedBatteryDisplay.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'

const emit = defineEmits<{
  (e: 'view-vehicle', vehicle: Vehicle): void
}>()

const store = useStore<State>()

const { smAndDown, mdAndUp } = useDisplay()

const selectedVehicle = computed<Vehicle | undefined>(
  () => store.getters[GettersTypes.selectedVehicleData],
)

const emitViewVehicle = (): void => {
  if (!selectedVehicle.value) return
  emit('view-vehicle', selectedVehicle.value)
}

/**
 * Returns the source URL of the image associated with the vehicle.
 *
 * @param {Vehicle} vehicle - The vehicle object.
 * @return {string} The source URL of the image.
 */
function imageSrc(vehicle: Vehicle): string {
  // get uploaded image to car in first instance.
  const images = vehicle.imageSrcPaths
  if (images.length) return images[0]
  // get model image in second instance.
  if (vehicle.evModel)
    return vehicle.evModel?.imageSrc ?? getAssetSrc('car_images/No_Image_Powersell.png')
  // get company logo image in third instance.
  const company = store.state.fleet
  if (company && company.logoSrc) {
    return company.logoSrc
  }
  // get default image in fourth instance.
  return getAssetSrc('car_images/No_Image_Powersell.png')
}

/**
 * Handles the selection of the "Other" option in the vehicle details dialog.
 *
 * @return {void} This function does not return a value.
 */
function handleSelectOther(): void {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_GALLERY)
}
</script>
