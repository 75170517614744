<template>
  <div style="width: 100vw; height: 100vh; position: relative; overflow: hidden">
    <div id="map" ref="mapContainer" style="width: 100vw; height: 100vh"></div>
    <AddChargingStopContent
      :activator="showAddChargerDialog"
      :centerLatLng="polylineClickLatLng"
      @close="showAddChargerDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet-hotline'
import { useStore } from 'vuex'
import { onMounted, ref, computed, watch } from 'vue'
import polyline from '@mapbox/polyline'
import {
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  type State,
  TertiaryDialogContent,
} from '@/store/store_types'
import type Trip from '@/classes/trip_classes/trip'
import { useDisplay } from 'vuetify'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@maplibre/maplibre-gl-leaflet'
import AddChargingStopContent from '../trips/planning/AddChargingStopContent.vue'
import type Charger from '@/classes/charger_classes/charger'

const store = useStore<State>()
const { width } = useDisplay()

// -------------------------- //
// ----------- Map ---------- //
// -------------------------- //

const geoIp = computed(() => store.state.userGeoIPData)
const mapContainer = ref(null)
let map: L.Map | null = null

/**
 * Initializes the map component.
 *
 * This function is called after the component has mounted.
 *
 * It creates a Leaflet map instance with the given options and adds a tile layer to it.
 * It also creates a marker cluster group and adds it to the map.
 *
 * The marker cluster group is customized to display the number of child markers in the cluster
 * and to change its color based on the number of markers in the cluster.
 *
 * @returns {void}
 */
const initializeMap = () => {
  if (!mapContainer.value) return
  map = L.map(mapContainer.value, {
    maxZoom: 19,
    minZoom: 3,
    zoomControl: false,
  }).setView(geoIp.value ? geoIp.value.asLatLng : [-41.2924, 174.7787], 7)

  map.on('moveend', () => {
    const center = map?.getCenter()
    if (!center) return
    store.commit(MutationTypes.setPannedCenter, {
      lat: center.lat,
      lng: center.lng,
    })
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  L.maplibreGL({
    style: 'https://magic.appstack.me/osm_power_trip.json',
    maxZoom: 19,
  }).addTo(map)

  markerClusterGroup = L.markerClusterGroup({
    iconCreateFunction: function (cluster) {
      const count = cluster.getChildCount()
      let color = 'blue'
      if (count < 5) {
        color = '#42a5f5' // Lightest blue
      } else if (count < 10) {
        color = '#2196f3' // Light blue
      } else if (count < 20) {
        color = '#1e88e5' // Medium blue
      } else if (count < 50) {
        color = '#1976d2' // Blue
      } else if (count < 100) {
        color = '#1565c0' // Dark blue
      } else {
        color = '#0d47a1' // Darkest blue
      }

      return L.divIcon({
        html: `<div style="background-color: ${color}; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; color: white;">${count}</div>`,
        className: 'marker-cluster',
        iconSize: L.point(30, 30),
      })
    },
  })
  if (markerClusterGroup) map.addLayer(markerClusterGroup)
  // update now initialized map. Called here rather than in onMounted to prevent possible race condition.
  updateMarkers()
  updatePolylines()
}

/**
 * Re-center the map to show all points in the given list.
 * @param pointsList List of points to show on the map.
 */
const reCenterMap = (pointsList: [number, number][]) => {
  // get map padding (40% in pixels)
  const options = {
    paddingTopLeft: L.point((width.value / 100) * 40, 0),
  }

  // center map
  if (pointsList.length) map?.fitBounds(pointsList, options)
}

// -------------------------- //
// --------- Markers -------- //
// -------------------------- //

let markerClusterGroup: L.MarkerClusterGroup | null = null
let markers: L.Marker[] = []
const chargers = computed<Charger[]>(() => store.getters[GettersTypes.filterChargingStations])

/**
 * Clears the existing markers and adds new ones based on the filtered chargers in the Vuex store.
 *
 * This function is called whenever the filtered chargers change in the Vuex store.
 *
 * @remarks
 * The markers are added to a Leaflet {@link https://leafletjs.com/reference-1.7.1.html#markercluster marker cluster group}.
 * This allows for efficient clustering of nearby markers.
 *
 * Each marker is given a custom icon with a different color based on the number of chargers at that location.
 * Clicking on a marker will log a message to the console indicating which charger was clicked.
 */
const updateMarkers = () => {
  // Clear existing markers

  markerClusterGroup?.clearLayers()
  markers = []

  // Add new markers
  chargers.value.forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (charger) => {
      const customIcon = L.icon({
        iconUrl: charger.pinIconSrc,
        iconSize: trip?.value?.chargingStopCDBIDs?.includes(charger.id) ? [65, 65] : [50, 50], // Size of the icon
        iconAnchor: [25, 50], // Point of the icon which will correspond to marker's location
        popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png', // Default Leaflet marker shadow
        shadowSize: [41, 41], // Size of the shadow
        shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
        className: 'custom-marker', // Add a custom class to the marker
      })

      const marker = L.marker([charger.coordinates.latitude, charger.coordinates.longitude], {
        icon: customIcon,
      })
      marker.on('click', () => {
        store.commit(MutationTypes.setSelectedCharger, charger.id)
        store.commit(MutationTypes.setMainDialogContent, MainDialogContent.CHARGING_STATION_DETAILS)
      })
      markers.push(marker)
      markerClusterGroup?.addLayer(marker)
    },
  )
}

const locationMarkers: L.Marker[] = []
const updateLocationMarkers = () => {
  // clear existing markers
  locationMarkers.forEach((marker) => map?.removeLayer(marker))

  // guard clause
  if (!trip.value) return

  const locationCustomIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // Default Leaflet marker icon
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png', // Default Leaflet marker shadow
    shadowSize: [41, 41], // Size of the shadow
    shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
  })

  // add new markers
  trip.value.locations.forEach((location) => {
    const marker = L.marker(location.coordinates.asLatLng, {
      icon: locationCustomIcon,
    })
    marker.on('click', () => {
      store.commit(MutationTypes.setSelectedLocation, location.local_id)
      store.commit(MutationTypes.setTertiaryDialogContent, TertiaryDialogContent.LOCATION_DETAILS)
    })
    locationMarkers.push(marker)
    map?.addLayer(marker)
  })
}

// -------------------------- //
// -------- Polylines ------- //
// -------------------------- //

const polylineClickLatLng = ref<{ latitude: number; longitude: number } | null>(null)
const showAddChargerDialog = ref(false)
const trip = computed<Trip | undefined>(() => {
  return store.getters[GettersTypes.selectedTripData]
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let polylines: any[] = []

const updatePolylines = () => {
  // clear existing polylines
  if (polylines) {
    polylines.forEach((polyline) => map?.removeLayer(polyline))
    polylines = []
  }

  // guard clause
  if (!trip.value || !trip.value.itinerary) return // guard against trip being undefined or corrupted trip with no itinerary.

  // options to be used on each instance of the polyline
  const hotlineOptions = {
    min: 0,
    max: 1,
    palette: {
      0.2: '#F44336',
      0.3: '#9C27B0',
      0.4: '#0D47A1',
      0.9: '#2196F3',
    },
    outlineColor: '#FFFFFF',
    weight: 5,
    outlineWidth: 1,
  }

  // create a polyline for each step
  trip.value.itinerary.steps.forEach((step) => {
    const latlngs = polyline.decode(step.polyline, 6)
    const threeDimensionalLatlngs = latlngs.map((latlng, latlngIndex) => {
      const positionValue = (1 / latlngs.length) * latlngIndex
      const zValue =
        (1 - positionValue) * step.chargeBeforeTravelling +
        positionValue * step.chargeAfterTravelling
      const expandedItem: [number, number, number] = [latlng[0], latlng[1], zValue]
      return expandedItem
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const polylineLayer = L.hotline(threeDimensionalLatlngs, hotlineOptions).on(
      'click',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        polylineClickLatLng.value = { latitude: e.latlng.lat, longitude: e.latlng.lng }
        showAddChargerDialog.value = true
      },
    )
    if (map) polylineLayer.addTo(map)
    polylines.push(polylineLayer)
  })
}

onMounted(async () => {
  const LeafletHotline = await import('leaflet-hotline')
  LeafletHotline.default(L)
  initializeMap()
})

watch(
  chargers,
  () => {
    updateMarkers()
  },
  { deep: true },
)

watch(
  trip,
  () => {
    updatePolylines()
    if (trip.value) {
      reCenterMap(trip.value.fullTripPoints)
    }
    updateLocationMarkers()
  },
  { deep: true },
)

watch(geoIp, () => {
  if (geoIp.value && map && !trip.value) {
    map.flyTo(geoIp.value.asLatLng, 7)
  }
})
</script>

<style>
.leaflet-control-attribution {
  @media screen and (min-width: 600px) {
    padding-right: 20px;
  }
}
</style>
