import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import {
  MAPS_LITTLE_MONKEY_API_KEY,
  MAPS_LITTLE_MONKEY_GEO_IP_ENDPOINT,
} from '@/api/clients/maps-little-monkey-client'
import type { MapsGeoIPRes } from '@/types/maps_little_monkey_types'

/**
 * Locates the coordinates for the users IP address.
 *
 * NOTE: this is not precise as it will result in the ISPs IP provisioning server
 * in most instances however is useful for assigning basic country defaults etc.
 *
 * @returns a `MapsGeoIPRes` object if successful, undefined if unsuccessful.
 */
export async function fetchGeoIPDetails(): Promise<MapsGeoIPRes | undefined> {
  try {
    const response = await fetch(
      `${MAPS_LITTLE_MONKEY_GEO_IP_ENDPOINT}/?api_key=${MAPS_LITTLE_MONKEY_API_KEY}`,
    )
    const convertedResponse: MapsGeoIPRes = await response.json()
    return convertedResponse
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
