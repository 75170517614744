<template>
  <v-btn
    :color="powerTripDarkBlue"
    block
    variant="text"
    :disabled="disabled"
    :loading="loading"
    class="text-none rounded-lg text-decoration-underline"
    @click="emitClick"
  >
    <slot></slot>
  </v-btn>
</template>
<script setup lang="ts">
import { powerTripDarkBlue } from '@/data/const'

interface Props {
  loading?: boolean
  disabled?: boolean
}

const { loading = false, disabled = false } = defineProps<Props>()

const emit = defineEmits(['click'])

function emitClick() {
  emit('click')
}
</script>
