<template>
  <v-card flat class="bg-background">
    <AvatarRow
      v-if="user"
      :avatarSrc="avatarSrc"
      :emitViewAccount="() => emit('view-account')"
      :emitViewAllVehicles="() => emit('view-vehicles')"
      :emitViewVehicle="() => emit('view-vehicle')"
      :emitViewDefaults="() => emit('view-defaults')"
      :handleLogout="handleLogout"
      :user="user"
      class="my-5"
    />
    <v-row no-gutters align="center" v-else class="my-5">
      <v-skeleton-loader type="avatar" class="mr-3" color="background" />
      <v-skeleton-loader type="text, text" width="30%" color="background" />
      <v-spacer />
    </v-row>
    <v-card class="mb-3 rounded-lg bg-background pa-5" elevation="1">
      <v-row no-gutters v-if="company" class="flex-column align-start align-md-center flex-md-row">
        <v-row v-if="smAndDown" no-gutters align="center" style="width: 100%">
          <img v-if="logoSrc" :src="logoSrc" width="auto" height="50px" />
          <v-spacer />
          <v-btn icon @click="() => emit('view-vehicles')">
            <v-icon color="grey-lighten-1">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-row>
        <img v-if="mdAndUp && logoSrc" :src="logoSrc" width="auto" height="50px" />
        <v-card-title v-if="company">
          {{ company.name ?? 'Unnamed fleet' }}
        </v-card-title>
        <v-spacer />
        <ElevatedBtn v-if="mdAndUp" @click="() => emit('view-vehicles')">
          View fleet details
        </ElevatedBtn>
      </v-row>
      <v-row v-else no-gutters class="flex-column align-start align-md-center flex-md-row">
        <v-skeleton-loader type="button" width="150px" color="background" />
        <v-skeleton-loader type="text, text" width="30%" color="background" />
        <v-spacer />
        <v-skeleton-loader v-if="mdAndUp" type="heading" width="20%" color="background" />
      </v-row>
    </v-card>
  </v-card>
</template>

<script setup lang="ts">
import Driver from '@/classes/user_classes/driver'
import Fleet from '@/classes/fleet'
import { ActionTypes, GettersTypes, type State } from '@/store/store_types'
import { useStore } from 'vuex'
import ElevatedBtn from '@/components/ui-elements/buttons/ElevatedBtn.vue'
import AvatarRow from './AvatarRow.vue'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'

const { smAndDown, mdAndUp } = useDisplay()

const store = useStore<State>()

const emit = defineEmits<{
  (e: 'logout'): void
  (e: 'view-account'): void
  (e: 'view-vehicle'): void
  (e: 'view-vehicles'): void
  (e: 'view-defaults'): void
}>()

const handleLogout = () => {
  emit('logout')
  store.dispatch(ActionTypes.logoutUser)
}

const avatarSrc = computed(() => {
  const driver: Driver | undefined = store.getters[GettersTypes.currentDriver]
  return driver?.profilePicSrc
})

const logoSrc = computed(() => {
  const fleet: Fleet | undefined = store.state.fleet
  return fleet?.logoSrc
})

const user = computed(() => store.state.user)
const company = computed(() => store.state.fleet)
</script>
