<template>
  <v-card flat class="px-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title>
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Vehicle Settings
        </v-card-title>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <!-- vehicle image gallery -->
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="4">
          <v-img
            width="100%"
            height="auto"
            :lazy-src="fallbackImgSrc"
            :src="imgSrcList.length ? imgSrcList[0] : fallbackImgSrc"
            class="rounded-lg"
            contain
            aspect-ratio="1"
            max-height="160"
          ></v-img>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col
              cols="4"
              v-for="(image, index) in imgSrcList"
              :key="'vehicle-settings-image-' + index"
            >
              <v-img
                width="100%"
                height="auto"
                :lazy-src="fallbackImgSrc"
                :src="image"
                class="rounded-lg"
                contain
                aspect-ratio="1"
                max-height="80"
              ></v-img>
            </v-col>
            <v-col cols="4">
              <v-card
                class="rounded-lg bg-secondary d-flex justify-center align-center"
                style="height: 100%"
                @click="showUploader"
              >
                <v-icon dark x-large>mdi-camera-plus</v-icon>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- image uploader modal -->
    <v-dialog v-model="showingUploader" max-width="300">
      <v-card class="rounded-lg">
        <v-card-title class="d-flex justify-space-between">
          <span> Upload an image </span>
          <v-btn icon @click="hideUploader">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="imageUploadStatus">
          <v-card-text
            v-if="imageUploadStatus === 'PROCESSING'"
            class="d-flex flex-column justify-center align-center"
          >
            <LoadingCard />
            <v-row no-gutters class="align-center">
              Processing
              <PulseLoader />
            </v-row>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'SUCCESS'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="success" x-large>mdi-check-circle</v-icon>
            <span>Successfully uploaded</span>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'FAILED'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="error" x-large>mdi-alert-circle</v-icon>
            <span>Failed to uploaded</span>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text class="pr-8 pb-0">
            <v-file-input
              label="select image to upload"
              accept="image/*"
              :error-messages="errorMsg"
              @click="clearError"
              v-model="imageFile"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-5">
            <ElevatedBtn @click="uploadImage"> Upload image </ElevatedBtn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <!-- settings editing form -->
    <v-form ref="edit-vehicle-form" :disabled="processing || loading">
      <v-card-text>
        <!-- basic settings -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field label="Name" v-model="name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field label="Plate Number" v-model="plate"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="VIN" v-model="VIN"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="fuelType === 'Electric'">
            <v-autocomplete
              v-model="evModel"
              :items="EVModels"
              item-text="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @change="handleModelSelect"
            ></v-autocomplete>
          </v-col>
        </v-row>
        Battery Size
        <v-row align="center" justify="center" class="mb-7">
          <v-btn large icon @click="decrementBatterySize">
            <v-icon large> mdi-minus-circle-outline </v-icon>
          </v-btn>
          <div
            class="bg-grey-lighten-2 rounded-lg d-flex align-center"
            :style="mdAndUp ? 'height: 56px; width: 80%' : 'height: 56px; width: 70%'"
            id="battery-custom-input"
          >
            <v-text-field
              v-model="batterySize"
              class="right-aligned-input none-underlined-input off-set-input-message pt-5"
              style="max-width: 145px"
              suffix="kWh"
              type="number"
              hide-spin-buttons
              :rules="[validateBattery]"
            ></v-text-field>
          </div>

          <v-btn large icon @click="incrementBatterySize">
            <v-icon large> mdi-plus-circle-outline </v-icon>
          </v-btn>
        </v-row>
        <v-row class="justify-space-between" no-gutters>
          <span>
            Range adjustment
            <StyledToolTip v-if="tooltipContent" :data="tooltipContent" />
          </span>
          <span> {{ SOH }}% or {{ getMaxRange() }} </span>
        </v-row>
        <v-slider
          v-if="fuelType === 'Electric'"
          track-color="grey-lighten-2"
          v-model="SOH"
          min="1"
          max="100"
          class="pr-2 pb-3"
          :disabled="processing"
          :messages="batteryAgeMessage"
          thumb-size="16"
          track-size="6"
        ></v-slider>
      </v-card-text>
      <!-- connectors and cables -->
      <v-card-title> Connector Settings </v-card-title>
      <v-card-subtitle>
        These settings override the default settings based on your selected EV model.
      </v-card-subtitle>
      <v-card-text>
        <v-card class="mb-8">
          <v-card-title> Tethered Charging Stations </v-card-title>
          <v-card-subtitle>
            Plan trips with compatible chargers by selecting desired connectors.
          </v-card-subtitle>
          <v-slide-group class="py-2" show-arrows>
            <v-slide-group-item
              v-for="(connector, index) in connectors"
              :key="'connector-styled-btn-' + index"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2"
                style="width: 95px; height: 140px"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded-lg pa-1 d-flex align-center justify-center mb-2"
                  :class="connectorIsSelected(connector) ? 'selected_svg_container_background' : ''"
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="connectorIsSelected(connector) ? 'svg_outline' : 'svg_grayed_out'"
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-card>
        <v-card>
          <v-card-title> Your Cables </v-card-title>
          <v-card-subtitle> Select the cables you are bringing with you </v-card-subtitle>
          <v-slide-group class="py-2" show-arrows>
            <v-slide-group-item
              v-for="(connector, index) in cables"
              :key="'connector-styled-btn-' + index"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2"
                style="width: 95px; height: 140px"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded-lg pa-1 d-flex align-center justify-center mb-2"
                  :class="connectorIsSelected(connector) ? 'selected_svg_container_background' : ''"
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="connectorIsSelected(connector) ? 'svg_outline' : 'svg_grayed_out'"
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-card>
      </v-card-text>
      <!-- technical advanced settings -->
      <v-card-title> Advanced Settings </v-card-title>
      <v-card-subtitle>
        Danger zone, these settings can greatly impact your trip planning experience. Your selected
        EV model has preset values for these settings. You only need change these if your vehicle
        differs from those.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0 text-h6"> Vehicle Physics </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="DragCoefficient"
              label="Aerodynamics (optional)"
              type="number"
              clearable
              :messages="dragCoefficientMessage"
              :rules="[validateDrag]"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="RollingResistanceCoefficient"
              label="Road dynamics (optional)"
              type="number"
              clearable
              :rules="[validateRoleResistance]"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="Mass"
              label="Vehicle weight (optional)"
              type="number"
              clearable
              suffix="kg"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-0 text-h6"> EV Efficiency </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="RegenerativeBreakingEfficiency"
              label="Regenerative breaking efficiency (optional)"
              type="number"
              clearable
              :rules="[validateRegenBreaking]"
              :messages="regenerativeBrakingMessage"
              suffix="%"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="PowerChainEfficiency"
              label="Power chain efficiency (optional)"
              type="number"
              clearable
              :rules="[validatePowerChainEfficiency]"
              :message="powerChainEfficiencyMessage"
              suffix="%"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-0 text-h6"> Power Rating </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="MaxElectricPowerAc"
              label="AC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxAC]"
              suffix="kw"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="MaxElectricPowerDc"
              label="DC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxDC]"
              suffix="kw"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- action buttons -->
        <ElevatedBlockBtn
          class="mb-3 mt-5"
          :disabled="processing ? processing : !dataToSave"
          :loading="processing"
          @click="handleSave"
        >
          Save Vehicle Settings
        </ElevatedBlockBtn>
        <TextBlockBtn click="back"> Cancel </TextBlockBtn>
      </v-card-text>
    </v-form>
    <v-alert type="success" v-if="saveStatus === 'success'" class="mx-2">
      Vehicle updated successfully and saved to the database
    </v-alert>
    <v-alert type="error" v-if="saveStatus === 'failed'" class="mx-2">
      Vehicle was updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
</template>

<script setup lang="ts">
import type EVModel from '@/classes/vehicle_classes/evModel'
import type Vehicle from '@/classes/vehicle_classes/vehicle'
import {
  connectorDetailsDataMap,
  getAllSocketedConnectors,
  getAllTetheredConnectors,
  type ConnectorDetailsData,
  type ConnectorDetailsIconData,
} from '@/data/connectorDetailsData'
import evNavDefaultData from '@/data/eVNavDefaultData'
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
} from '@/store/store_types'
import type { ConnectorFormat, ConnectorType } from '@/types/charger_Db_types'
import type { DirectusVehicleVariableData } from '@/types/directus-types'
import type { AdvancedConfig, UserSelectedPlug } from '@/types/directus-types/directus_vehicle'
import type { FuelType } from '@/types/sheared_local_types'
import getAssetSrc from '@/utils/getAssetSrc'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import to2DP from '@/utils/to2DP'
import { onMounted, watch, ref, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import PulseLoader from '../ui-elements/loaders/PulseLoader.vue'
import LoadingCard from '../ui-elements/loaders/LoadingCard.vue'
import StyledToolTip from '../ui-elements/displays/StyledToolTip.vue'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'
import TextBlockBtn from '../ui-elements/buttons/TextBlockBtn.vue'
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()

interface LocalConnectorData {
  iconURL?: string
  displayName: string
  standard: ConnectorType
  format: ConnectorFormat
}

// data
const showingUploader = ref(false)
const imageFile = ref<File | undefined>(undefined)
const errorMsg = ref<string | undefined>(undefined)
const loading = ref(true)
const processing = ref(false)
const saveStatus = ref<undefined | 'failed' | 'success'>(undefined)
const name = ref<string | null>(null)
const plate = ref<string | null>(null)
const VIN = ref<string | null>(null)
const fuelType = ref<FuelType | null>('Electric')
const evModel = ref<EVModel | null>(null)
const batterySize = ref<string | number | null>(null)
const SOH = ref(100)
const Mass = ref<string | number | null>(null)
const DragCoefficient = ref<string | number | null>(null)
const RollingResistanceCoefficient = ref<string | number | null>(null)
const RegenerativeBreakingEfficiency = ref<string | number | null>(null)
const PowerChainEfficiency = ref<string | number | null>(null)
const MaxElectricPowerAc = ref<string | number | null>(null)
const MaxElectricPowerDc = ref<string | number | null>(null)
const selectedConnectors = ref<LocalConnectorData[]>([])

const store = useStore()

const fallbackImgSrc = computed(() => {
  return getAssetSrc('car_images/No_Image_Powersell.png')
})

const imgSrcList = computed(() => {
  const tempArray: string[] = []
  if (vehicle.value) {
    tempArray.push(...vehicle.value.imageSrcPaths)
    if (vehicle.value.evModel?.imageSrc) tempArray.push(vehicle.value.evModel.imageSrc!)
  }
  return tempArray
})

const connectors = computed(() => {
  return orderConnectors(
    getAllTetheredConnectors().map((connector) =>
      convertConnectorDataToLocalConnectorData(connector),
    ),
  )
})

const cables = computed(() => {
  return orderConnectors(
    getAllSocketedConnectors().map((connector) =>
      convertConnectorDataToLocalConnectorData(connector),
    ),
  )
})

const dataToSave = computed(() => {
  if (!vehicle.value) return false

  if (name.value !== vehicle.value.name) return true
  if (plate.value !== vehicle.value.licensePlate) return true
  if (VIN.value !== vehicle.value.VIN) return true
  if (fuelType.value !== vehicle.value.fuelType) return true
  if (evModel.value !== vehicle.value.evModel) return true
  if (batterySize.value && parseIntOrFloat(batterySize.value) !== vehicle.value.batterySize)
    return true
  if (SOH.value !== (vehicle.value.stateOfHealth ?? 0) * 100) return true
  if (Mass.value && parseIntOrFloat(Mass.value) !== vehicle.value.mass) return true
  if (
    DragCoefficient.value &&
    parseIntOrFloat(DragCoefficient.value) !== vehicle.value.dragCoefficient
  )
    return true
  if (
    RollingResistanceCoefficient.value &&
    parseIntOrFloat(RollingResistanceCoefficient.value) !==
      vehicle.value.rollingResistanceCoefficient
  )
    return true
  if (
    RegenerativeBreakingEfficiency.value &&
    parseIntOrFloat(RegenerativeBreakingEfficiency.value) !==
      vehicle.value.regenerativeBreakingEfficiency * 100
  )
    return true
  if (
    PowerChainEfficiency.value &&
    parseIntOrFloat(PowerChainEfficiency.value) !== vehicle.value.powerChainEfficiency * 100
  )
    return true
  if (
    MaxElectricPowerAc.value &&
    parseIntOrFloat(MaxElectricPowerAc.value) !== vehicle.value.maxElectricPowerAC
  )
    return true
  if (
    MaxElectricPowerDc.value &&
    parseIntOrFloat(MaxElectricPowerDc.value) !== vehicle.value.maxElectricPowerDC
  )
    return true

  const initialConnectors = getInitialConnectors()
  if (selectedConnectors.value.length !== initialConnectors.length) return true
  if (!initialConnectors.every((connector) => connectorIsSelected(connector))) return true

  return false
})

const batteryAgeMessage = computed(() => {
  const degradedPercentage = 100 - SOH.value
  if (degradedPercentage >= 70) return 'this looks like a poorly functioning battery'
  const yearsOld = Math.floor(degradedPercentage / 3)
  if (yearsOld <= 0) return 'equivalent to a new battery'
  return 'equivalent to a ' + yearsOld + ' year old battery with normal usage'
})

const dragCoefficientMessage = computed(() => {
  if (DragCoefficient.value) {
    const value = parseIntOrFloat(DragCoefficient.value)
    if (value && value >= 10)
      return 'Are you sure? This is the equivalent of being as aerodynamic as a wall.'
  }
  return undefined
})

const regenerativeBrakingMessage = computed(() => {
  if (RegenerativeBreakingEfficiency.value) {
    const value = parseIntOrFloat(RegenerativeBreakingEfficiency.value)
    if (value && value >= 100) return 'Wow! Really?'
  }
  return undefined
})

const powerChainEfficiencyMessage = computed(() => {
  if (PowerChainEfficiency.value) {
    const value = parseIntOrFloat(PowerChainEfficiency.value)
    if (value && value >= 100) return 'Wow! Really?'
  }
  return undefined
})

const vehicle = computed<Vehicle | undefined>(
  () => store.getters[GettersTypes.viewedFleetVehicleData],
)

const imageUploadStatus = computed(() => store.state.updateImageStatus)

const EVModels = computed<EVModel[]>(() => store.state.evModels)

const tooltipContent = computed(() =>
  store.state.managedContent.find((content) => content.Name === ManagedContentNames.SoHToolTip),
)

function convertConnectorDataToLocalConnectorData(
  connectorData: ConnectorDetailsData,
): LocalConnectorData {
  return {
    displayName: connectorData.displayName,
    format: 'CABLE',
    standard: connectorData.standard,
    iconURL: getSrc(connectorData.iconURL, 'cable'),
  }
}

function orderConnectors(connectors: LocalConnectorData[]): LocalConnectorData[] {
  const returnArray: LocalConnectorData[] = []
  connectors.forEach((connector) => {
    if (connectorIsSelected(connector)) {
      returnArray.unshift(connector)
    } else {
      returnArray.push(connector)
    }
  })
  return returnArray
}

function convertSelectedConnectorsToUserSelectedPlugs(): UserSelectedPlug[] {
  const tempArray: UserSelectedPlug[] = []

  selectedConnectors.value.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    if (extraData) {
      tempArray.push({
        standard: connector.standard,
        format: connector.format,
        powerType: extraData.powerType,
      })
    }
  })
  return tempArray
}

function connectorIsSelected(connector: LocalConnectorData): boolean {
  return !!selectedConnectors.value.find(
    (selectedConnector) =>
      selectedConnector.standard === connector.standard &&
      selectedConnector.format === connector.format,
  )
}

function getInitialConnectors(): LocalConnectorData[] {
  const typedVehicle: Vehicle | undefined = vehicle.value // cast type as ts has trouble with typing vuex getters.
  if (typedVehicle) {
    if (typedVehicle.userSelectedPlugs && typedVehicle.userSelectedPlugs.length)
      return getUserSelectedConnectors(typedVehicle.userSelectedPlugs)
    if (typedVehicle.evModel) return getModelConnectors(typedVehicle.evModel)
  }
  return []
}

function getModelConnectors(model: EVModel): LocalConnectorData[] {
  const tempArray: LocalConnectorData[] = []
  model.compatibleConnectors.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    const alreadyAdded = !!tempArray.find((dataItem) => dataItem.standard === connector.standard)
    if (extraData && !alreadyAdded) {
      tempArray.push({
        format: 'CABLE',
        standard: connector.standard,
        displayName: extraData.displayName,
        iconURL: getSrc(extraData.iconURL, 'cable'),
      })
    }
  })
  return tempArray
}

function getUserSelectedConnectors(userSelectedPlugs: UserSelectedPlug[]): LocalConnectorData[] {
  const tempArray: LocalConnectorData[] = []
  userSelectedPlugs.forEach((connector) => {
    const extraData = connectorDetailsDataMap.get(connector.standard)
    if (extraData) {
      tempArray.push({
        format: connector.format,
        standard: connector.standard,
        displayName: extraData.displayName,
        iconURL: getSrc(extraData.iconURL, connector.format === 'CABLE' ? 'cable' : 'socket'),
      })
    }
  })
  return tempArray
}

/**
 * Returns the target connectors icon asset url if one exist
 *
 * @param iconUrlData the 'ConnectorDetailsData' `iconUrl` property.
 * @param format the target format
 * @returns complete file path if it exist or undefined if not.
 */
function getSrc(
  iconUrlData: string | ConnectorDetailsIconData | undefined,
  format: 'cable' | 'socket',
): string | undefined {
  let partialFilePath: string | undefined = undefined
  if (!iconUrlData) return undefined
  if (typeof iconUrlData === 'string') partialFilePath = iconUrlData
  if (typeof iconUrlData === 'object') {
    if (Object.hasOwn(iconUrlData, format)) partialFilePath = iconUrlData[format]
  }
  if (!partialFilePath) return undefined
  return getAssetSrc(partialFilePath)
}

// Event handlers

/** Adds/removes connector from selected connectors list.
 *
 * @param connector the target `LocalConnectorData` object for the connector.
 */
function toggleConnectorSelect(connector: LocalConnectorData): void {
  if (connectorIsSelected(connector)) {
    // filter out matching connector from array
    selectedConnectors.value = selectedConnectors.value.filter((selectedConnector) => {
      if (selectedConnector.standard !== connector.standard) return true
      if (
        selectedConnector.standard === connector.standard &&
        selectedConnector.format !== connector.format
      )
        return true
      return false // assumes this will be the selected connector that matches both standard and format.
    })
  } else {
    // add connector to array
    selectedConnectors.value.push(connector)
  }
}

/** Increases the battery size by 1 kWh. */
function incrementBatterySize(): void {
  // exit early if batterySize is null
  if (!batterySize.value) return
  // parse value
  const parsedVal = parseIntOrFloat(batterySize.value)
  // exit early if parsing value failed
  if (!parsedVal) return
  // increment battery size
  batterySize.value = parsedVal + 1
}

/** Decreases the battery size by 1 kWh. */
function decrementBatterySize(): void {
  // exit early if batterySize is null
  if (!batterySize.value) return
  // parse value
  const parsedVal = parseIntOrFloat(batterySize.value)
  // exit early if parsing value failed
  if (!parsedVal) return
  // decrement battery size
  batterySize.value = parsedVal - 1
}

/** Returns a display string containing the expected max range in km for this vehicle. */
function getMaxRange(): string {
  const range = vehicle.value?.calcMaxRange(store.state.extraWeight, SOH.value / 100)
  return range ? `${Math.floor(range / 1000)}km max range` : '0km max range'
}

/** closes all modals and returns to the normal vue of the app. */
function close() {
  store.commit(MutationTypes.setMainDialogContent, undefined)
  store.commit(MutationTypes.setViewedFleetVehicle, undefined)
}

/** swaps modal content back to the target `Vehicles` vehicle details page */
function back() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.FLEET_VEHICLE_DETAILS)
}

/** displays the image uploader modal */
function showUploader() {
  showingUploader.value = true
}

/** closes the image uploader modal */
function hideUploader() {
  showingUploader.value = false
}

/** upload selected image */
function uploadImage() {
  if (!imageFile.value) {
    errorMsg.value = 'please select an image'
  } else {
    store.dispatch(ActionTypes.uploadVehicleImage, {
      vehicle: vehicle,
      imageFile: imageFile,
    })
  }
}

/** clears the image uploader's error message. */
function clearError() {
  errorMsg.value = undefined
}

/**
 * Adjusts other values to match the pre set values of the new selected model.
 *
 * @param val the selected `EVModel` - NOTE this is auto applied if triggered off the change event for the autocomplete
 */
function handleModelSelect(val: EVModel | null): void {
  if (val) {
    batterySize.value = val.batterySize
    Mass.value = val.mass
    DragCoefficient.value = val.dragCoefficient
    RollingResistanceCoefficient.value = evNavDefaultData.RollingResistanceCoefficient
    RegenerativeBreakingEfficiency.value = val.regenRecovery * 100 // convert from decimal percentage representation to whole number percentage representation.
    PowerChainEfficiency.value = val.powerChainEfficiency * 100 // convert from decimal percentage representation to whole number percentage representation.
    MaxElectricPowerAc.value = val.maxElectricPowerAC
    MaxElectricPowerDc.value = val.maxElectricPowerDC
    SOH.value = val.calcLinearDegradationSOH() * 100 // convert from decimal percentage representation to whole number percentage representation.
    selectedConnectors.value = getModelConnectors(val)
  }
}

async function handleSave(): Promise<void> {
  processing.value = true // indicate async process has begun.
  if (!vehicle.value) return // guard clause.
  if (!selectedConnectors.value.length) return // guard clause.
  // compile data to update
  const dataToUpdate: DirectusVehicleVariableData = {}
  if (name.value !== (vehicle.value.name ?? null)) {
    vehicle.value.name = name.value ?? undefined
    dataToUpdate.name = name.value
  }
  if (plate.value !== (vehicle.value.licensePlate ?? null)) {
    vehicle.value.licensePlate = plate.value ?? undefined
    dataToUpdate.rego = plate.value
  }
  if (VIN.value !== (vehicle.value.VIN ?? null)) {
    vehicle.value.VIN = VIN.value ?? undefined
    dataToUpdate.vin = VIN.value
  }
  if (fuelType.value !== (vehicle.value.fuelType ?? null)) {
    vehicle.value.fuelType = fuelType.value ?? undefined
    dataToUpdate.fuel_type = fuelType.value
  }
  if (evModel.value !== (vehicle.value.evModel ?? null)) {
    vehicle.value.setEVModel(evModel.value ?? undefined)
    dataToUpdate.CDB_Model_ID = evModel.value?.id
  }
  if (batterySize.value && parseIntOrFloat(batterySize.value) !== vehicle.value.batterySize) {
    const newAdvConf = vehicle.value.advancedConfig
      ? {
          ...vehicle.value.advancedConfig,
          BatterySize: parseIntOrFloat(batterySize.value),
        }
      : { BatterySize: parseIntOrFloat(batterySize.value) }
    vehicle.value.advancedConfig = newAdvConf
    dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf)
  }
  if (
    SOH.value !== (vehicle.value.stateOfHealth ? vehicle.value.stateOfHealth! * 100 : undefined)
  ) {
    const convertedSOH = to2DP(SOH.value / 100)
    vehicle.value.userProvidedStateOfHealth = convertedSOH
    dataToUpdate.soh = convertedSOH
  }

  // compile advanced config
  const newAdvConf: AdvancedConfig = vehicle.value.advancedConfig
    ? { ...vehicle.value.advancedConfig }
    : {}
  if (Mass.value && parseIntOrFloat(Mass.value) !== vehicle.value.mass) {
    newAdvConf.Mass = parseIntOrFloat(Mass.value)
  }
  if (
    DragCoefficient.value &&
    parseIntOrFloat(DragCoefficient.value) !== vehicle.value.dragCoefficient
  ) {
    newAdvConf.DragCoefficient = parseIntOrFloat(DragCoefficient.value)
  }
  if (
    RollingResistanceCoefficient.value &&
    parseIntOrFloat(RollingResistanceCoefficient.value) !==
      vehicle.value.rollingResistanceCoefficient
  ) {
    newAdvConf.RollingResistanceCoefficient = parseIntOrFloat(RollingResistanceCoefficient.value)
  }
  if (RegenerativeBreakingEfficiency.value) {
    const parsedVal = parseIntOrFloat(RegenerativeBreakingEfficiency.value)
    if (parsedVal && parsedVal !== vehicle.value.rollingResistanceCoefficient * 100) {
      newAdvConf.RegenerativeBreakingEfficiency = to2DP(parsedVal / 100)
    }
  }
  if (PowerChainEfficiency.value) {
    const parsedVal = parseIntOrFloat(PowerChainEfficiency.value)
    if (parsedVal && parsedVal !== vehicle.value.powerChainEfficiency * 100) {
      newAdvConf.PowerChainEfficiency = to2DP(parsedVal / 100)
    }
  }
  if (
    MaxElectricPowerAc.value &&
    parseIntOrFloat(MaxElectricPowerAc.value) !== vehicle.value.maxElectricPowerAC
  ) {
    newAdvConf.MaxElectricPowerAc = parseIntOrFloat(MaxElectricPowerAc.value)
  }
  if (
    MaxElectricPowerDc.value &&
    parseIntOrFloat(MaxElectricPowerDc.value) !== vehicle.value.maxElectricPowerDC
  ) {
    newAdvConf.MaxElectricPowerDc = parseIntOrFloat(MaxElectricPowerDc.value)
  }
  // check advanced config has any entries
  if (Object.keys(newAdvConf).length) {
    vehicle.value.advancedConfig = newAdvConf
    dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf)
  }

  // compile connectors and cables

  // check if just model defaults
  if (evModel.value) {
    // find models default connectors
    const modelDefaultConnectors = getModelConnectors(evModel.value)
    if (
      selectedConnectors.value.length !== modelDefaultConnectors.length ||
      !modelDefaultConnectors.every((connector) => connectorIsSelected(connector))
    ) {
      // ASSUME: not the same and needs override
      const userSelectedPlugs = convertSelectedConnectorsToUserSelectedPlugs()
      vehicle.value.userSelectedPlugs = userSelectedPlugs
      dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) => JSON.stringify(obj))
    } else {
      // ASSUMES: is default for model and userSelectedPlugs can be removed.
      vehicle.value.userSelectedPlugs = undefined
      dataToUpdate.UserSelectedPlugs = null
    }
  } else {
    // ASSUME: just straight override as no model for comparison.
    const userSelectedPlugs = convertSelectedConnectorsToUserSelectedPlugs()
    vehicle.value.userSelectedPlugs = userSelectedPlugs
    dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) => JSON.stringify(obj))
  }

  // update DB
  saveStatus.value = await vehicle.value.updateDirectusData(dataToUpdate)
  // update global state
  store.commit(MutationTypes.updateIndividualVehicle, vehicle)
  // indicate async process has completed
  processing.value = false
}

// form validation
function validateDrag(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0.01) || (value && value > 10))
    return 'Please set a value between 0.01 and 10 if setting aerodynamics'
  return true
}
function validateRoleResistance(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0.001) || (value && value > 1))
    return 'Please set a value between 0.001 and 1 if setting road dynamics'
  return true
}
function validateRegenBreaking(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 1) || (value && value > 100))
    return 'Please set a value between 1% and 100% if setting regenerative breaking efficiency'
  return true
}
function validatePowerChainEfficiency(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 1) || (value && value > 100))
    return 'Please set a value between 1% and 100% if setting power chain efficiency'
  return true
}
function validateMaxAC(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0) || (value && value > 50))
    return 'Please set a value between 0kw and 50kw if setting AC charging speed'
  return true
}
function validateMaxDC(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0) || (value && value > 1000))
    return 'Please set a value between 0kw and 1000kw if setting DC charging speed'
  return true
}
function validateFuelType(v: string | number | null): boolean | string {
  if (v) return true
  return 'Fuel type is required'
}
function validateBattery(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (v) {
    const parsedVal = parseIntOrFloat(v)
    if (parsedVal === 0) return 'Must be a positive number' // ensure inputs of "0" are not treated the same as ""
    if (parsedVal && parsedVal < 0) return 'Must be a positive number' // positive numbers only.
    if (!parsedVal) return 'Needs to be a number' // string passed is not parsable as a number, vuetify behavior makes this unlikely.
    if (parsedVal) return true
  }
  if (v === '') return 'Needs to be a number' // this version of vuetify treats NaN inputs as empty strings.
  return 'An EV needs to have a battery' // this code is only reachable if this is a null.
}

function delayCloseModal() {
  setTimeout(() => {
    showingUploader.value = false
    imageFile.value = undefined
    saveStatus.value = undefined
  }, 1000)
}

watch(imageUploadStatus, (val: 'PROCESSING' | 'SUCCESS' | 'FAILED' | undefined) => {
  if (val === 'SUCCESS' || val === 'FAILED') {
    delayCloseModal()
  }
})

onMounted(() => {
  nextTick(() => {
    if (vehicle.value) {
      // set initial values
      name.value = vehicle.value.name ?? null
      plate.value = vehicle.value.licensePlate ?? null
      VIN.value = vehicle.value.VIN ?? null
      fuelType.value = vehicle.value.fuelType ?? 'Electric'
      evModel.value = vehicle.value.evModel ?? null
      batterySize.value = vehicle.value.batterySize
      SOH.value = vehicle.value.stateOfHealth ? vehicle.value.stateOfHealth * 100 : 100
      Mass.value = vehicle.value.mass ?? null
      DragCoefficient.value = vehicle.value.dragCoefficient ?? null
      RollingResistanceCoefficient.value = vehicle.value.rollingResistanceCoefficient ?? null
      RegenerativeBreakingEfficiency.value = vehicle.value.regenerativeBreakingEfficiency
        ? vehicle.value.regenerativeBreakingEfficiency * 100
        : null
      MaxElectricPowerAc.value = vehicle.value.maxElectricPowerAC ?? null
      MaxElectricPowerDc.value = vehicle.value.maxElectricPowerDC ?? null
      PowerChainEfficiency.value = vehicle.value.powerChainEfficiency
        ? vehicle.value.powerChainEfficiency * 100
        : null
      selectedConnectors.value = getInitialConnectors()
    }
    loading.value = false
  })
})
</script>

<style scoped>
#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}
.right-aligned-input :deep(input) {
  text-align: right;
}

.none-underlined-input :deep(.v-input__slot::before) {
  border-style: none !important;
}

.off-set-input-message :deep(.v-messages__message) {
  padding-top: 16px;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}

.svg_base {
  width: 56px;
  height: 56px;
}
.svg_outline {
  filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white) drop-shadow(-1px 1px 0 white)
    drop-shadow(1px -1px 0 white);
}

.svg_grayed_out {
  filter: grayscale(1);
}

.selected_svg_container_background {
  background-color: #adedd2;
}
</style>
