import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { EV_NAV_RADAR_ENDPOINT } from '@/api/clients/ev-nav-client'
import type { EVNavRadarParams, EVNavRadarResponse } from '@/types/ev_nav_types'

/**
 * Fetch a list of chargers with in range of a full trip
 *
 * @param radarParams parameters for range service - see `routPlaningStore` for object shape.
 * @returns an array of charger objects with in range
 */
export async function evnavRadarCall(
  radarParams: EVNavRadarParams,
): Promise<EVNavRadarResponse | undefined> {
  try {
    const response = await fetch(EV_NAV_RADAR_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(radarParams),
      headers: { 'Content-Type': 'application/json' },
    })
    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)
    const convertedResponse: { result: EVNavRadarResponse } = await response.json()
    return convertedResponse.result
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
