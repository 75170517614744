<template>
  <v-card flat class="px-sm-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title> Vehicle Booking </v-card-title>
      </span>
    </v-row>
    <!-- vehicle details card -->
    <v-card class="py-4 pl-md-12 rounded-lg bg-background mb-10" flat>
      <v-row class="flex-column flex-md-row">
        <v-col md="3">
          <!-- image section -->
          <v-skeleton-loader type="image" width="100%" height="140px" v-if="!vehicle" />
          <v-img
            v-else
            contain
            :src="imageSrc()"
            aspect-ratio="1"
            :width="mdAndUp ? '100%' : '40%'"
            :height="mdAndUp ? '100%' : 'auto'"
            style="margin: auto"
          ></v-img>
        </v-col>
        <v-col md="9">
          <!-- details section -->
          <div v-if="vehicle">
            <v-card-title>
              {{ isSelected ? 'Current vehicle' : 'Unselected vehicle' }}
            </v-card-title>
            <v-card-subtitle class="font-weight-medium pr-12">
              {{ vehicle.name ?? 'unnamed vehicle' }}
            </v-card-subtitle>
            <v-card-text class="pr-12">
              <p class="mb-0">
                {{ vehicle.licensePlate ?? 'unrecorded license plate' }}
              </p>
              <p v-if="vehicle.evModel">
                {{ vehicle.evModel.name }}
              </p>
              <NotConnectedBatteryDisplay v-if="!vehicle.stateOfCharge" />
              <BatteryDisplay
                v-else
                :battery="vehicle.stateOfCharge"
                :style="mdAndUp ? 'max-width: 50%' : ''"
              />
            </v-card-text>
          </div>
          <div v-else>
            <v-row no-gutters class="mb-4">
              <v-skeleton-loader type="heading" width="70%" />
              <v-skeleton-loader type="heading" width="30%" />
            </v-row>
            <v-skeleton-loader type="text" width="40%" />
            <v-skeleton-loader type="text" width="70%" class="mb-4" />
            <v-skeleton-loader type="heading" width="100%" />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <BookingCalendar
      v-if="vehicle?.directusId"
      :assetId="vehicle.directusId"
      :assetType="'vehicle'"
    />
  </v-card>
</template>
<script setup lang="ts">
import getAssetSrc from '@/utils/getAssetSrc'
import BookingCalendar from '../booking/BookingCalendar.vue'
import type Vehicle from '@/classes/vehicle_classes/vehicle'
import { useStore } from 'vuex'
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
import type { State } from '@/store/store_types'

const { vehicle } = defineProps<{ vehicle: Vehicle }>()

const store = useStore<State>()
const { mdAndUp } = useDisplay()
const isSelected = computed(() => vehicle.localId === store.state.selectedVehicle)

/**
 * Returns the source URL of the image associated with the vehicle.
 *
 * @return {string} The source URL of the image.
 */
function imageSrc(): string {
  if (vehicle) {
    // get uploaded image to car in first instance.
    const images = vehicle.imageSrcPaths
    if (images.length) return images[0]
    // get model image in second instance.
    if (vehicle.evModel)
      return vehicle.evModel?.imageSrc ?? getAssetSrc('car_images/No_Image_Powersell.png')
    // get company logo image in third instance.
    const company = store.state.fleet
    if (company && company.logoSrc) {
      return company.logoSrc
    }
  }
  // get default image in fourth instance.
  return getAssetSrc('car_images/No_Image_Powersell.png')
}
</script>
