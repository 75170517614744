<template>
  <v-btn icon class="pa-1" size="small" flat color="transparent">
    <v-icon color="primary" @click="open"> mdi-help-circle </v-icon>
    <template>
      <v-dialog
        v-model="showDialog"
        :content-class="xs ? '' : 'rounded-lg'"
        style="z-index: 1500"
        @click:outside="close()"
        @keydown="keyDown($event)"
        :width="mdAndUp ? '50%' : '70%'"
        max-width="600px"
        min-width="300px"
        :fullscreen="xs"
        scrollable
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="d-flex align-center justify-space-between pa-5"
            style="word-break: normal"
          >
            <span>
              <v-icon color="primary" class="pb-2 mr-1"> mdi-help-circle </v-icon>
              <span class="text-primary text-h5"> {{ data.Name }} </span>
            </span>
            <v-btn icon @click="close()" flat color="transparent">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-html="data.Content"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-btn>
</template>
<script setup lang="ts">
import type { DirectusManagedContent } from '@/types/directus-types'
import { ref } from 'vue'
import { useDisplay } from 'vuetify'

const { mdAndUp, xs } = useDisplay()
interface Props {
  data: DirectusManagedContent
}

const { data } = defineProps<Props>()

const showDialog = ref(false)

function open(): void {
  showDialog.value = true
}

function close(): void {
  showDialog.value = false
}

function keyDown(event: KeyboardEvent) {
  if (event.key === 'Escape') close()
}
</script>
