<template>
  <v-dialog
    v-model="showDialog"
    :content-class="xs ? '' : 'rounded-lg'"
    style="z-index: 1300"
    @click:outside="close"
    @after-leave="cleanup"
    @keydown="keyDown($event)"
    :width="mdAndUp ? '50%' : '70%'"
    max-width="600px"
    min-width="300px"
    :fullscreen="xs"
    scrollable
    :max-height="mdAndUp ? '90vh' : undefined"
  >
    <v-card
      class="overflow-hidden py-5 rounded-lg bg-background"
      :style="xs ? 'height: 100vh;' : 'height: 75vh;'"
    >
      <v-card-title class="d-flex justify-space-between pl-10" style="word-break: normal">
        <v-row no-gutters align="center">
          <v-col cols="2" md="1" v-if="icon">
            <v-icon color="primary" class="pb-1">
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col :cols="icon ? 8 : 10" :md="icon ? 10 : 11">
            <span class="text-primary"> {{ title }} </span>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="close" flat color="transparent">
              <v-icon color="grey-lighten-1"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden pb-0"
        style="height: calc(100% - 96px)"
      >
        <!-- conditionally rendered content. -->
        <DeleteTripConfirmationCard v-if="content === 'storedTripDeleteConfirmation'" />
        <NetworkSettings v-if="content === 'networkSettings'" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { MutationTypes, SecondaryDialogContent } from '@/store/store_types'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { useDisplay } from 'vuetify'
import NetworkSettings from '../settings/NetworkSettings.vue'
import DeleteTripConfirmationCard from '../trips/DeleteTripConfirmationCard.vue'

const store = useStore()
const { mdAndUp, xs } = useDisplay()
const showDialog = ref(false)

const content = computed(() => store.state.secondaryDialogContent)
const title = computed(() => {
  switch (store.state.secondaryDialogContent) {
    case SecondaryDialogContent.NETWORK_SETTINGS:
      return 'Filter chargers by network'
    case SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION:
      return 'Confirm deletion'
    default:
      return undefined
  }
})
const icon = computed(() => {
  switch (store.state.secondaryDialogContent) {
    case SecondaryDialogContent.NETWORK_SETTINGS:
      return ''
    case SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION:
      return 'mdi-trash-can'
    default:
      return undefined
  }
})

function cleanup() {
  store.commit(MutationTypes.setSecondaryDialogContent, undefined)
}

function close() {
  showDialog.value = false
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function keyDown(event: any): void {
  if (event.key === 'Escape') close()
}

watch(content, () => {
  if (content.value) {
    showDialog.value = true
    notifyAnalytics({
      type: 'track_pageview',
      data: content.value,
    })
  } else {
    close()
  }
})
</script>
<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
