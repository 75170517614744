import type { DirectusCompany, DirectusScoringConfig } from '@/types/directus-types'
import generateUniqueLocalID from '../utils/generateUniqueLocalID'
import getDirectusImgUrl from '../utils/getDirectusImgUrl'

export default class Fleet {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = []

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string

  /** Flag that represents if a driver is allowed to manually match or change vehicle data. */
  driverEditAccess: boolean

  /** Display name for this fleet. */
  name?: string

  /** Fleets's logo asset file UUID. */
  logo?: string

  config?: DirectusScoringConfig

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    driverEditAccess = false,
    name = undefined,
    logo = undefined,
    config = undefined,
  }: {
    localId?: string
    driverEditAccess?: boolean
    name?: string
    logo?: string
    config?: DirectusScoringConfig
  }) {
    this.localId = localId ?? generateUniqueLocalID(Fleet.usedIds, 'fleet')
    this.driverEditAccess = driverEditAccess
    this.name = name
    this.logo = logo
    this.config = config

    // add id to list of used unique ids
    Fleet.usedIds.push(this.localId)
  }

  static fromDirectusData(data: DirectusCompany) {
    return new Fleet({
      driverEditAccess: data.ManualVehicleMatching ?? undefined,
      name: data.Name ?? undefined,
      logo: data.Logo ?? undefined,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the src path for this fleets logo pic if they have one. */
  public get logoSrc(): string | undefined {
    if (this.logo) return getDirectusImgUrl(this.logo)
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
