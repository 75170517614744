import { app } from '@/main'
import type { analyticsRecord } from './countlyTypes'

/**
 * Send an analytics record to Countly.
 *
 * @param {analyticsRecord} recordData - The data to be sent to Countly.
 *
 * @example
 * notifyAnalytics({
 *   type: 'add_event',
 *   data: {
 *     key: 'myEvent',
 *     segmentation: {
 *       'foo': 'bar',
 *     },
 *   },
 * })
 */
export function notifyAnalytics(recordData: analyticsRecord) {
  const $Countly = app?.config.globalProperties.$Countly

  if ($Countly) {
    const preparedData =
      recordData.type === 'add_event' ? JSON.stringify(recordData.data) : recordData.data

    $Countly.q.push([recordData.type], preparedData)
  }
}
