/** Base Url for the Maps Little Monkey's API. */
const baseUrl = 'https://maps.littlemonkey.co.nz'

// Keys

/** Maps Little Monkey API key. */
export const MAPS_LITTLE_MONKEY_API_KEY = import.meta.env.VITE_LITTLE_MONKEY_API_KEY

// Endpoints

/** Maps Little Monkey endpoint to get an autocompleted address and address data. */
export const MAPS_LITTLE_MONKEY_AUTOCOMPLETE_ENDPOINT = baseUrl + '/complete'

/** Maps Little Monkey endpoint to get the geo IP data (approx location for user based on IP). */
export const MAPS_LITTLE_MONKEY_GEO_IP_ENDPOINT = baseUrl + '/geoip'

/** Maps Little Monkey endpoint to reverse geo code/get address data based on latitude and longitude. */
export const MAPS_LITTLE_MONKEY_REVERSE_GEO_CODE_ENDPOINT = baseUrl + '/reverse'
