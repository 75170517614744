<template>
  <v-card @click="() => emit('click')" class="rounded-lg bg-background">
    <v-card-title class="d-flex justify-space-between text-subtitle-1 font-weight-medium">
      {{ vehicle.name ?? 'unnamed vehicle' }}
      <v-icon color="grey" size="small">mdi-dots-vertical</v-icon>
    </v-card-title>
    <v-card-subtitle class="font-weight-medium pr-12">
      {{ vehicle.licensePlate ?? 'unrecorded license plate' }}
    </v-card-subtitle>
    <v-card-text v-if="vehicle.fuelType === 'Electric' || vehicle.fuelType === 'Plug in hybrid'">
      <NotConnectedBatteryDisplay v-if="!vehicle.stateOfCharge" />
      <BatteryDisplay v-else :battery="vehicle.stateOfCharge" />
      {{ vehicle.fuelType ? vehicle.fuelType : 'Electric' }}
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import Vehicle from '@/classes/vehicle_classes/vehicle'
import BatteryDisplay from '../ui-elements/displays/BatteryDisplay.vue'
import NotConnectedBatteryDisplay from '../ui-elements/displays/NotConnectedBatteryDisplay.vue'

const { vehicle } = defineProps<{ vehicle: Vehicle }>()
const emit = defineEmits(['click'])
</script>
