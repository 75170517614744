import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { EV_NAV_MULTI_ROUTES_ENDPOINT, EV_NAV_ROUTE_ENDPOINT } from '@/api/clients/ev-nav-client'
import type { EVNavFailedRoutePlan, EVNavRouteParams, EVNavRoutePlan } from '@/types/ev_nav_types'

/**
 * Fetch multiple planned routes in one call form the ev nav service
 *
 * @param routeParams an array of `RouteParams` objects - see types for object shape.
 * @returns multiple fully planed routes, if a route is possible else one of the servers other status messages - see `routePlanningStore` for potential responses.
 */
export async function fetchMultiRoutePlans(
  routeParams: EVNavRouteParams[],
): Promise<(EVNavRoutePlan | EVNavFailedRoutePlan)[] | undefined> {
  try {
    const response = await fetch(EV_NAV_MULTI_ROUTES_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(routeParams),
      headers: { 'Content-Type': 'application/json' },
    })
    const convertedResponse = await response.json()
    return convertedResponse.result as (EVNavRoutePlan | EVNavFailedRoutePlan)[]
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}

/**
 * Fetch a planed route form the ev nav service
 *
 * @param routeParams a populated `EVNavRouteParams` object.
 * @returns a fully planed route, if a route is possible else one of the servers other status messages - see `routePlanningStore` for potential responses.
 */
export async function fetchRoutePlan(
  routeParams: EVNavRouteParams,
): Promise<EVNavRoutePlan | EVNavFailedRoutePlan | undefined> {
  try {
    const response = await fetch(EV_NAV_ROUTE_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(routeParams),
      headers: { 'Content-Type': 'application/json' },
    })
    const convertedResponse = await response.json()
    return convertedResponse.result
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
