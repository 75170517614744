<template>
  <v-container>
    <v-card v-for="evse in evses" :key="evse.uid" flat>
      <template v-for="connector in evse.connectors" :key="connector.id">
        <v-card
          @click.stop="selectConnector(connector)"
          :class="
            connector.id === selectedConnectorId
              ? 'py-2 mb-5 rounded-lg'
              : vehicle && connector.isCompatible(vehicle) !== 'incompatible'
                ? 'mb-5 rounded-lg'
                : 'bg-grey-lighten-3 mb-5 rounded-lg'
          "
          :style="
            connector.id === selectedConnectorId
              ? 'border: 2px solid #2196f3 !important'
              : vehicle && connector.isCompatible(vehicle) !== 'incompatible'
                ? ''
                : 'filter: grayscale(1); cursor: unset !important;'
          "
        >
          <v-row no-gutters align="center">
            <v-card flat class="bg-transparent ml-3" height="60" width="60">
              <v-img contained :src="connector.getPlugIconSrc" height="60" width="60"></v-img>
            </v-card>
            <v-card flat class="bg-transparent">
              <v-card-title>{{ connector.displayName }}</v-card-title>
              <v-card-subtitle>
                {{ connector.ratingDisplayStr }}
              </v-card-subtitle>
            </v-card>
            <v-card
              flat
              class="bg-transparent"
              v-if="vehicle && connector.isCompatible(vehicle) === 'compatible with cable only'"
            >
              <v-card-title class="text-error">BYO Cable</v-card-title>
            </v-card>
          </v-row>
        </v-card>
      </template>
    </v-card>
  </v-container>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, nextTick } from 'vue'
import Connector from '@/classes/charger_classes/connector'
import EVSE from '@/classes/charger_classes/evse'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { GettersTypes } from '@/store/store_types'
import { useStore } from 'vuex'

interface Props {
  evses: EVSE[]
}

const props = defineProps<Props>()

const store = useStore()

const vehicle = computed(() => store.getters[GettersTypes.selectedVehicleData])

const emit = defineEmits<{ (event: 'selectConnector', connectorId: string): void }>()

const selectedConnectorId = ref<string | undefined>()

const selectConnector = (connector: Connector | undefined): void => {
  if (connector && vehicle.value && connector.isCompatible(vehicle.value) !== 'incompatible') {
    selectedConnectorId.value = connector.id
    emit('selectConnector', connector.id)
  }
}

onMounted(async () => {
  await nextTick()
  if (props.evses) {
    const vehicleValue: Vehicle | undefined = vehicle.value
    if (vehicleValue) {
      const connectors = props.evses
        .flatMap((evse) => evse.connectors)
        .filter((connector) => connector.isCompatible(vehicleValue) !== 'incompatible')
      selectConnector(connectors[0])
    } else {
      selectConnector(props.evses[0].connectors[0])
    }
  }
})
</script>
