import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { VALHALLA_ROUTE_ENDPOINT } from '@/api/clients/valhalla-client'
import {
  QuickTripCheckReturn,
  type Valhalla_CostingModel,
  type Valhalla_Location,
  type Valhalla_RouteError,
  type Valhalla_RouteRes,
} from '@/types/valhalla_types'

/**
 * Fetches a route plan from the Valhalla routing service.
 *
 * Sends a request to the Valhalla routing API with the provided locations and costing model.
 * Returns a Valhalla route response if successful or an error response if there is a problem
 * with the request. If an error occurs during the fetch operation, it logs the error and
 * returns undefined.
 *
 * @param locations - An array of Valhalla_Location objects representing the waypoints of the route.
 * @param costing - The costing model to use for the route calculation. Defaults to "auto".
 * @returns A promise that resolves to either a Valhalla_RouteRes object if the route is successfully
 * fetched, a Valhalla_RouteError object if there is an error in the response, or undefined if an
 * exception occurs during the fetch operation.
 */
export async function fetchValhallaRoutePlan(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = 'auto',
): Promise<Valhalla_RouteRes | Valhalla_RouteError | undefined> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    }

    const res = await fetch(`${VALHALLA_ROUTE_ENDPOINT}?json=${JSON.stringify(json)}`)
    const convertedResponse = await res.json()
    return convertedResponse
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}

export async function quickTripCheck(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = 'auto',
): Promise<QuickTripCheckReturn> {
  const response = await fetchValhallaRoutePlan(locations, costing)
  if (response && Object.hasOwn(response, 'trip')) {
    return QuickTripCheckReturn.routable
  } else if (
    response &&
    Object.hasOwn(response, 'error_code') &&
    (response as Valhalla_RouteError).error_code === 170
  ) {
    return QuickTripCheckReturn.unconnected_regions
  } else {
    return QuickTripCheckReturn.not_routable
  }
}
