<template>
  <div v-if="networkBranding">
    <!-- conditionally rendered operators logo -->

    <!-- Icon only -->
    <span
      v-if="mobile && networkBranding.iconLogoUUID"
      class="pwt-icon-only-logo-wrapper"
      :style="networkBranding.textColor ? `color: ${networkBranding.textColor}` : ''"
    >
      <span class="pwt-circular-logo-img-wrapper">
        <v-img :src="iconLogo" class="pwt-circular-logo-img" contained></v-img>
      </span>
    </span>

    <!-- full image logo -->
    <span v-else-if="networkBranding.textBakedLogoUUID">
      <v-img :src="textBakedLogo" class="pwt-full-img-logo" contained></v-img>
    </span>

    <!-- image and text logo -->
    <span
      v-else-if="networkBranding.iconLogoUUID"
      class="pwt-img-and-text-logo-wrapper"
      :style="networkBranding.textColor ? `color: ${networkBranding.textColor}` : ''"
    >
      <span class="pwt-circular-logo-img-wrapper">
        <v-img :src="iconLogo" class="pwt-circular-logo-img" contained></v-img>
      </span>
      {{ networkBranding.displayName }}
    </span>

    <!-- default to only network name as text if no known styling exists -->
    <span v-else>{{ networkBranding.displayName }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import NetworkBranding from '@/classes/charger_classes/networkBranding'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

interface Props {
  networkBranding?: NetworkBranding
}

const { networkBranding } = defineProps<Props>()

const iconLogo = computed(() => networkBranding?.iconLogoSrc)
const textBakedLogo = computed(() => networkBranding?.textBakedLogoSrc)
</script>

<style scoped>
.pwt-full-img-logo {
  width: 100px;
  height: auto;
}

.pwt-circular-logo-img-wrapper {
  overflow: hidden;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

.pwt-circular-logo-img {
  width: 24px;
  height: 24px;
}

.pwt-img-and-text-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pwt-icon-only-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 5px;
}
</style>
