<script setup lang="ts">
import { onMounted, getCurrentInstance, nextTick, watch, computed } from 'vue'
import { useStore } from 'vuex'
import LeafletMap from './components/map-panel/LeafletMap.vue'
import { checkToken } from './api/calls/directus-calls/auth-calls'
import { MutationTypes, ActionTypes } from '@/store/store_types'
import { keycloak } from '@/main'
import NetworkFilter from './components/settings/NetworkFilter.vue'
import SecondaryDialog from './components/dialog-wrappers/SecondaryDialog.vue'
import TertiaryDialog from './components/dialog-wrappers/TertiaryDialog.vue'
import MainDialog from './components/dialog-wrappers/MainDialog.vue'
import InfoPanelControls from './components/Info-panel/InfoPanelControls.vue'
import DashboardDialog from './components/dashboard/DashboardDialog.vue'
import LoginOverlay from './components/user/authorisation/LoginOverlay.vue'
import BottomLogo from './components/map-panel/BottomLogo.vue'

const store = useStore()
const instance = getCurrentInstance()
const $Countly = instance?.appContext.config.globalProperties.$Countly

const showLogin = computed(() => store.state.showLogin)
const authorizedFlag = computed(() => store.state.authorizedFlag)
const showDashboard = computed(() => store.state.showDashboard)

watch(authorizedFlag, (value) => {
  if (value) {
    toggleDashboard(true)
  }
})

function startRefresh() {
  setTimeout(() => {
    store.dispatch(ActionTypes.refreshData)
    startRefresh()
  }, 300000)
}

function toggleDashboard(show: boolean) {
  store.commit(MutationTypes.setShowDashboard, show)
}

onMounted(() => {
  $Countly.q.push(['track_pageview', 'landing'])
  nextTick(() => {
    checkToken().then((res) => {
      if (
        window.location.search.includes('reason=INVALID_PROVIDER') ||
        window.location.search.includes('reason=INVALID_CREDENTIALS')
      ) {
        keycloak.logout()
        return
      } else if (res) {
        store.commit(MutationTypes.setShowLogin, false)
        store.commit(MutationTypes.setAuthorizedFlag, true)
        store.commit(MutationTypes.setCheckingToken, false)
        store.dispatch(ActionTypes.fetchData)
        startRefresh()
        toggleDashboard(true)
        return
      }
      store.commit(MutationTypes.setShowLogin, true)
    })
  })
})
</script>

<template>
  <v-app>
    <v-main>
      <LeafletMap />
      <NetworkFilter />
      <MainDialog />
      <SecondaryDialog />
      <TertiaryDialog />
      <InfoPanelControls @showDashboard="toggleDashboard(true)" />
      <BottomLogo />
      <DashboardDialog :activator="showDashboard" @close="toggleDashboard(false)" />
      <LoginOverlay v-if="showLogin" />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import 'https://cdn.jsdelivr.net/npm/vuetify/dist/vuetify.min.css';
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');

.v-application {
  font-family: 'Public Sans', Roboto, sans-serif !important;
  background-color: rgb(var(--v-theme-background)) !important;
}

.v-card,
.v-sheet,
.v-toolbar,
.v-tabs-items,
.v-expansion-panel,
.v-skeleton-loader__article,
.v-skeleton-loader__list-item-avatar,
.container {
  background-color: rgb(var(--v-theme-background)) !important;
}

.v-card__title {
  word-break: normal !important;
}

.pwt-number-font {
  font-family: 'Inter', sans-serif !important;
}

#pwt-main-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100svh;
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

.v-application--wrap {
  overflow: hidden;
  min-height: unset;
}
</style>
