<template>
  <!-- sliding tab section -->
  <v-card flat>
    <v-tabs
      grow
      color="white"
      class="pwt-tabs-border"
      slider-color="primary"
      v-model="tab"
      background-color="background"
    >
      <v-tab :ripple="false">
        <span :class="tab === 0 ? 'pwt-custom-active-tab-text' : ''"> Instructions </span>
      </v-tab>
      <v-tab :ripple="false">
        <span :class="tab === 1 ? 'pwt-custom-active-tab-text' : ''"> Availability </span>
      </v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <!-- Instructions tab content -->
      <v-tabs-window-item>
        <v-card flat>
          <v-card-title class="text-primary">
            Instructions and details from the owner
          </v-card-title>
          <v-card-text class="pb-0"> Operated by - {{ networkDisplayName }} </v-card-text>
          <v-card-text v-if="instructions">
            <div v-html="instructions"></div>
          </v-card-text>
        </v-card>
      </v-tabs-window-item>

      <!-- Availability tab content -->
      <v-tabs-window-item class="pt-3 pb-5">
        <v-card flat> put a calender here </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card>
</template>
<script setup lang="ts">
import { ref } from 'vue'

const { instructions, networkDisplayName } = defineProps<{
  instructions?: string
  networkDisplayName?: string
}>()

const tab = ref(0)
</script>
<style scoped>
.pwt-tabs-border {
  border-bottom: 2px solid rgb(var(--v-theme-primary));
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}

* :deep(.v-tab__slider) {
  border-radius: 10px 10px 0 0;
  background-color: rgb(var(--v-theme-primary));
  height: 47px;
}
</style>
