<template>
  <v-dialog
    v-model="open"
    max-width="350px"
    content-class="rounded-lg"
    @click:outside="resetAndClose"
    @keydown="keyDown($event)"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-row align="end" no-gutters class="flex-nowrap mb-3">
        <span class="pr-2"> {{ label }} </span>
        <v-text-field
          dense
          :model-value="dateString"
          type="date"
          readonly
          required
          @click="openDatePicker"
          :dark="dark"
          v-bind="activatorProps"
          @click:clear="clear"
          clearable
          hide-details
          class="flex-grow-1 flex-shrink-1"
        />
      </v-row>
    </template>

    <v-card>
      <v-date-picker full-width v-model="date" />
      <v-card-actions>
        <ElevatedBtn @click="updateDate" class="flex-grow-1"> Set Date </ElevatedBtn>
        <OutlinedBtn @click="resetAndClose"> Cancel </OutlinedBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ElevatedBtn from '../buttons/ElevatedBtn.vue'
import OutlinedBtn from '../buttons/OutlinedBtn.vue'
import { DateTime } from 'luxon'

export interface DatePickerInputUpdateObj {
  identifier: string
  date: Date | null
}

interface Props {
  initialValue?: Date | null
  identifier: string
  label: string
  dark?: boolean
}

const { initialValue = undefined, identifier, label, dark = false } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update', value: DatePickerInputUpdateObj): void
}>()

const dateString = ref('')
const date = ref<Date | null>(null)
const open = ref(false)

function setInitialValue() {
  if (initialValue) {
    date.value = initialValue
    dateString.value = DateTime.fromJSDate(initialValue).setLocale('en-US').toFormat('yyyy-MM-dd')
  } else {
    date.value = null
    dateString.value = ''
  }
}

function openDatePicker() {
  open.value = true
}

function closeTimePicker() {
  open.value = false
}

function resetAndClose() {
  setInitialValue()
  closeTimePicker()
}

function updateDate() {
  dateString.value = date.value
    ? DateTime.fromJSDate(date.value).setLocale('en-US').toFormat('yyyy-MM-dd')
    : ''
  emit('update', {
    identifier,
    date: date.value,
  })
  closeTimePicker()
}

function keyDown(event: KeyboardEvent) {
  if (event.key === 'Escape') resetAndClose()
}

function clear() {
  date.value = null
  updateDate()
}

onMounted(() => {
  setInitialValue()
})
</script>
