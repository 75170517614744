<template>
  <v-carousel v-model="slide" height="unset" hide-delimiters :show-arrows="groups.length > 1">
    <v-carousel-item v-for="(group, index) in groups" :key="`slide-group-card-${index}`">
      <v-card flat :class="groups.length > 1 ? 'px-15' : 'px-3'">
        <v-row>
          <v-col
            v-for="(UUID, index) in group"
            cols="4"
            :key="`slide-group-image-card-${index}`"
            style="max-height: 300px; min-height: 150px"
          >
            <v-card
              v-if="UUID === 'default'"
              class="rounded-lg bg-secondary d-flex justify-center align-center"
              style="height: 100%"
            >
            </v-card>
            <v-img
              v-else
              :lazy-src="getSrc('car_images/No_Image_Powersell.png')"
              :src="getImageSrc(UUID)"
              width="100%"
              height="100%"
              aspect-ration="1"
              contain
            />
          </v-col>
        </v-row>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import getDirectusImgUrl from '@/utils/getDirectusImgUrl'
import getAssetSrc from '@/utils/getAssetSrc'

const { vehicle } = defineProps<{ vehicle?: Vehicle }>()

const slide = ref(0)

const groups = computed(() => {
  const returnArray: [string, string, string][] = []
  const numberOfGroups = getNumberOfGroups(vehicle?.images?.length ?? 0)
  for (let i = 0; i < numberOfGroups; i++) {
    returnArray.push([
      vehicle?.images?.[0 + i] ?? 'default',
      vehicle?.images?.[0 + i + 1] ?? 'default',
      vehicle?.images?.[0 + i + 2] ?? 'default',
    ])
  }
  // see if the ev model has an image to display also
  if (vehicle?.evModel?.imageUUID) {
    // find first default panel
    const firstDefaultIndex = returnArray[returnArray.length - 1].findIndex(
      (str) => str === 'default',
    )
    if (firstDefaultIndex === -1) {
      // if no default panel add a new group with model img and default panels to fill
      returnArray.push([vehicle.evModel.imageUUID, 'default', 'default'])
    } else {
      // over write first default panel to model img
      returnArray[returnArray.length - 1].splice(firstDefaultIndex, 1, vehicle.evModel.imageUUID)
    }
  }

  return returnArray
})

function getNumberOfGroups(numberOfImages: number) {
  if (numberOfImages === 0) return 1
  if (numberOfImages <= 2) return 1
  // ASSUMES: more than 2 so therefore more than one group
  // NOTE: three images are displayed at once and the final image is the default add new image display
  return numberOfImages - 1
}

function getImageSrc(UUID: string) {
  if (UUID) return getDirectusImgUrl(UUID)
  else return getSrc('car_images/No_Image_Powersell.png')
}

function getSrc(partialFilePath: string): string {
  return getAssetSrc(partialFilePath)
}
</script>
