import Trip from '../classes/trip_classes/trip'
import type { SortedTripsGroup } from '../types/sheared_local_types'

/**
 * Sorts a list of trips by EV model. Trips without an EV model are grouped together under an "unknown" group.
 *
 * @param trips - The list of trips to sort.
 * @return {SortedTripsGroup[]} A list of EV groups, each containing a list of trips. The first item in the list is the "unknown" group.
 */
export default function sortTripsViaEVGroups(trips: Trip[]): SortedTripsGroup[] {
  const returnArray: SortedTripsGroup[] = []
  const usedEVs: string[] = []
  const unsortedGroup: SortedTripsGroup = {
    evModel: 'unknown',
    trips: [],
  }
  trips.reverse().forEach((trip) => {
    const modelID: string | number | undefined = trip.vehicle_data?.eVModelId
    if (!modelID) unsortedGroup.trips.push(trip)

    if (modelID && usedEVs.includes(modelID)) {
      const index = returnArray.findIndex((group) => group.evModel === (modelID as string))
      if (index !== -1) {
        returnArray[index].trips.push(trip)
      } else {
        returnArray.push({
          evModel: modelID,
          trips: [trip],
        })
      }
    } else if (modelID) {
      returnArray.push({
        evModel: modelID,
        trips: [trip],
      })
      usedEVs.push(modelID)
    }
  })
  if (unsortedGroup.trips.length) returnArray.push(unsortedGroup)
  return returnArray
}
