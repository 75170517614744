import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus from '@/api/clients/directus-client'
import type { DirectusNetworkBranding } from '@/types/directus-types'

/** API Call to fetch network branding for all networks that have charging stations in DB. */
export async function fetchNetworkBranding(): Promise<DirectusNetworkBranding[] | undefined> {
  try {
    const res = await directus.items('NetworkBranding').readByQuery({ limit: -1 })
    if (res.data) return res.data as DirectusNetworkBranding[]
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}
