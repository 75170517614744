import type { DirectusDriver } from '@/types/directus-types'
import generateUniqueLocalID from '../../utils/generateUniqueLocalID'
import getDirectusImgUrl from '../../utils/getDirectusImgUrl'

export default class Driver {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = []

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string

  /** Directus `Driver` collection record id. */
  directusId?: number

  /** Display name for this driver. */
  name?: string

  /** Drivers profile pic asset file UUID. */
  profilePic?: string

  /** Drivers bio for display. */
  bio?: string

  /** Related directus `Vehicle` collection record id. */
  assignedVehicleDirectusId?: number

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Creates a new instance of the Driver class.
   *
   * @param localId - Unique local id for this driver.
   * @param directusId - Directus 'Driver' collection record id for this driver.
   * @param name - Display name for this driver.
   * @param profilePic - UUID of the drivers profile picture.
   * @param bio - Drivers bio for display.
   * @param assignedVehicleDirectusId - Directus record id for the assigned vehicle.
   */
  constructor({
    localId = undefined,
    directusId = undefined,
    name = undefined,
    profilePic = undefined,
    bio = undefined,
    assignedVehicleDirectusId = undefined,
  }: {
    /** Unique local id for this driver. */
    localId?: string
    /** directus 'Driver' collection record id for this driver */
    directusId?: number
    /** Display name for this driver. */
    name?: string
    /** UUID of the drivers profile picture. */
    profilePic?: string
    /** Drivers bio for display. */
    bio?: string
    /** Directus related 'Car_Record' collection record id for the assigned vehicle. */
    assignedVehicleDirectusId?: number
  }) {
    this.localId = localId ?? generateUniqueLocalID(Driver.usedIds, 'driver')
    this.directusId = directusId
    this.name = name
    this.profilePic = profilePic
    this.bio = bio
    this.assignedVehicleDirectusId = assignedVehicleDirectusId

    // add id to list of used unique ids
    Driver.usedIds.push(this.localId)
  }

  static fromDirectusData(data: DirectusDriver): Driver {
    return new Driver({
      directusId: data.id,
      name: data.Name ?? undefined,
      profilePic: data.ProfilePic ?? undefined,
      bio: data.Bio ?? undefined,
      assignedVehicleDirectusId: data.Driving ?? undefined,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the src path for this drivers profile pic if they have one. */
  public get profilePicSrc(): string | undefined {
    if (this.profilePic) return getDirectusImgUrl(this.profilePic)
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
