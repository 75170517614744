<template>
  <v-card flat class="px-5 pb-5">
    <v-row no-gutters justify="space-between" align="center">
      <span>
        <v-card-title class="pt-5 text-tertiary" v-if="type === 'CUSTOM_VEHICLES'">
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          My Vehicles
        </v-card-title>
        <v-card-title v-if="type === 'CONNECTED_VEHICLES'" class="pt-5 text-tertiary">
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Connected Vehicles
        </v-card-title>
        <v-card-title v-if="type === 'GENERIC_VEHICLES'" class="pt-5 text-tertiary">
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Generic EVs
        </v-card-title>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-text-field
      v-model="searchQuery"
      prepend-inner-icon="mdi-magnify"
      rounded
      filled
      clearable
      hide-details
      dense
      class="mx-5 mb-3"
    />
    <v-card-text v-if="type === 'CUSTOM_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in customVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="select"
        />
        <CardLikeButton />
      </v-row>
    </v-card-text>

    <v-card-text v-if="type === 'CONNECTED_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in connectedVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="select"
        />
      </v-row>
    </v-card-text>

    <v-card-text v-if="type === 'GENERIC_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in genericVehicles"
          :vehicle="vehicle"
          :key="`generic-vehicle-card-${index}`"
          @select="select"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  VehicleGalleryType,
} from '@/store/store_types'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import VehicleGalleryCard from './VehicleGalleryCard.vue'
import CardLikeButton from './CardLikeButton.vue'

const store = useStore()

const { type } = defineProps<{ type: VehicleGalleryType }>()

const panel = ref([0, 1])
const searchQuery = ref('')

const connectedVehicles = computed(() => {
  const unfilteredData: Vehicle[] = store.getters[GettersTypes.connectedVehicles]
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    return unfilteredData.filter((vehicle) => {
      if (vehicle.name && vehicle.name.toLowerCase().includes(query)) return vehicle
      if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query)) return vehicle
      if (vehicle.licensePlate && vehicle.licensePlate.toLowerCase().includes(query)) return vehicle
      if (vehicle.evModel) {
        if (vehicle.evModel.name.toLowerCase().includes(query)) return vehicle
      }
    })
  }
  return unfilteredData
})

const genericVehicles = computed(() => {
  const unfilteredData: Vehicle[] = store.getters[GettersTypes.genericVehicles]
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    return unfilteredData.filter((vehicle) => {
      if (vehicle.name && vehicle.name.toLowerCase().includes(query)) return vehicle
      if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query)) return vehicle
      if (vehicle.licensePlate && vehicle.licensePlate.toLowerCase().includes(query)) return vehicle
      if (vehicle.evModel) {
        if (vehicle.evModel.name.toLowerCase().includes(query)) return vehicle
      }
    })
  }
  return unfilteredData
})

const customVehicles = computed(() => {
  const unfilteredData: Vehicle[] = store.getters[GettersTypes.customVehicles]
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    return unfilteredData.filter((vehicle) => {
      if (vehicle.name && vehicle.name.toLowerCase().includes(query)) return vehicle
      if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query)) return vehicle
      if (vehicle.licensePlate && vehicle.licensePlate.toLowerCase().includes(query)) return vehicle
      if (vehicle.evModel) {
        if (vehicle.evModel.name.toLowerCase().includes(query)) return vehicle
      }
    })
  }
  return unfilteredData
})

onMounted(() => {
  if (!connectedVehicles.value.length) {
    panel.value = [1]
  }
})

function close() {
  store.commit(MutationTypes.setMainDialogContent, undefined)
}

function back() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_GALLERY)
}

function select(vehicle: Vehicle) {
  store.dispatch(ActionTypes.selectVehicle, vehicle)
  store.commit(MutationTypes.setMainDialogContent, undefined)
}
</script>
