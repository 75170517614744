<template>
  <v-container v-if="charger" class="pa-5 bg-background">
    <v-container class="pt-0 bg-background">
      <v-card-title class="pt-0 pl-0 bg-background">
        <v-row no-gutters align="center">
          {{ charger.name }}

          <!-- conditionally rendered Network logos -->
          <v-spacer v-if="charger.operator && charger.operator.name"></v-spacer>
          <OperatorLogo :networkBranding="branding" />
        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="charger.addressString" class="pl-0">
        {{ charger.addressString }}
      </v-card-subtitle>
      <v-card-subtitle class="pl-0" v-else>
        charging station address has not been provided
      </v-card-subtitle>
      <v-card-subtitle class="pl-0 pt-0 pb-10">
        data provided by {{ charger.dataSrcDisplayStr }}
      </v-card-subtitle>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title class="text-primary">
            Charging station devices
            <template v-slot:actions>
              <v-icon color="primary" large>mdi-menu-down</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <EvseCard :evses="charger.evses" @selectConnector="selectConnector" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actual charging stop info card -->
      <ChargingStopDetailsCard
        v-if="trip && charger && trip.chargingStopCDBIDs.includes(charger.id)"
      />
      <!-- Estimated charging stop info card -->
      <ChargingCalculatorCard
        v-else-if="charger && vehicle && vehicle.evModel"
        :connector="selectedConnector"
      />
      <!-- no EV model set error display -->
      <v-card class="pa-2 mt-5 mb-5 rounded-lg" v-else>
        <v-card-text> Please set an EV profile for your EV to see estimates. </v-card-text>
      </v-card>
      <!-- add charger section -->
      <v-card v-if="showAddChargingStopButton" class="mb-5 rounded-lg">
        <v-card-title> Add charger to your trip </v-card-title>
        <v-card-text>
          <v-slider
            v-model="chargeTo"
            min="1"
            max="100"
            step="1"
            track-color="grey-lighten-2"
            :label="'Charge up to ' + chargeTo + '%'"
            thumb-size="16"
            track-size="6"
          />
          <v-list class="bg-background">
            <v-list-subheader>Add to leg</v-list-subheader>
            <v-list-group v-model="selectedStep" color="primary" mandatory>
              <v-list-item v-for="(item, i) in stepOrder" :key="i">
                <v-list-item-title>
                  {{ item.displayName }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
          <ElevatedBlockBtn class="mt-5" @click="addChargingStop">
            Add charging stop
          </ElevatedBlockBtn>
        </v-card-text>
      </v-card>
      <v-card flat class="pb-10">
        <v-card-title class="text-primary"> Plugs and sockets </v-card-title>
        <PlugsCard :plugs="charger.allConnectors" />
        <v-card-title class="text-primary">Photos added</v-card-title>
        <ChargingStationImageCarousel />
        <ChargingStationsAnimatedTabsCard
          :networkDisplayName="branding && branding.displayName ? branding.displayName : undefined"
          :instructions="branding && branding.instructions ? branding.instructions : undefined"
        />
      </v-card>
    </v-container>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { GettersTypes, MutationTypes } from '@/store/store_types'
import PlugsCard from './PlugsCard.vue'
import ChargingCalculatorCard from './ChargingCalculatorCard.vue'
import ChargingStopDetailsCard from './ChargingStopDetailsCard.vue'
import ChargingStationImageCarousel from './ChargingStationImageCarousel.vue'
import OperatorLogo from './OperatorLogo.vue'
import EvseCard from './EvseCard.vue'
import ChargingStationsAnimatedTabsCard from './ChargingStationsAnimatedTabsCard.vue'
import ElevatedBlockBtn from '@/components/ui-elements/buttons/ElevatedBlockBtn.vue'
import Charger from '@/classes/charger_classes/charger'
import Trip from '@/classes/trip_classes/trip'
import type NetworkBranding from '@/classes/charger_classes/networkBranding'
import type Vehicle from '@/classes/vehicle_classes/vehicle'

const store = useStore()

const selectedConnector = computed(() => {
  if (selectedConnectorId.value) {
    return (
      store.getters[GettersTypes.selectedChargerData] as Charger | undefined
    )?.allConnectors.find((connector) => connector.id === selectedConnectorId.value)
  }
  return undefined
})

const charger = computed<Charger | undefined>(() => store.getters[GettersTypes.selectedChargerData])
const branding = computed<NetworkBranding | undefined>(
  () => store.getters[GettersTypes.selectedChargerBranding],
)
const trip = computed<Trip | undefined>(() => store.getters[GettersTypes.selectedTripData])
const vehicle = computed<Vehicle | undefined>(() => store.getters[GettersTypes.selectedVehicleData])

const showAddChargingStopButton = computed(() => {
  return (
    charger.value &&
    trip.value &&
    !trip.value.itinerary.chargerIDs.includes(charger.value.id) &&
    vehicle.value &&
    charger.value.isCompatible(vehicle.value) !== 'incompatible'
  )
})

const chargingCostPerMin = computed(() => store.state.defaultCostPerMinDC)
const publicCostPerKWh = computed(() => store.state.defaultPublicCostPerKWh)

const stepOrder = computed(() => {
  if (!trip.value) return []
  if (!charger.value) return []
  return trip.value.itinerary.orderStepsByDistanceFromPoint({
    lat: charger.value.coordinates.latitude,
    lon: charger.value.coordinates.longitude,
  })
})

const selectedConnectorId = ref<string | undefined>(undefined)
const chargeTo = ref(80)
const selectedStep = ref(0)
const fetching = ref(false)

watch(trip, () => {
  if (trip.value) {
    fetchCorridorCall()
  }
})

onMounted(() => {
  try {
    nextTick(() => {
      setInitialValues()
    })
  } catch (error) {
    console.error(error)
  }
})

function close() {
  store.commit(MutationTypes.setSelectedCharger, undefined)
  store.commit(MutationTypes.setMainDialogContent, undefined)
}

function selectConnector(uuid: string): void {
  selectedConnectorId.value = uuid
}

function setInitialValues(): void {
  if (vehicle.value && charger.value) {
    const connectors = charger.value.allConnectors.filter(
      (connector) => connector.isCompatible(vehicle.value!) !== 'incompatible',
    )
    if (connectors.length) selectConnector(connectors[0].id)
  } else if (charger.value) {
    selectConnector(charger.value.evses[0].connectors[0].id)
  }
}

function addChargingStop(): void {
  if (!charger.value) return
  if (!trip.value) return
  trip.value.addChargerToStep({
    stepIndex: stepOrder.value[selectedStep.value].stepIndex,
    charger: charger.value,
    stateOfChargeAfterCharging: chargeTo.value / 100,
    defaultCostPerKWh: publicCostPerKWh.value,
    defaultCostPerMinDC: chargingCostPerMin.value,
  })
  store.commit(MutationTypes.updateIndividualTrip, trip.value)
  close()
}

async function fetchCorridorCall(): Promise<void> {
  fetching.value = true
  if (!trip.value) return
  const outcome = await trip.value.getRadarData()
  if (outcome === 'SUCCESS') {
    store.commit(MutationTypes.updateIndividualTrip, trip.value)
  }
  fetching.value = false
}
</script>

<style scoped>
#pwt-custom-active-tab {
  border-radius: 10px 10px 0 0;
  background-color: var(--v-theme-primary);
}

.pwt-tabs-border {
  border-bottom: 2px solid var(--v-theme-primary);
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
