import generateUniqueLocalID from '@/utils/generateUniqueLocalID'
import Coordinate from '../common_classes/coordinate'
import type { EVNavWaypoint } from '@/types/ev_nav_types'
import type { Valhalla_Location } from '@/types/valhalla_types'
import haversineDistance from '@/utils/haversineDistance'
import Charger from '../charger_classes/charger'
import type { SavedRouteWaypointData } from '@/types/trip_specific_types'

interface TripLocationOptions {
  /** local session scope unique id for this trip. */
  local_id?: string
  /** Display String for location. */
  address?: string
  /** Lat/Lon coordinate object for this location. */
  coordinates?: Coordinate
  /**
   * The arrival time for this location. If no time is set it will appear as an
   * empty string
   */
  time?: string
  /** Optional property used for calculations of overnight trips. */
  date?: string
  /**
   * Optional property used for flagging this location as a waypoint that the
   * user will charge at.
   *
   * NOTE: This is not used for the starting or destination locations, only
   * additional stops.
   */
  chargeHere?: boolean
  /**
   * State Of Charge after charging at this location. Used as SOCAct for
   * planning the next leg of the trip.
   *
   * This should be between 0 and 1 as float representation of a percentage
   * e.g. 80% = 0.8
   */
  stateOfChargeAfterCharging?: number
  /**
   * Number of seconds the user is intending to stay at this location. This is
   * an optional property only used for additional stops calculating times
   * taking into account this extra duration of the stay at this location.
   */
  stay?: number
  /** Max power that can be transferred from a charger at this site in kW. */
  kWChargerRating?: number
  /**
   * Weight change for the load carried at this scheduled stop in kgs.
   *
   * NOTE: this could be either a positive or negative depending on if it is an
   * expected pick up or drop off.
   */
  weightChange?: number
  /**
   * kWh used by other process that make use of the travelling battery e.g.
   * powered raised/lowered platform.
   */
  nonDrivingEnergyUsed?: number
  /** Name of this location. */
  name?: string
}

export default class TripLocation {
  // ----------------------------------------------------------------------- //
  // -------------------------- Global class state ------------------------- //
  // ----------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = []

  // ----------------------------------------------------------------------- //
  // ------------------------------- State --------------------------------- //
  // ----------------------------------------------------------------------- //

  /** local session scope unique id for this trip. */
  local_id: string

  /** Display String for location. */
  address = ''

  /** Lat/Lon coordinate object for this location. */
  coordinates: Coordinate = new Coordinate({
    latitude: 0,
    longitude: 0,
  })

  /**
   * The arrival time for this location. If no time is set it will appear as an
   * empty string
   */
  time?: string

  /**
   * Number of seconds the user is intending to stay at this location. This is
   * an optional property only used for additional stops calculating times
   * taking into account this extra duration of the stay at this location.
   */
  stay?: number

  /** Optional property used for calculations of overnight trips. */
  date?: string

  /**
   * Optional property used for flagging this location as a waypoint that the
   * user will charge at.
   *
   * NOTE: This is not used for the starting or destination locations, only
   * additional stops.
   */
  chargeHere?: boolean

  /**
   * State Of Charge after charging at this location. Used as SOCAct for
   * planning the next leg of the trip.
   *
   * This should be between 0 and 1 as float representation of a percentage
   * e.g. 80% = 0.8
   */
  stateOfChargeAfterCharging?: number

  /**
   * Weight change for the load carried at this scheduled stop in kgs.
   *
   * NOTE: this could be either a positive or negative depending on if it is an
   * expected pick up or drop off.
   */
  weightChange?: number

  /**
   * kWh used by other process that make use of the travelling battery e.g.
   * powered raised/lowered platform.
   */
  nonDrivingEnergyUsed?: number

  /** Max power that can be transferred from a charger at this site in kW. */
  kWChargerRating?: number

  /** Name of this location. */
  name?: string

  // ----------------------------------------------------------------------- //
  // ---------------------------- Constructor ------------------------------ //
  // ----------------------------------------------------------------------- //

  constructor(options: TripLocationOptions | undefined = undefined) {
    this.local_id =
      options?.local_id ?? generateUniqueLocalID(TripLocation.usedIds, 'trip-location')
    if (options?.address) this.address = options.address
    if (options?.coordinates) this.coordinates = options.coordinates
    this.time = options?.time
    this.date = options?.date
    this.chargeHere = options?.chargeHere
    this.stateOfChargeAfterCharging = options?.stateOfChargeAfterCharging
    this.stay = options?.stay
    this.kWChargerRating = options?.kWChargerRating
    this.weightChange = options?.weightChange
    this.nonDrivingEnergyUsed = options?.nonDrivingEnergyUsed
    this.name = options?.name

    // add id to list of used unique ids
    // ASSUMES: if id already exists this is an overwrite to the original
    // object.
    if (!TripLocation.usedIds.includes(this.local_id)) {
      TripLocation.usedIds.push(this.local_id)
    }
  }

  /**
   * Creates a new TripLocationV2 instance from the provided SavedRouteWaypointData.
   *
   * @param {SavedRouteWaypointData} data - The data to create the TripLocationV2 from.
   * @return {TripLocation} A new TripLocationV2 instance created from the SavedRouteWaypointData.
   */
  static fromSavedData(data: SavedRouteWaypointData) {
    return new TripLocation({
      address: data.address,
      coordinates: new Coordinate({
        latitude: data.latitude,
        longitude: data.longitude,
      }),
      chargeHere: data.chargeHereFlag,
      stateOfChargeAfterCharging: data.stateOfChargeAfterCharging,
      stay: data.stayDuration,
      weightChange: data.weightChange,
      nonDrivingEnergyUsed: data.nonDrivingEnergyUsed,
      name: data.name,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the number of kWh added at this location. */
  public get addedCharge(): number {
    if (!this.kWChargerRating || !this.stay) return 0
    return this.kWChargerRating * (this.stay / 3600)
  }

  /** Returns a `EVNavWaypoint` object populated with data from this `TripLocationV2`. */
  public get asEVNavWaypoint(): EVNavWaypoint {
    return {
      Latitude: this.coordinates.latitude,
      Longitude: this.coordinates.longitude,
      Name: this.local_id,
    }
  }

  /** Returns a `Valhalla_Location` object populated with data from this `TripLocationV2`. */
  public get asValhallaLocation(): Valhalla_Location {
    return {
      lat: this.coordinates.latitude,
      lon: this.coordinates.longitude,
    }
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //
  /**
   * gets an array of `Charger` class objects for stations with in 1km of a this trip location.
   *
   * @param stations the full global state array of `Charger` objects.
   * @param chargersAlongRouteIds the 'Trip` array of ids for chargers along the route.
   * @returns an array of `Charger` class objects.
   */
  public getCloseChargers(stations: Charger[], chargersAlongRouteIds?: string[]) {
    const returnArray: Charger[] = []

    if (chargersAlongRouteIds) {
      chargersAlongRouteIds.forEach((id) => {
        const station = stations.find((station) => station.id === id)

        if (station) {
          if (this.chargerIsWithIn1Km(station)) {
            returnArray.push(station)
          }
        }
      })
    } else {
      stations.forEach((station) => {
        if (this.chargerIsWithIn1Km(station)) {
          returnArray.push(station)
        }
      })
    }

    return returnArray
  }

  private chargerIsWithIn1Km(charger: Charger): boolean {
    // bail out early if charger coordinates are default null island.
    if (charger.isNullIsland) return false
    // calculate distance from charger in km.
    const distanceFromWaypoint = haversineDistance(
      [charger.coordinates.longitude, charger.coordinates.latitude],
      [this.coordinates.longitude, this.coordinates.latitude],
    )
    // return weather charger is with in 1km.
    return distanceFromWaypoint <= 1
  }
}
