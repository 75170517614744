import type { CollectionNames } from '@/api/clients/directus-client'
import { directusCreateOne, directusReadOne, directusUpdateOne } from './helperFunctions'
import type { DirectusDriver } from '@/types/directus-types'

const collectionName: CollectionNames = 'Driver'
const query = {
  fields: ['id', 'Name', 'Driving', 'ProfilePic', 'Bio'],
}

/**
 * Creates a `Driver` collection record in directus.
 *
 * @param data partial object to create.
 * @returns the newly created record if successful, undefined if not.
 */
export async function createDriverRecord(
  data: Partial<DirectusDriver>,
): Promise<DirectusDriver | undefined> {
  return await directusCreateOne<DirectusDriver>(collectionName, data, query)
}

/**
 * Updates an existing directus `Driver` collection record.
 *
 * @param recordId the directus `Driver` collection record id.
 * @param dataToUpdate partial object to update
 * @returns the updates record if successful, undefined if not.
 */
export async function updateDriverRecord(
  recordId: string | number,
  dataToUpdate: Partial<DirectusDriver>,
) {
  return await directusUpdateOne<DirectusDriver>(collectionName, recordId, dataToUpdate, query)
}

/**
 * Fetches an individual driver record based on the passed id.
 *
 * @param driverId the id of the users driver record
 * @returns a user `DriverData` object if successful. Undefined if not.
 */
export async function fetchDriverData(driverId: number): Promise<DirectusDriver | undefined> {
  return await directusReadOne<DirectusDriver>('Driver', driverId, query)
}
