import type { FuelType, SelectedPlug } from '../../types/sheared_local_types'
import generateUniqueLocalID from '../../utils/generateUniqueLocalID'
import getDirectusImgUrl from '../../utils/getDirectusImgUrl'
import EVModel from './evModel'
import OptimiserDefaultData from '../../data/optimiserDefaultData'
import type { EVNavCar, EVNavCompatibleConnector, EVNavPlug } from '../../types/ev_nav_types'
import evNavDefaultData from '../../data/eVNavDefaultData'

import { connectorDetailsDataMap, getConnectorAssetSrc } from '../../data/connectorDetailsData'
import {
  type VehicleCreationFormData,
  VehicleType,
  type VehicleConnectorsDisplayData,
} from '../../types/vehicle_specific_types'
import type { MapsReverseGeocodeRes } from '../../types/maps_little_monkey_types'
import type {
  DirectusAdvancedConfig,
  DirectusUserSelectedPlug,
  DirectusVehicle,
  DirectusVehicleVariableData,
} from '@/types/directus-types'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { reverseGeocode } from '@/api/calls/maps-calls/reverse-geo-code-calls'
import {
  createNewVehicle,
  updateVehicle,
  updateVehicleImages,
  updateVehicleModel,
  updateVehiclePlugs,
  updateVehicleSOH,
} from '@/api/calls/directus-calls/vehicle-calls'
import parseIntOrFloat from '@/utils/parseIntOrFloat'

export default class Vehicle {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = []

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string

  /** Directus `Vehicle` collection record id. */
  directusId?: number

  /** Related directus `Driver` collection record id. */
  driverDirectusId?: number

  /** Related directus `Company` collection record id. */
  companyDirectusId?: number

  /** evNav/ChargerDB ev model ID.*/
  eVModelId?: string

  /** The licenses plate of the vehicle. */
  licensePlate?: string

  /** vehicle identification number. */
  VIN?: string

  /** Display name for this vehicle. Note can be overwritten by the display name given to a vehicle in its telematics provider data. */
  name?: string

  /** The fuel type of the vehicle. */
  fuelType?: FuelType

  /** The user provided state of health of the vehicles battery. Only applicable to EVs. */
  userProvidedStateOfHealth?: number

  /** The last known state of charge of this vehicle. expected in a range of 0-100 potentially including decimals. */
  stateOfCharge?: number

  /** The user provided list of connectors compatible with this vehicle. Only applicable to EVs. */
  userSelectedPlugs?: DirectusUserSelectedPlug[]

  /** File names for images help in directus related to this vehicles. */
  images: string[]

  /** the last known latitude for this vehicle. */
  latitude?: number

  /** the last known longitude for this vehicle. */
  longitude?: number

  /** the last known speed of this vehicle. */
  speed?: number

  /** the time this last known data was updated. In UTC date time string format. */
  lastUpdated?: string // UTC date time string

  /** Represents if the vehicles ignition is on or not. */
  ignitionOn: boolean

  /** The EV model object for this vehicle if applicable. */
  evModel?: EVModel

  /** Flag to indicate if this is a generic ev built without a real vehicle record. */
  vehicleType: VehicleType = VehicleType.TELEMATICS_VEHICLE

  /** The vehicles advanced config object if any advanced config has been set.
   *
   * Note: advanced config overrides properties from the ev model
   */
  advancedConfig?: DirectusAdvancedConfig

  /** Reversed Geo-coded location data object */
  locationData?: MapsReverseGeocodeRes

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    driverDirectusId = undefined,
    companyDirectusId = undefined,
    eVModelId = undefined,
    licensePlate = undefined,
    VIN = undefined,
    name = undefined,
    fuelType = undefined,
    userProvidedStateOfHealth = undefined,
    images = [],
    latitude = undefined,
    longitude = undefined,
    speed = undefined,
    lastUpdated = undefined,
    stateOfCharge = undefined,
    ignitionOn = false,
    userSelectedPlugs = [],
    advancedConfig = undefined,
    vehicleType = VehicleType.TELEMATICS_VEHICLE,
  }: {
    localId?: string
    directusId?: number
    driverDirectusId?: number
    companyDirectusId?: number
    eVModelId?: string
    licensePlate?: string
    VIN?: string
    name?: string
    fuelType?: FuelType
    userProvidedStateOfHealth?: number
    images?: string[]
    latitude?: number
    longitude?: number
    speed?: number
    lastUpdated?: string
    stateOfCharge?: number
    ignitionOn?: boolean
    userSelectedPlugs?: DirectusUserSelectedPlug[]
    advancedConfig?: DirectusAdvancedConfig
    vehicleType?: VehicleType
  }) {
    this.localId = localId ?? generateUniqueLocalID(Vehicle.usedIds, 'vehicle')
    this.directusId = directusId
    this.driverDirectusId = driverDirectusId
    this.companyDirectusId = companyDirectusId
    this.eVModelId = eVModelId
    this.licensePlate = licensePlate
    this.VIN = VIN
    this.name = name
    this.fuelType = fuelType
    this.userProvidedStateOfHealth = userProvidedStateOfHealth
    this.images = images
    this.latitude = latitude
    this.longitude = longitude
    this.speed = speed
    this.lastUpdated = lastUpdated
    this.stateOfCharge = stateOfCharge
    this.ignitionOn = ignitionOn
    this.userSelectedPlugs = userSelectedPlugs
    this.advancedConfig = advancedConfig
    this.vehicleType = vehicleType

    // add id to list of used unique ids
    Vehicle.usedIds.push(this.localId)
  }

  /**
   * Creates a new `Vehicle` class instance populated with data from a directus `Vehicle` collections record.
   *
   * @param data directus `Vehicle` collections record.
   * @returns a new `Vehicle` class instance
   */
  static fromDirectusData(data: DirectusVehicle): Vehicle {
    const images: string[] = []
    data.Images?.forEach((file) => {
      if (file.directus_files_id) images.push(file.directus_files_id)
    })

    const plugs: DirectusUserSelectedPlug[] = []
    data.UserSelectedPlugs?.forEach((plug) => {
      try {
        const parsedPlug: DirectusUserSelectedPlug = JSON.parse(plug)
        plugs.push(parsedPlug)
      } catch (error) {
        notifyAnalytics({
          type: 'log_error',
          data: error,
        })
      }
    })

    const vehicleType = data.from_slurper
      ? VehicleType.TELEMATICS_VEHICLE
      : VehicleType.CUSTOM_VEHICLE

    return new Vehicle({
      directusId: data.id,
      driverDirectusId: data.Driver ?? undefined,
      eVModelId: data.CDB_Model_ID ?? undefined,
      licensePlate: data.rego ?? undefined,
      VIN: data.vin ?? undefined,
      name: data.name ?? undefined,
      fuelType: data.fuel_type ? Vehicle.cleanUpFuelType(data.fuel_type) : undefined,
      userProvidedStateOfHealth: data.soh ?? undefined,
      images: images,
      latitude: data.Latitude ?? undefined,
      longitude: data.Longitude ?? undefined,
      speed: data.speed ?? undefined,
      lastUpdated: data.LastKnown ?? undefined,
      stateOfCharge: data.StateOfCharge ?? undefined,
      ignitionOn: data.Ignition_On ?? undefined,
      userSelectedPlugs: plugs.length ? plugs : undefined,
      advancedConfig: data.AdvancedConfig ?? undefined,
      vehicleType,
    })
  }

  static fromFormData(data: VehicleCreationFormData): Vehicle {
    const newVehicle = new Vehicle({
      fuelType: data.fuelType,
      licensePlate: data.plate ?? undefined,
      name: data.vehicleName ?? undefined,
      VIN: data.VIN ?? undefined,
      vehicleType: VehicleType.CUSTOM_VEHICLE,
      companyDirectusId: data.companyDirectusID,
      driverDirectusId: data.driverDirectusID,
    })

    if (data.evModel) {
      newVehicle.setEVModel(data.evModel)
    }

    newVehicle.userSelectedPlugs = newVehicle.compileConnectorsFromFormData(data)
    newVehicle.advancedConfig = newVehicle.compileAdvancedConfig(data)

    return newVehicle
  }

  /**
   * Cleans up a given fuel type string by removing any quotes and trimming the string.
   * If the string matches one of the valid fuel types, it is returned as an union value.
   * If the string does not match any of the valid fuel types, undefined is returned.
   * @param fuelType The string to clean up.
   * @returns The cleaned up string or undefined if it does not match any valid fuel type.
   */
  static cleanUpFuelType(fuelType: string): FuelType | undefined {
    const trimmedFuelType = fuelType.replaceAll('"', '').trim().toLowerCase() // redundancy to cover issue with data sometimes having an extra set of quotes around the string e.g ""Petrol"" instead of "Petrol" as well as od capitalization

    switch (trimmedFuelType) {
      case 'diesel':
        return 'Diesel'
      case 'petrol':
        return 'Petrol'
      case 'electric':
        return 'Electric'
      case 'hybrid':
        return 'Hybrid'
      case 'plug in hybrid':
        return 'Plug in hybrid'
      default:
        return undefined
    }
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns a list of the image src paths for this vehicle. */
  public get imageSrcPaths(): string[] {
    return this.images.map((image) => getDirectusImgUrl(image))
  }

  /** Returns the battery size for this vehicle. */
  public get batterySize(): number {
    if (this.advancedConfig?.BatterySize) return this.advancedConfig.BatterySize
    if (this.evModel) return this.evModel.batterySize
    return OptimiserDefaultData.battery
  }

  /** Returns the mass for this vehicle. */
  public get mass(): number {
    if (this.advancedConfig?.Mass) return this.advancedConfig.Mass
    if (this.evModel) return this.evModel.mass
    return evNavDefaultData.Mass
  }

  /** Returns the drag coefficient for this vehicle. */
  public get dragCoefficient(): number {
    if (this.advancedConfig?.DragCoefficient) return this.advancedConfig.DragCoefficient
    if (this.evModel?.dragCoefficient) return this.evModel.dragCoefficient
    return evNavDefaultData.DragCoefficient
  }

  /** Returns the rolling resistance coefficient for this vehicle. */
  public get rollingResistanceCoefficient(): number {
    if (this.advancedConfig?.RollingResistanceCoefficient)
      return this.advancedConfig.RollingResistanceCoefficient
    return evNavDefaultData.RollingResistanceCoefficient
  }

  /** Returns the regenerative breaking efficiency for this vehicle.  */
  public get regenerativeBreakingEfficiency(): number {
    if (this.advancedConfig?.RegenerativeBreakingEfficiency)
      return this.advancedConfig.RegenerativeBreakingEfficiency
    if (this.evModel?.regenRecovery) return this.evModel.regenRecovery
    return evNavDefaultData.RegenerativeBreakingEfficiency
  }

  /** Returns the max electric power AC for this vehicle.  */
  public get maxElectricPowerAC(): number {
    if (this.advancedConfig?.MaxElectricPowerAc) return this.advancedConfig.MaxElectricPowerAc
    if (this.evModel?.maxElectricPowerAC) return this.evModel.maxElectricPowerAC
    return evNavDefaultData.MaxElectricPowerAc
  }

  /** Returns the max electric power DC for this vehicle.  */
  public get maxElectricPowerDC(): number {
    if (this.advancedConfig?.MaxElectricPowerDc) return this.advancedConfig.MaxElectricPowerDc
    if (this.evModel?.maxElectricPowerDC) return this.evModel.maxElectricPowerDC
    return evNavDefaultData.MaxElectricPowerDc
  }

  /** Returns the power chain efficiency for this vehicle. */
  public get powerChainEfficiency(): number {
    if (this.advancedConfig?.PowerChainEfficiency) return this.advancedConfig.PowerChainEfficiency
    if (this.evModel?.powerChainEfficiency) return this.evModel.powerChainEfficiency
    return evNavDefaultData.PowerChainEfficiency
  }

  /** Returns the expected max capacity for the vehicles battery in kWh. */
  public totalBatteryKWh(SOH: number | undefined = undefined): number {
    // check if can be calculated from actual state of health and advanced config.
    if (this.advancedConfig?.BatterySize)
      return Math.round(
        this.advancedConfig.BatterySize * (SOH ?? this.userProvidedStateOfHealth ?? 1),
      )

    // check if can be calculated from actual state of health and battery size.
    if (this.evModel && SOH) return Math.round(this.evModel.batterySize * SOH)
    if (this.evModel && this.userProvidedStateOfHealth)
      return Math.round(this.evModel.batterySize * this.userProvidedStateOfHealth)

    // Check if can use linear degradation if actual data is not present.
    if (this.evModel) return this.evModel.calcLinearDegradation()

    // Failing both actual data and linear degradation return default value.
    if (SOH) return OptimiserDefaultData.battery * SOH
    if (this.userProvidedStateOfHealth)
      return OptimiserDefaultData.battery * this.userProvidedStateOfHealth

    return OptimiserDefaultData.battery
  }

  /** Returns car params for ev nav route planning. */
  public get routePlanningCarParam(): EVNavCar {
    const tempObj: EVNavCar = {}

    if (this.eVModelId) tempObj.Id = this.eVModelId

    // collate plugs.
    const plugs = this.evNavPlugs
    if (plugs.length) tempObj.CompatibleConnectors = plugs

    if (this.advancedConfig) {
      if (this.advancedConfig.AccelerationAdjustment)
        tempObj.AccelerationAdjustment = this.advancedConfig.AccelerationAdjustment
      if (this.advancedConfig.DragCoefficient)
        tempObj.DragCoefficient = this.advancedConfig.DragCoefficient
      if (this.advancedConfig.Mass) tempObj.Mass = this.advancedConfig.Mass
      if (this.advancedConfig.MaxElectricPowerAc)
        tempObj.MaxElectricPowerAc = this.advancedConfig.MaxElectricPowerAc
      if (this.advancedConfig.MaxElectricPowerDc)
        tempObj.MaxElectricPowerDc = this.advancedConfig.MaxElectricPowerDc
      if (this.advancedConfig.PowerChainEfficiency)
        tempObj.PowerChainEfficiency = this.advancedConfig.PowerChainEfficiency
      if (this.advancedConfig.RegenerativeBreakingEfficiency)
        tempObj.RegenerativeBreakingEfficiency = this.advancedConfig.RegenerativeBreakingEfficiency
      if (this.advancedConfig.RollingResistanceCoefficient)
        tempObj.RollingResistanceCoefficient = this.advancedConfig.RollingResistanceCoefficient
      if (this.advancedConfig.SpeedAdjustment)
        tempObj.SpeedAdjustment = this.advancedConfig.SpeedAdjustment
      if (this.advancedConfig.WeatherFactor)
        tempObj.WeatherFactor = this.advancedConfig.WeatherFactor
    }

    return tempObj
  }

  /** Return this vehicles plugs in a format for ev nav route planning. */
  public get evNavPlugs(): EVNavCompatibleConnector[] {
    const tempArray: EVNavCompatibleConnector[] = []
    const maxACinKW =
      this.advancedConfig?.MaxElectricPowerAc ??
      this.evModel?.maxElectricPowerAC ??
      evNavDefaultData.MaxElectricPowerAc
    const maxDCinKW =
      this.advancedConfig?.MaxElectricPowerDc ??
      this.evModel?.maxElectricPowerDC ??
      evNavDefaultData.MaxElectricPowerDc
    // collect and format user selected plugs.
    this.userSelectedPlugs?.forEach((plug) => {
      if (Array.isArray(plug.powerType)) {
        plug.powerType.forEach((powerType) => {
          tempArray.push({
            Format: plug.format,
            MaxElectricPower: powerType === 'DC' ? maxDCinKW * 1000 : maxACinKW * 1000,
            PowerType: powerType,
            Standard: plug.standard,
          })
        })
      } else {
        tempArray.push({
          Format: plug.format,
          Standard: plug.standard,
          PowerType: plug.powerType,
          MaxElectricPower: plug.powerType === 'DC' ? maxDCinKW * 1000 : maxACinKW * 1000,
        })
      }
    })

    // If populated return formatted user selected plugs.
    if (tempArray.length) return tempArray

    // If no user selected plugs get defaults from model.
    if (this.evModel)
      return this.evModel.compatibleConnectors.map((connector) => ({
        Format: connector.format,
        Standard: connector.standard,
        PowerType: connector.power_type,
        MaxElectricPower: connector.max_electric_power,
      }))

    // If no user selected plugs and no model data return an empty array.
    return []
  }

  /** The state of health of the vehicles battery. Only applicable to EVs. */
  public get stateOfHealth(): number | undefined {
    if (!this.userProvidedStateOfHealth && !this.evModel) return

    if (this.userProvidedStateOfHealth) return this.userProvidedStateOfHealth

    if (!this.userProvidedStateOfHealth && this.evModel)
      return this.evModel.calcLinearDegradationSOH()
  }

  /** compatible connector display data */
  public get connectorsDisplayData(): VehicleConnectorsDisplayData[] {
    const tempArray: VehicleConnectorsDisplayData[] = []
    const usedArray: string[] = []
    let connectorsArray: DirectusUserSelectedPlug[] | EVNavPlug[] = []
    if (this.userSelectedPlugs && this.userSelectedPlugs.length) {
      connectorsArray = this.userSelectedPlugs
    } else if (this.evModel) {
      connectorsArray = this.evModel.compatibleConnectors
    }
    connectorsArray.forEach((connector) => {
      const usedName = connector.standard + connector.format
      const data = connectorDetailsDataMap.get(connector.standard)
      if (!usedArray.includes(usedName) && data) {
        tempArray.push({
          displayName: data.displayName + (connector.format === 'SOCKET' ? ' cable' : ''), // from vehicles perspective not chargers
          imageSrc: getConnectorAssetSrc(connector.standard, connector.format),
        })
        usedArray.push(usedName)
      }
    })

    return tempArray
  }

  public async locationDisplayStr(): Promise<string> {
    if (!this.latitude || !this.longitude) return 'unknown'
    if (this.locationData) return this.locationData.display_name
    const location = await reverseGeocode(this.latitude, this.longitude)
    if (location) {
      this.locationData = location
      return location.display_name
    }
    return 'unknown'
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Calculates and returns the estimated maximum number of metres the car should drive with the given battery capacity.
   *
   * @param extraWeight optional additional weight above the vehicles mass.
   * @param SOH optional state of health to use for calculation if not yet saved to vehicle profile.
   * @returns estimated maximum range in meters.
   */
  public calcMaxRange(extraWeight = 0, SOH: number | undefined = undefined): number {
    const joulesTokWh = 0.000000277778
    const rollingResistanceCoefficient = this.advancedConfig?.RollingResistanceCoefficient ?? 0.015
    const airDensity = 1.225
    const dragCoefficient =
      this.advancedConfig?.DragCoefficient ??
      this.evModel?.dragCoefficient ??
      evNavDefaultData.DragCoefficient
    const totalMass =
      (this.advancedConfig?.Mass ?? this.evModel?.mass ?? evNavDefaultData.Mass) + extraWeight
    const powerChainEfficiency =
      this.advancedConfig?.PowerChainEfficiency ??
      this.evModel?.powerChainEfficiency ??
      evNavDefaultData.PowerChainEfficiency

    return (
      this.totalBatteryKWh(SOH) /
      ((0.5 * airDensity * 493.827161 * dragCoefficient +
        rollingResistanceCoefficient * totalMass * 9.807) *
        joulesTokWh *
        (1.0 / powerChainEfficiency))
    )
  }

  /**
   * Updates the vehicles regularly updated data (e.g coordinates etc...) with
   * those passed form the recently fetch `Vehicle` collection object.
   *
   * @param updatedData the directus `Vehicle` collection object for this vehicle.
   */
  public updateData(updatedData: DirectusVehicle) {
    // check if vehicle has moved and clear location data if it has.
    if (this.latitude !== updatedData.Latitude || this.longitude !== updatedData.Longitude)
      this.locationData = undefined
    // update select data that may have changed.
    this.latitude = updatedData.Latitude ?? undefined
    this.longitude = updatedData.Longitude ?? undefined
    this.stateOfCharge = updatedData.StateOfCharge ?? undefined
    this.speed = updatedData.speed ?? undefined
    this.ignitionOn = updatedData.Ignition_On ?? false
    this.lastUpdated = updatedData.LastKnown ?? undefined
  }

  /** Sets the ev model. This will also set the ev model id if needed. */
  public setEVModel(evModel?: EVModel) {
    this.evModel = evModel
    this.fuelType = evModel?.fuelType ? Vehicle.cleanUpFuelType(evModel.fuelType) : undefined
    if (this.eVModelId !== evModel?.id) {
      this.eVModelId = evModel?.id
      // sync update with DB if needed.
      if (this.directusId) updateVehicleModel(this.directusId, evModel?.id)
    }
  }

  /** upload passed image to cloud storage and update reference to image. */
  public async uploadImage(imageFile: File): Promise<'failed' | 'success'> {
    if (!this.directusId) return 'failed'
    const res = await updateVehicleImages(this.directusId, imageFile)
    if (res.success && res.imageUUID) {
      this.images.push(res.imageUUID)
      return 'success'
    }
    return 'failed'
  }

  /**
   * Updates both the local SOH and the directus record for this vehicle if it has one.
   *
   * @param newSOH the new SOH.
   */
  public async updateSOH(newSOH: number) {
    if (newSOH !== this.userProvidedStateOfHealth && this.directusId) {
      // update DB record
      await updateVehicleSOH(this.directusId, newSOH)
    }

    // update local SOH
    this.userProvidedStateOfHealth = newSOH
  }

  /**
   * Update the user select plugs override for this vehicle.
   *
   * @param selectedPlugs an array of plugs to be set to this vehicle
   * @returns status of the request
   */
  public async setSelectedPlugs(selectedPlugs: SelectedPlug[]): Promise<'SUCCESS' | 'FAILED'> {
    // check if empty array
    if (!selectedPlugs.length) {
      // Assumes: that an empty array being passed is an attempt to
      // remove user selected plugs and return to defaults.
      if (this.userSelectedPlugs && this.directusId) {
        // clear attribute in DB.
        const outcome = await updateVehiclePlugs(this.directusId, null)
        if (outcome) {
          this.userSelectedPlugs = undefined
          return 'SUCCESS'
        }
        // Assumes: failed if no outcome received.
        return 'FAILED'
      }

      // no DB record just clear selection locally.
      this.userSelectedPlugs = undefined
      return 'SUCCESS'
    }

    // convert `SelectedPlug` items to `UserSelectedPlug` items.
    const convertedPlugs: DirectusUserSelectedPlug[] = []

    selectedPlugs.forEach((plug) => {
      const convertedPlug = this.convertSelectedPlugToUserSelectedPlug(plug)
      if (convertedPlug) convertedPlugs.push(convertedPlug)
    })

    // check if db record needs updating
    if (this.directusId) {
      const outcome = await updateVehiclePlugs(this.directusId, convertedPlugs)
      if (outcome) {
        this.userSelectedPlugs = undefined
        return 'SUCCESS'
      }
      // Assumes: failed if no outcome received.
      return 'FAILED'
    }

    // no DB record just set selection locally.
    this.userSelectedPlugs = convertedPlugs
    return 'SUCCESS'
  }

  /**
   * Helper method that converts a `SelectedPlug` object into a `UserSelectedPlug` object.
   *
   * @param selectedPlug a `SelectedPlug` object
   * @returns a `UserSelectedPlug` object if the necessary data was in the original passed object.
   */
  public convertSelectedPlugToUserSelectedPlug(
    selectedPlug: SelectedPlug,
  ): DirectusUserSelectedPlug | undefined {
    const powerType = connectorDetailsDataMap.get(selectedPlug.standard)?.powerType

    if (!powerType) return

    return {
      format: selectedPlug.format,
      standard: selectedPlug.standard,
      powerType,
    }
  }

  /**
   * Saves this `Vehicle` to directus as a directus `Vehicles` collection record.
   */
  public async saveVehicle(): Promise<'failed' | 'success'> {
    if (this.directusId) return await this.updateDirectusData()
    return await this.createDirectusRecord()
  }

  public async updateDirectusData(
    dataToUpdate?: DirectusVehicleVariableData,
  ): Promise<'failed' | 'success'> {
    // check if directus id has been recorded
    if (!this.directusId) return await this.createDirectusRecord()
    // update directus with only passed data
    if (dataToUpdate) {
      const res = await updateVehicle(this.directusId, dataToUpdate)
      if (res) return 'success'
    }
    // update directus with compiled data.
    const res = await updateVehicle(this.directusId, this.compileDirectusVehicleVariableData())
    if (res) return 'success'
    return 'failed'
  }

  private async createDirectusRecord(): Promise<'failed' | 'success'> {
    // compile creation data
    const tempObj: DirectusVehicleVariableData = this.compileDirectusVehicleVariableData()

    // create new directus record
    const res = await createNewVehicle(tempObj)

    // process res
    if (res) {
      this.directusId = res.id
      return 'success'
    }
    return 'failed'
  }

  /**
   * Compiles the creation data into a DirectusVehicleVariableData object.
   *
   * @return {DirectusVehicleVariableData} The compiled DirectusVehicleVariableData object.
   */
  private compileDirectusVehicleVariableData(): DirectusVehicleVariableData {
    // compile creation data
    const tempObj: DirectusVehicleVariableData = {}
    if (this.driverDirectusId) tempObj.Driver = this.driverDirectusId
    if (this.eVModelId) tempObj.CDB_Model_ID = this.eVModelId
    if (this.licensePlate) tempObj.rego = this.licensePlate
    if (this.VIN) tempObj.vin = this.VIN
    if (this.name) tempObj.name = this.name
    if (this.fuelType) tempObj.fuel_type = this.fuelType
    if (this.userSelectedPlugs)
      tempObj.UserSelectedPlugs = this.userSelectedPlugs.map((plug) => JSON.stringify(plug))
    if (this.advancedConfig) tempObj.AdvancedConfig = JSON.stringify(this.advancedConfig)
    return tempObj
  }

  /**
   * Compiles what needs to be set for a `Vehicle` class objects `advancedConfig`
   * property based on the data passed.
   *
   * Only data that needs to be included in advanced config will be returned if
   * all matches model/defaults if no model it will return undefined.
   *
   * @param data the whole `VehicleCreationFormData` object.
   * @returns `AdvancedConfig | undefined`
   */
  public compileAdvancedConfig(data: VehicleCreationFormData): DirectusAdvancedConfig | undefined {
    const tempObj: DirectusAdvancedConfig = {}
    // only add data that is different from model if there is one or defaults if no model
    if (data.RollingResistanceCoefficient) {
      const rollingResist = parseIntOrFloat(data.RollingResistanceCoefficient)
      if (rollingResist && rollingResist !== evNavDefaultData.RollingResistanceCoefficient)
        tempObj.RollingResistanceCoefficient = rollingResist
    }

    if (data.evModel) {
      if (data.Mass) {
        const mass = parseIntOrFloat(data.Mass)
        if (mass && mass !== data.evModel.mass) tempObj.Mass = mass
      }
      if (data.DragCoefficient) {
        const drag = parseIntOrFloat(data.DragCoefficient)
        if (drag && drag !== data.evModel.dragCoefficient) tempObj.DragCoefficient = drag
      }
      if (data.RegenerativeBreakingEfficiency) {
        const regenBreakingEfficiency = parseIntOrFloat(data.RegenerativeBreakingEfficiency)
        if (regenBreakingEfficiency && regenBreakingEfficiency / 100 !== data.evModel.regenRecovery)
          tempObj.RegenerativeBreakingEfficiency = regenBreakingEfficiency / 100
      }
      if (data.PowerChainEfficiency) {
        const powerChainEfficiency = parseIntOrFloat(data.PowerChainEfficiency)
        if (
          powerChainEfficiency &&
          powerChainEfficiency / 100 !== data.evModel.powerChainEfficiency
        )
          tempObj.PowerChainEfficiency = powerChainEfficiency / 100
      }
      if (data.MaxElectricPowerAc) {
        const maxAC = parseIntOrFloat(data.MaxElectricPowerAc)
        if (maxAC && maxAC !== data.evModel.maxElectricPowerAC) tempObj.MaxElectricPowerAc = maxAC
      }
      if (data.MaxElectricPowerDc) {
        const maxDC = parseIntOrFloat(data.MaxElectricPowerDc)
        if (maxDC && maxDC !== data.evModel.maxElectricPowerDC) tempObj.MaxElectricPowerDc = maxDC
      }
    } else {
      if (data.Mass) {
        const mass = parseIntOrFloat(data.Mass)
        if (mass && mass !== evNavDefaultData.Mass) tempObj.Mass = mass
      }
      if (data.DragCoefficient) {
        const drag = parseIntOrFloat(data.DragCoefficient)
        if (drag && drag !== evNavDefaultData.DragCoefficient) tempObj.DragCoefficient = drag
      }

      if (data.RegenerativeBreakingEfficiency) {
        const regenBreakingEfficiency = parseIntOrFloat(data.RegenerativeBreakingEfficiency)
        if (
          regenBreakingEfficiency &&
          regenBreakingEfficiency / 100 !== evNavDefaultData.RegenerativeBreakingEfficiency
        )
          tempObj.RegenerativeBreakingEfficiency = regenBreakingEfficiency / 100
      }
      if (data.PowerChainEfficiency) {
        const powerChainEfficiency = parseIntOrFloat(data.PowerChainEfficiency)
        if (
          powerChainEfficiency &&
          powerChainEfficiency / 100 !== evNavDefaultData.PowerChainEfficiency
        )
          tempObj.PowerChainEfficiency = powerChainEfficiency / 100
      }
      if (data.MaxElectricPowerAc) {
        const maxAC = parseIntOrFloat(data.MaxElectricPowerAc)
        if (maxAC && maxAC !== evNavDefaultData.MaxElectricPowerAc)
          tempObj.MaxElectricPowerAc = maxAC
      }
      if (data.MaxElectricPowerDc) {
        const maxDC = parseIntOrFloat(data.MaxElectricPowerDc)
        if (maxDC && maxDC !== evNavDefaultData.MaxElectricPowerDc)
          tempObj.MaxElectricPowerDc = maxDC
      }
    }

    if (Object.keys(tempObj).length) return tempObj
    return undefined
  }

  /**
   * Compiles what needs to be set for a `Vehicle` class objects `userSelectedPlugs`
   * property based on the data passed.
   *
   * Only data that needs to be included in advanced config will be returned if
   * all matches the base models connectors it will return undefined. If no model
   * all need to be set and will be formatted as such.
   *
   * @param formData the whole `VehicleCreationFormData` object.
   * @returns `UserSelectedPlug[] | undefined`
   */
  public compileConnectorsFromFormData(
    formData: VehicleCreationFormData,
  ): DirectusUserSelectedPlug[] | undefined {
    const tempArray: DirectusUserSelectedPlug[] = []
    if (formData.evModel) {
      // check if connectors are already included on base model or if new ones have been added.
      formData.selectedConnectors.forEach((connector) => {
        const isDefault = !!formData.evModel?.compatibleConnectors.find(
          (cc) => cc.standard === connector.standard,
        )
        if (!isDefault) {
          if (Array.isArray(connector.powerType)) {
            connector.powerType.forEach((type) => {
              tempArray.push({
                format: 'CABLE',
                powerType: type,
                standard: connector.standard,
              })
            })
          } else {
            tempArray.push({
              format: 'CABLE',
              powerType: connector.powerType,
              standard: connector.standard,
            })
          }
        }
      })
    } else {
      // ASSUMES: no model so no base connectors thus all need to be added.
      formData.selectedConnectors.forEach((connector) => {
        if (Array.isArray(connector.powerType)) {
          connector.powerType.forEach((type) => {
            tempArray.push({
              format: 'CABLE',
              powerType: type,
              standard: connector.standard,
            })
          })
        } else {
          tempArray.push({
            format: 'CABLE',
            powerType: connector.powerType,
            standard: connector.standard,
          })
        }
      })
    }

    if (tempArray.length) return tempArray
    return undefined
  }

  /**
   * Converts the current Vehicle object to an EVNavCar object.
   *
   * @return {EVNavCar} The converted EVNavCar object.
   * @throws {Error} If no EV model is found.
   */
  public toEVNavVehicle(): EVNavCar {
    if (!this.eVModelId || !this.evModel) throw new Error('No EV model found')
    const tempObj: EVNavCar = {
      Id: this.eVModelId,
    }

    // check if connectors are already included on base model or if new ones have been added.
    if (this.userSelectedPlugs) {
      const tempArray: EVNavCompatibleConnector[] = []
      this.userSelectedPlugs.forEach((plug) => {
        if (Array.isArray(plug.powerType)) {
          plug.powerType.forEach((type) => {
            tempArray.push({
              Standard: plug.standard,
              PowerType: type,
              Format: plug.format,
              MaxElectricPower: null,
            })
          })
        } else {
          tempArray.push({
            Standard: plug.standard,
            PowerType: plug.powerType,
            Format: plug.format,
            MaxElectricPower: null,
          })
        }
      })
      if (tempArray.length) tempObj.CompatibleConnectors = tempArray
    }
    // check if base model config needs to be overridden
    if (this.advancedConfig) {
      Object.keys(this.advancedConfig).forEach((key) => {
        if (key in tempObj) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(tempObj as any)[key] = (this.advancedConfig as any)[key]
        }
      })
    }
    return tempObj
  }
}
