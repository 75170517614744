<template>
  <v-slide-group class="bg-background">
    <v-slide-group-item v-for="(trip, index) in unsavedTrips" :key="index">
      <div style="max-width: 300px" class="pa-2 mt-2 rounded-lg bg-background">
        <UnsavedTripCard :trip="trip" />
      </div>
    </v-slide-group-item>
  </v-slide-group>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { GettersTypes } from '@/store/store_types'
import UnsavedTripCard from './UnsavedTripCard.vue'

const store = useStore()

const unsavedTrips = computed(() => store.getters[GettersTypes.unsavedTrips])
</script>
