<template>
  <v-card flat v-if="locationData" class="px-8 bg-transparent">
    <v-card-title class="pt-0" style="word-break: normal">
      {{ locationData.address }}
    </v-card-title>
    <v-card-text>
      <ul style="list-style: none" class="pl-0">
        <li v-if="isOrigin && isDestination">This location is your origin and your destination</li>
        <li v-else-if="isOrigin">This location is your origin</li>
        <li v-else-if="isDestination">This location is your destination</li>

        <li v-if="locationData.time">
          {{ getTimeDisplayMessage(locationData.id, locationData.time) }}
        </li>
        <li v-if="tripData && isTrip && isOrigin" class="pt-2">
          Departing charge {{ Math.round(tripData.SOCAct * 100) }}%
        </li>
        <li v-else-if="tripData && isTrip && isDestination" class="pt-2">
          Arriving charge {{ Math.round(tripData.SOCEnd * 100) }}%
        </li>
        <li v-if="locationData.stay" class="pt-2">
          You are scheduled to be at this location for
          {{ calcStayDuration(locationData.stay) }}
        </li>
        <li v-if="locationData.chargeHere && locationData.stateOfChargeAfterCharging" class="pt-2">
          You have indicated that you will charge to
          {{ locationData.stateOfChargeAfterCharging * 100 }}% while at this location.
        </li>
      </ul>
    </v-card-text>
    <v-expansion-panels class="mb-5" v-if="!isOrigin && !isDestination">
      <v-expansion-panel>
        <v-expansion-panel-title class="d-flex text-body-2 font-weight-medium">
          <span>
            <v-icon color="primary" class="mr-1"> $stationsIcon </v-icon>
            <span>
              {{ closeByChargers.length || 'No' }} charging station{{
                closeByChargers.length > 1 ? 's' : ''
              }}
              near by</span
            >
          </span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-card
            v-for="(station, index) in closeByChargers"
            :key="`close-by-station-${index}`"
            @click="openChargingStationDetails(station.id)"
          >
            <v-card-title class="font-weight-bold text-body-2">
              {{ station.name }}
            </v-card-title>
            <v-card-subtitle class="text-grey-darken-2">
              {{ station.simpleDisplayRating }}
            </v-card-subtitle>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <v-card flat v-else>
    <v-card-text>
      Whoops! there was a problem in loading this locations details, please close this popup and try
      again.
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { Duration } from 'luxon'
import { GettersTypes, type State } from '@/store/store_types'
import { MutationTypes } from '@/store/store_types'
import { MainDialogContent } from '@/store/store_types'
import Trip from '@/classes/trip_classes/trip'
import TripLocation from '@/classes/trip_classes/tripLocation'

const store = useStore<State>()

const closeByChargers = computed(() => {
  const trip = store.getters[GettersTypes.selectedTripData]
  const location = store.getters[GettersTypes.selectedLocationData]
  const chargers = store.state.chargers
  if (trip && location) {
    return location.getCloseChargers(chargers, trip.chargersAlongRouteCDBIDs)
  }
  return []
})

const isOrigin = computed(() => {
  const trip = store.getters[GettersTypes.selectedTripData]
  const location = store.getters[GettersTypes.selectedLocationData]
  if (trip && location) {
    return location.local_id === trip.locations[0].local_id
  }
  return false
})

const isDestination = computed(() => {
  const trip = store.getters[GettersTypes.selectedTripData]
  const location = store.getters[GettersTypes.selectedLocationData]
  if (trip instanceof Trip && location instanceof TripLocation) {
    if (trip.roundTripFlag) {
      return location.local_id === trip.locations[0].local_id // note is also the origin as is a round trip.
    }
    return location.local_id === trip.locations[trip.locations.length - 1].local_id
  }
  return false
})

const isTrip = computed(() => {
  return store.getters[GettersTypes.selectedTripData] instanceof Trip
})

const locationData = computed(() => store.getters[GettersTypes.selectedLocationData])
const tripData = computed(() => store.getters[GettersTypes.selectedTripData])

const calcStayDuration = (staySeconds: number) => {
  return Duration.fromObject({
    hours: 0,
    minutes: Math.round(staySeconds / 60),
  })
    .normalize()
    .toHuman({ unitDisplay: 'narrow' })
    .replace(',', '')
}

const getTimeDisplayMessage = (id: string, time: string) => {
  switch (id) {
    case 'location-start':
      return `Departure time ${time}`
    case 'location-end':
    default:
      return `Arrival time ${time}`
  }
}

const openChargingStationDetails = (id: string) => {
  store.commit(MutationTypes.setSelectedCharger, id)
  store.commit(MutationTypes.setTertiaryDialogContent, undefined)
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.CHARGING_STATION_DETAILS)
}
</script>
