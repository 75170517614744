<template>
  <v-card flat class="bg-background">
    <v-card-title class="text-h5"> My fleet </v-card-title>
    <v-card flat class="bg-background">
      <v-card-title>
        <v-row no-gutters align="center">
          <span> Fleet vehicles </span>
          <v-spacer />
          <v-btn v-if="smAndDown" icon @click="() => emit('view-vehicles')">
            <v-icon color="grey-lighten-1">mdi-dots-vertical</v-icon>
          </v-btn>
          <TextBtn v-else @click="() => emit('view-vehicles')"> View all </TextBtn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <span v-if="!vehicleList.length" class="py-5">
          You don't have any vehicles in your fleet
        </span>
        <v-row no-gutters>
          <v-card
            flat
            v-for="(vehicle, index) in vehicleList"
            :key="'vehicle-' + index"
            :style="smAndDown ? 'width: 100%' : 'width: 50%'"
            class="pa-md-1 py-1"
          >
            <VehicleListCard
              :vehicle="vehicle"
              @click="() => emit('view-vehicle', vehicle)"
              :selected="false"
            />
          </v-card>
        </v-row>
        <OutlinedBlockBtn @click="showBookingDialog = true" class="mt-3">
          Book fleet vehicles
        </OutlinedBlockBtn>
        <GenericDialog :activator="showBookingDialog" @close="showBookingDialog = false" small>
          <v-card-title> Book fleet vehicles </v-card-title>
          <v-card-subtitle>Premium feature</v-card-subtitle>
          <v-card-text>
            Please contact PowerTrip to discuss unlocking premium features
          </v-card-text>
        </GenericDialog>
      </v-card-text>
    </v-card>
    <v-card flat class="bg-background">
      <v-card-title>
        <v-row no-gutters align="center">
          <span> Fleet locations </span>
          <v-spacer />
          <TextBtn v-if="mdAndUp" @click="() => emit('view-locations')"> View all </TextBtn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <span v-if="!fleetLocationsList.length" class="py-5">
          You don't have any saved locations
        </span>

        <v-row no-gutters>
          <v-card
            v-for="(location, index) in fleetLocationsList"
            :key="'location-' + index"
            style="width: calc(50% - 8px)"
            :style="smAndDown ? 'width: 100%' : ''"
            class="pa-5 ma-md-1 my-1 rounded-lg background"
          >
            <v-card-title> {{ location.name }} </v-card-title>
            <v-card-subtitle> {{ location.address }} </v-card-subtitle>
          </v-card>
        </v-row>
        <ElevatedBlockBtn v-if="smAndDown" class="mt-3" @click="() => emit('view-locations')">
          See all fleet locations
        </ElevatedBlockBtn>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts" setup>
import Vehicle from '@/classes/vehicle_classes/vehicle'
import FavouriteLocation from '@/classes/favouriteLocation'
import VehicleListCard from '../vehicles/VehicleListCard.vue'
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'
import OutlinedBlockBtn from '../ui-elements/buttons/OutlinedBlockBtn.vue'
import GenericDialog from '../dialog-wrappers/GenericDialog.vue'
import { GettersTypes, type State } from '@/store/store_types'
import { useStore } from 'vuex'
import { useDisplay } from 'vuetify'
import { computed, ref } from 'vue'

const emit = defineEmits<{
  (e: 'view-vehicle', vehicle: Vehicle | undefined): void
  (e: 'view-vehicles'): void
  (e: 'view-locations'): void
}>()

const store = useStore<State>()
const { smAndDown, mdAndUp } = useDisplay()

const showBookingDialog = ref(false)

const vehicleList = computed<Vehicle[]>(() => {
  const returnList: Vehicle[] = []
  const fullList = store.getters[GettersTypes.connectedVehicles] as Vehicle[]

  fullList.forEach((vehicle, index) => {
    if (index < (smAndDown ? 3 : 4)) returnList.push(vehicle)
  })

  return returnList
})
const fleetLocationsList = computed<FavouriteLocation[]>(() => {
  const unfilteredList = store.state.favLocations
  return unfilteredList.filter((location) => location.visibility !== 'private')
})
</script>
