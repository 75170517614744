<template>
  <v-card flat class="px-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title>{{ displayName }}</v-card-title>
        <v-card-subtitle>{{ makeModelDisplayName }}</v-card-subtitle>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="4">
          <v-img
            width="100%"
            height="auto"
            max-height="160"
            :lazy-src="fallbackImgSrc"
            :src="imgSrc"
            class="rounded-lg"
            contain
            aspect-ratio="1"
          ></v-img>
        </v-col>
        <v-col cols="8">
          <v-card style="min-height: 120px">
            <v-card-text>
              <span>
                {{ vehicle?.fuelType ? vehicle.fuelType : 'Electric' }}
              </span>
              <v-row
                v-if="vehicle.fuelType === 'Electric' || !vehicle?.fuelType"
                no-gutters
                justify="space-between"
              >
                <span>Charge</span>
                <span>{{ vehicle?.stateOfCharge ? Math.round(vehicle.stateOfCharge) : 100 }}%</span>
              </v-row>
              <v-slider
                v-if="vehicle?.fuelType === 'Electric'"
                readonly
                :model-value="vehicle.stateOfCharge ? Math.round(vehicle.stateOfCharge) : 100"
                min="1"
                max="100"
                track-color="grey-lighten-2"
                hide-details
                step="1"
                color="primary"
                thumb-size="16"
                track-size="6"
              ></v-slider>
              <span class="d-flex flex-column">
                <span>Last Known Location</span>
                <span>
                  <PulseLoader v-if="loading" />
                  <a v-else> {{ location }} </a>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <ElevatedBlockBtn class="mb-3" @click="handleViewBooking"> Book Vehicle </ElevatedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="handleViewSettings">
        Vehicle settings
      </OutlinedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="handleSelect" v-if="!isSelected">
        Select This Vehicle
      </OutlinedBlockBtn>
      <TextBlockBtn @click="handleSelectOther"> Select Other </TextBlockBtn>
    </v-card-text>
    <v-card-title>Connectors</v-card-title>
    <v-card-text class="mb-5">
      <v-card class="mb-8">
        <v-card-title>Compatible</v-card-title>
        <v-card-subtitle> These are the connectors compatible with this vehicle. </v-card-subtitle>
        <ConnectorDisplayRow :connectors="connectors" />
      </v-card>
      <v-card>
        <v-card-title>Your Cables</v-card-title>
        <v-card-subtitle> These are the cables you are bringing with you. </v-card-subtitle>
        <ConnectorDisplayRow :connectors="cables" />
      </v-card>
    </v-card-text>
    <!-- TODO: add an image carousel here -->
    <VehicleImageCarousel :vehicle="vehicle" />
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  type State,
} from '@/store/store_types'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import getAssetSrc from '@/utils/getAssetSrc'
import PulseLoader from '../ui-elements/loaders/PulseLoader.vue'
import ConnectorDisplayRow from './ConnectorDisplayRow.vue'
import VehicleImageCarousel from './VehicleImageCarousel.vue'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'
import OutlinedBlockBtn from '../ui-elements/buttons/OutlinedBlockBtn.vue'
import TextBlockBtn from '../ui-elements/buttons/TextBlockBtn.vue'

const store = useStore<State>()

const loading = ref(true)
const location = ref('unknown')

const vehicle = computed<Vehicle | undefined>(
  () => store.getters[GettersTypes.viewedFleetVehicleData],
)

const connectors = computed(
  () =>
    vehicle.value?.connectorsDisplayData.filter(
      (connector) => !connector.displayName.includes('cable'),
    ) ?? [],
)

const cables = computed(
  () =>
    vehicle.value?.connectorsDisplayData.filter((connector) =>
      connector.displayName.includes('cable'),
    ) ?? [],
)

const fallbackImgSrc = computed(() => getAssetSrc('car_images/No_Image_Powersell.png'))

const imgSrc = computed(() => {
  if (vehicle.value) {
    const images = vehicle.value.imageSrcPaths
    if (images.length) return images[0]
    else if (vehicle.value.evModel)
      return vehicle.value.evModel?.imageSrc ?? getAssetSrc('car_images/No_Image_Powersell.png')
  }
  return getAssetSrc('car_images/No_Image_Powersell.png')
})

const isSelected = computed(() => {
  return vehicle.value?.localId === store.state.selectedVehicle
})

const displayName = computed(() => {
  if (vehicle.value?.licensePlate)
    return store.getters[GettersTypes.viewedFleetVehicleData].licensePlate
  if (vehicle.value?.name) return store.getters[GettersTypes.viewedFleetVehicleData].name
  if (window.innerWidth < 600) return 'no license plate'
  return 'unspecified license plate'
})

const makeModelDisplayName = computed(() => {
  if (vehicle.value?.evModel) return vehicle.value.evModel.name
  return 'unknown make and model'
})

function handleSelect() {
  store.dispatch(ActionTypes.selectVehicle, store.getters[GettersTypes.viewedFleetVehicleData])
}

function handleSelectOther() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_GALLERY)
}

async function getLocation(): Promise<string> {
  return (await vehicle.value?.locationDisplayStr()) ?? 'unknown'
}

function close() {
  store.commit(MutationTypes.setMainDialogContent, undefined)
  store.commit(MutationTypes.setViewedFleetVehicle, undefined)
}

function handleViewSettings() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_SETTINGS)
}

function handleViewBooking() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.VEHICLE_BOOKING)
}

onMounted(async () => {
  location.value = await getLocation()
  loading.value = false
})
</script>

<style scoped>
* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
