<template>
  <v-dialog
    :model-value="activator"
    :width="mdAndUp ? '75%' : '100%'"
    :content-class="
      mdAndUp
        ? 'rounded-lg bg-background pwt-scrollbar-styles'
        : 'bg-background pwt-scrollbar-styles'
    "
    class="pwt-scroll-styles"
    scrollable
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
    :fullscreen="smAndDown"
    :max-height="mdAndUp ? '90vh' : undefined"
    :min-height="mdAndUp ? '90vh' : undefined"
  >
    <!-- must have vh for height to work with v-dialog having no exposed height prop. -->
    <v-card
      style="height: 80vh"
      :class="
        smAndDown ? 'bg-background overflow-hidden' : 'rounded-lg bg-background overflow-hidden'
      "
      flat
    >
      <div
        id="dashboard-scroll-container"
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto"
      >
        <!-- second card to counteract miss-behaving v-dialog overflow due to vh needed earlier. -->
        <v-card
          class="pt-5 bg-background"
          :class="mdAndUp ? 'pr-10 pl-5' : 'px-0'"
          flat
          style="position: relative"
        >
          <DashboardMobileAppBar
            v-if="smAndDown"
            @close="emitClose"
            @view-home="viewDashboard"
            @view-account="viewAccount"
            @view-vehicle="viewVehicle"
            @view-vehicles="viewAllVehicles"
            @view-defaults="viewDefaults"
            @view-locations="viewLocationsManager"
            @view-saved-trips="viewSavedTrips"
            :tab="tab"
          />
          <!-- tabs -->
          <v-tabs
            v-if="mdAndUp"
            direction="vertical"
            :model-value="tab"
            style="position: fixed; top: calc(10vh + 40px); width: 200px"
            hide-slider
            @change="resetScrollPosition"
            optional
            color="primary"
          >
            <v-tab class="text-none bg-background" tab-value="home" @click="viewDashboard">
              Home <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none bg-background" tab-value="account" @click="viewAccount">
              Account <v-spacer></v-spacer>
            </v-tab>
            <v-expansion-panels v-model="expand" accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-title class="v-tab text-none bg-background">
                  Vehicles
                </v-expansion-panel-title>
                <v-expansion-panel-text class="bg-background">
                  <v-tab
                    class="text-none"
                    :class="tab === 'vehicle-details' ? 'text-primary' : ''"
                    tab-value="vehicle-details"
                    @click="viewVehicle(undefined)"
                  >
                    Vehicle details <v-spacer></v-spacer>
                  </v-tab>
                  <v-tab
                    class="text-none"
                    :class="tab === 'fleet-vehicles' ? 'text-primary' : ''"
                    tab-value="fleet-vehicles"
                    @click="viewAllVehicles"
                  >
                    Fleet vehicles <v-spacer></v-spacer>
                  </v-tab>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-tab
              class="text-none bg-background"
              tab-value="locations-manager"
              @click="viewLocationsManager"
            >
              Locations manager
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none bg-background" tab-value="defaults" @click="viewDefaults">
              Trip planning defaults
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none bg-background" tab-value="saved-trips" @click="viewSavedTrips">
              Saved trips <v-spacer></v-spacer>
            </v-tab>
          </v-tabs>
          <v-tabs-window
            v-model="tab"
            :style="
              smAndUp
                ? 'margin-left: 200px; border-left: 2px solid rgba(218, 218, 218, 0.5) !important'
                : ''
            "
            class="bg-background"
            :class="smAndUp ? 'py-5 pl-5' : 'pa-5'"
          >
            <v-tabs-window-item value="home">
              <DashboardMain
                @view-vehicle="viewVehicle"
                @view-account="viewAccount"
                @view-trip="emitClose"
                @view-all-vehicles="viewAllVehicles"
                @view-locations="viewLocationsManager"
                @view-defaults="viewDefaults"
                @view-saved-trips="viewSavedTrips"
                @logout="emitClose"
              />
            </v-tabs-window-item>
            <v-tabs-window-item value="account">
              <DashboardBreadcrumbs
                v-if="mdAndUp"
                :items="[{ text: 'Home' }, { text: 'Manage account', disabled: true }]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <EditUserContent />
            </v-tabs-window-item>
            <v-tabs-window-item value="vehicle-details">
              <DashboardBreadcrumbs
                v-if="mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Fleet vehicles' },
                  { text: 'Vehicle details', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Fleet vehicles' })"
              />
              <VehicleDetails :vehicle="vehicleData" :isSelected="isSelected" />
            </v-tabs-window-item>
            <v-tabs-window-item value="fleet-vehicles">
              <DashboardBreadcrumbs
                v-if="mdAndUp"
                :items="[{ text: 'Home' }, { text: 'Fleet vehicles', disabled: true }]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <VehicleList @view-vehicle="viewVehicle($event.localId)" />
            </v-tabs-window-item>
            <v-tabs-window-item value="locations-manager">
              <DashboardBreadcrumbs
                v-if="mdAndUp"
                :items="[{ text: 'Home' }, { text: 'Location manager', disabled: true }]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <FavLocationsMain />
            </v-tabs-window-item>
            <v-tabs-window-item value="defaults">
              <DashboardBreadcrumbs
                v-if="mdAndUp"
                :items="[{ text: 'Home' }, { text: 'Edit trip planning presets', disabled: true }]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <TripPlanningDefaults />
            </v-tabs-window-item>
            <v-tabs-window-item value="saved-trips">
              <SavedTripsContent @close="emitClose" />
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card>
      </div>
      <!-- floating close button -->
      <v-btn
        v-if="mdAndUp"
        icon
        @click.stop="emitClose()"
        style="position: absolute; top: 8px; right: 12px"
        flat
        color="transparent"
      >
        <v-icon color="grey-lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import FavLocationsMain from '../favourite-locations/FavLocationsMain.vue'
import TripPlanningDefaults from './TripPlanningDefaults.vue'
import VehicleDetails from '../vehicles/VehicleDetails.vue'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import {
  GettersTypes,
  MutationTypes,
  type State,
  type DashboardDialogContent,
} from '@/store/store_types'
import VehicleList from '../vehicles/VehicleList.vue'
import DashboardMain from './DashboardMain.vue'
import DashboardBreadcrumbs, { type BreadcrumbsItem } from './DashboardBreadcrumbs.vue'
import DashboardMobileAppBar from './DashboardMobileAppBar.vue'
import EditUserContent from '@/components/user/profile/EditUserContent.vue'
import SavedTripsContent from './SavedTripsContent.vue'
import { useStore } from 'vuex'
import { computed, nextTick, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'

export type tabs =
  | 'home'
  | 'account'
  | 'vehicle-details'
  | 'fleet-vehicles'
  | 'locations-manager'
  | 'saved-trips'
  | 'defaults'

const { activator } = defineProps<{ activator: boolean }>()
const emit = defineEmits<{
  (e: 'close'): void
}>()
const { smAndDown, smAndUp, mdAndUp } = useDisplay()

// state
const store = useStore<State>()
const vehicleId = ref<string | undefined>(undefined)
const expand = ref<number | undefined>(undefined)
// const showMenu = ref<boolean>(false)
const tab = computed(() => store.state.dashboardDialogContent)
const vehicleData = computed<Vehicle | undefined>(
  () => store.getters[GettersTypes.selectedVehicleData],
)
const isSelected = computed<boolean>(
  () => vehicleData.value?.localId === store.state.selectedVehicle,
)

//  general methods
function emitClose() {
  emit('close')
}

function keyDown(event: KeyboardEvent) {
  if (event.key === 'Escape') emitClose()
}

function resetScrollPosition() {
  nextTick(() => {
    const myDiv = document.getElementById('dashboard-scroll-container')
    if (myDiv) myDiv.scrollTop = 0
  })
}

// navigation methods

function viewVehicle(id: string | undefined) {
  vehicleId.value = id
  expand.value = 0
  store.commit(MutationTypes.setDashboardDialogContent, 'vehicle-details')
  resetScrollPosition()
}

function viewAllVehicles() {
  store.commit(MutationTypes.setDashboardDialogContent, 'fleet-vehicles')
  expand.value = 0
  resetScrollPosition()
}

function viewAccount() {
  store.commit(MutationTypes.setDashboardDialogContent, 'account')
  resetScrollPosition()
}

function viewLocationsManager() {
  store.commit(MutationTypes.setDashboardDialogContent, 'locations-manager')
  resetScrollPosition()
}

function viewDashboard() {
  store.commit(MutationTypes.setDashboardDialogContent, 'home')
  resetScrollPosition()
}

function viewDefaults() {
  store.commit(MutationTypes.setDashboardDialogContent, 'defaults')
  resetScrollPosition()
}

function viewSavedTrips() {
  store.commit(MutationTypes.setDashboardDialogContent, 'saved-trips')
  resetScrollPosition()
}

function breadcrumbNavigation(item: BreadcrumbsItem) {
  if (item.disabled) return
  if (item.text === 'Home') {
    viewDashboard()
  } else if (item.text === 'Fleet vehicles') {
    viewAllVehicles()
  } else if (item.text === 'Edit trip planning presets') {
    viewDefaults()
  } else if (item.text === 'Manage account') {
    viewAccount()
  } else if (item.text === 'Location manager') {
    viewLocationsManager()
  } else if (item.text === 'Vehicle details') {
    viewVehicle(undefined)
  } else if (item.text === 'Saved trips') {
    viewSavedTrips()
  }
}

// watchers

watch(tab, (value: DashboardDialogContent | undefined) => {
  if (value === 'vehicle-details' || value === 'fleet-vehicles') {
    expand.value = 0
  } else {
    expand.value = undefined
  }
})

watch(
  () => activator,
  () => {
    resetScrollPosition()
  },
)
</script>
<style scoped>
* :deep(.v-slide-group__prev) {
  flex: unset !important;
  min-width: 0px !important;
}

* :deep(.v-slide-group) {
  background-color: var(--v-background-base) !important;
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
