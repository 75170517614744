<template>
  <v-card flat>
    <v-img
      contain
      :height="size"
      :width="size"
      :max-height="size"
      :max-width="size"
      :src="getSrc('Loading-Blue.gif')"
    ></v-img>
  </v-card>
</template>
<script setup lang="ts">
import getAssetSrc from '@/utils/getAssetSrc'

const { size = 150 } = defineProps<{ size?: number }>()

function getSrc(partialFilePath: string): string {
  return getAssetSrc(partialFilePath)
}
</script>
