import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { ALL_EV_MODELS_ENDPOINT } from '@/api/clients/charger-database-client'

/**
 * Fetches a list of all EV models from the charger database.
 *
 * @returns A list of all EV models from the charger database.
 */
export default async function fetchEVModels() {
  const requestOptions = {
    method: 'GET',
  }

  const response = await fetch(ALL_EV_MODELS_ENDPOINT, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => notifyAnalytics({ type: 'log_error', data: error }))

  return response
}
