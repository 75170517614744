<template>
  <div style="height: 600px; width: 100%; border-radius: 10px">
    <VueCal
      ref="calendar"
      clickToNavigate
      :disable-views="['years', 'year']"
      :editable-events="{ title: false, drag: true, resize: true, delete: true, create: true }"
      :snapToTime="15"
      :events="events"
      small
      @event-drag-create="addNewEvent"
      @event-delete="deleteEvent"
      @event-change="updateEvent"
      @event-click="handleShowEventDetails"
      @view-change="handleViewChange"
      @cell-click="handleCellClick"
    />
    <v-dialog v-model="addNewEventDialog" @after-leave="cleanUpNewEvent" max-width="600">
      <v-card class="rounded-lg">
        <v-card-title class="bg-primary text-center"> Add new booking </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="pr-2">
              <v-card>
                <v-card-title> from </v-card-title>
                <v-card-text>
                  <DatePickerInput
                    identifier="start date"
                    label="Date"
                    :initial-value="newEventStartDate"
                    @update="handleDateUpdate"
                  />
                  <TimePickerInput
                    label="Time"
                    identifier="from"
                    :initial-value="newEventStartTime"
                    @update="handleTImeUpdate"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-card>
                <v-card-title> to </v-card-title>
                <v-card-text>
                  <DatePickerInput
                    identifier="end date"
                    label="Date"
                    :initial-value="newEventEndDate"
                    @update="handleDateUpdate"
                  />
                  <TimePickerInput
                    label="Time"
                    identifier="to"
                    :initial-value="newEventEndTime"
                    @update="handleTImeUpdate"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ElevatedBtn color="primary" :disabled="disallowBooking" @click="handleBookClick">
            Book
          </ElevatedBtn>
          <TextBtn @click="addNewEventDialog = false"> Cancel </TextBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEventDetails" @after-leave="cleanUpEventDetails" max-width="600">
      <v-card v-if="showEditEventDialog" class="rounded-lg">
        <v-card-title class="bg-primary text-center"> Editing booking </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="pr-2">
              <v-card>
                <v-card-title> from </v-card-title>
                <v-card-text>
                  <DatePickerInput
                    identifier="editing start date"
                    label="Date"
                    :initial-value="editEventStartDate"
                    @update="handleDateUpdate"
                  />
                  <TimePickerInput
                    label="Time"
                    identifier="editing from"
                    :initial-value="editEventStartTime"
                    @update="handleTImeUpdate"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-card>
                <v-card-title> to </v-card-title>
                <v-card-text>
                  <DatePickerInput
                    identifier="editing end date"
                    label="Date"
                    :initial-value="editEventEndDate"
                    @update="handleDateUpdate"
                  />
                  <TimePickerInput
                    label="Time"
                    identifier="editing to"
                    :initial-value="editEventEndTime"
                    @update="handleTImeUpdate"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <ElevatedBtn color="primary" @click="saveEditedEvent" :disabled="disallowEditing">
            Save
          </ElevatedBtn>
          <TextBtn @click="cleanUpEditEvent"> Cancel </TextBtn>
        </v-card-actions>
      </v-card>
      <v-card v-else class="rounded-lg">
        <v-card-title class="bg-primary text-center"> Booking details </v-card-title>
        <v-card-text>
          <v-row no-gutters> {{ showingEvent?.title }} </v-row>
          <v-row no-gutters>
            {{ showingEvent?.start.toLocaleString() }} - {{ showingEvent?.end.toLocaleString() }}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <ElevatedBtn color="primary" :disabled="!showingEvent?.resizable" @click="editEventClick">
            Edit details
          </ElevatedBtn>
          <TextBtn
            color="error"
            @click="handleDeleteShowingEvent"
            :disabled="!showingEvent?.deletable"
          >
            Delete
          </TextBtn>
          <TextBtn @click="showEventDetails = false"> Close </TextBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import {
  createAssetBooking,
  deleteAssetBooking,
  fetchAssetBookingsByAssetID,
  updateAssetBooking,
  type DirectusAssetBooking,
} from '@/api/calls/directus-calls/asset-bookings-calls'
import type { State } from '@/store/store_types'
import type { VueCalEvent, VueCalViewChangeEvent, VueClaViews } from '@/types/booking_types'
import generateUniqueLocalID from '@/utils/generateUniqueLocalID'
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from 'vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useStore } from 'vuex'
import DatePickerInput, {
  type DatePickerInputUpdateObj,
} from '../ui-elements/inputs/DatePickerInput.vue'
import TimePickerInput, {
  type TimePickerInputUpdateObj,
} from '../ui-elements/inputs/TimePickerInput.vue'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import TextBtn from '../ui-elements/buttons/TextBtn.vue'
import { DateTime } from 'luxon'

const { assetId, assetType } = defineProps<{
  assetId: string | number
  assetType: 'vehicle' | 'charger'
}>()

const store = useStore<State>()
const userId = computed(() => store.state.user?.directusId)
const events = ref<VueCalEvent[]>([])
let usedIds: string[] = []
const calendar = useTemplateRef<VueCal>('calendar')

// navigation related

const currentView = ref<VueClaViews>('week')

function handleViewChange(event: VueCalViewChangeEvent) {
  currentView.value = event.view
}

// new event related

const addNewEventDialog = ref(false)
const newEventStartDate = ref<Date | null>(null)
const newEventStartTime = ref<string | null>(null)
const newEventEndDate = ref<Date | null>(null)
const newEventEndTime = ref<string | null>(null)
const disallowBooking = computed<boolean>(() => {
  if (!newEventStartDate.value || !newEventStartTime.value || !newEventEndTime.value) return true
  if (!userId.value) return true
  if (!assetId) return true
  if (newEventStartTime.value === '' || newEventEndTime.value === '') return true
  return false
})

function handleCellClick(dateValue: Date) {
  if (currentView.value !== 'day') return // assume other clicks are for navigation to the day view
  newEventStartDate.value = dateValue
  newEventStartTime.value = DateTime.fromJSDate(dateValue).toFormat('HH:mm')
  newEventEndTime.value = DateTime.fromJSDate(dateValue).plus({ hours: 1 }).toFormat('HH:mm')
  newEventEndDate.value = DateTime.fromJSDate(dateValue).plus({ hours: 1 }).toJSDate()
  addNewEventDialog.value = true
}

function handleDateUpdate(value: DatePickerInputUpdateObj) {
  if (value.identifier === 'start date') newEventStartDate.value = value.date
  if (value.identifier === 'end date') newEventEndDate.value = value.date
  if (value.identifier === 'editing start date') editEventStartDate.value = value.date
  if (value.identifier === 'editing end date') editEventEndDate.value = value.date
}

function handleTImeUpdate(value: TimePickerInputUpdateObj) {
  if (value.identifier === 'from') newEventStartTime.value = value.time
  if (value.identifier === 'to') newEventEndTime.value = value.time
  if (value.identifier === 'editing from') editEventStartTime.value = value.time
  if (value.identifier === 'editing to') editEventEndTime.value = value.time
}

function handleBookClick() {
  if (
    !newEventStartDate.value ||
    !newEventStartTime.value ||
    !newEventEndTime.value ||
    !newEventEndDate.value
  )
    return
  const start = DateTime.fromFormat(
    `${DateTime.fromJSDate(newEventStartDate.value).toFormat('yyyy-MM-dd')} ${newEventStartTime.value}`,
    'yyyy-MM-dd HH:mm',
  )
  const end = DateTime.fromFormat(
    `${DateTime.fromJSDate(newEventEndDate.value).toFormat('yyyy-MM-dd')} ${newEventEndTime.value}`,
    'yyyy-MM-dd HH:mm',
  )

  const event = calendar.value?.createEvent(start.toJSDate(), end.diff(start, 'minutes').minutes)

  addNewEvent(event)
  cleanUpNewEvent()
}

function cleanUpNewEvent() {
  addNewEventDialog.value = false
  newEventStartDate.value = null
  newEventStartTime.value = null
  newEventEndTime.value = null
  newEventEndDate.value = null
}

async function addNewEvent(event: VueCalEvent) {
  const generatedId = generateUniqueLocalID(usedIds, 'booking')
  usedIds.push(generatedId)
  const overlaps = doesEventOverlapExistingEvents(event, events.value)
  events.value.push({
    ...event,
    title: overlaps ? 'Error Overlapping' : 'My Booking',
    content: overlaps ? 'Not saved because of overlapping another booking' : 'This is my booking',
    deletable: true,
    resizable: true,
    class: overlaps ? 'overlap-event' : 'my-event',
    internalId: generatedId,
    externalId: overlaps ? undefined : await createOrUpdateEvent(event),
  })
}

// event removal related

async function deleteEvent(event: VueCalEvent) {
  // remove from local state
  events.value = events.value.filter((e) => e.internalId !== event.internalId)
  // recycle id
  usedIds = usedIds.filter((id) => id !== event.internalId)
  // remove from backend
  if (!event.externalId) return
  await deleteAssetBooking(event.externalId)
}

function handleDeleteShowingEvent() {
  if (!showingEvent.value) return
  deleteEvent(showingEvent.value)
  cleanUpEventDetails()
}

// event details display related

const showEventDetails = ref(false)
const showingEvent = ref<VueCalEvent | null>(null)

function handleShowEventDetails(event: VueCalEvent) {
  showingEvent.value = event
  showEventDetails.value = true
}

function cleanUpEventDetails() {
  showEventDetails.value = false
  showingEvent.value = null
  cleanUpEditEvent()
}

// editing event related

const editEvent = ref<VueCalEvent | null>(null)
const showEditEventDialog = ref(false)
const editEventStartTime = ref<string | null>(null)
const editEventEndTime = ref<string | null>(null)
const editEventEndDate = ref<Date | null>(null)
const editEventStartDate = ref<Date | null>(null)
const disallowEditing = ref(true)

watch(editEvent, () => {
  checkIfSavable()
})
watch(editEventStartTime, () => {
  checkIfSavable()
})
watch(editEventEndTime, () => {
  checkIfSavable()
})
watch(editEventStartDate, () => {
  checkIfSavable()
})
watch(editEventEndDate, () => {
  checkIfSavable()
})

function checkIfSavable() {
  // check if valid
  if (
    !editEventStartDate.value ||
    !editEventStartTime.value ||
    !editEventEndTime.value ||
    !editEventEndDate.value ||
    !editEvent.value
  ) {
    disallowEditing.value = true
    return
  }

  // check if has changed
  let hasChanged = false

  // check if start date has changed
  const oldStart =
    editEvent.value?.start instanceof Date
      ? DateTime.fromJSDate(editEvent.value.start)
      : DateTime.fromISO(editEvent.value.start)
  const newStart = editEventStartDate.value ? DateTime.fromJSDate(editEventStartDate.value) : null
  if (newStart && !oldStart.equals(newStart)) hasChanged = true

  // check if end date has changed
  const oldEnd =
    editEvent.value?.end instanceof Date
      ? DateTime.fromJSDate(editEvent.value.end)
      : DateTime.fromISO(editEvent.value.end)
  const newEnd = editEventEndDate.value ? DateTime.fromJSDate(editEventEndDate.value) : null
  if (newEnd && !oldEnd.equals(newEnd)) hasChanged = true

  // check if start time has changed
  const oldStartTime = oldStart.toFormat('HH:mm')
  if (oldStartTime !== editEventStartTime.value) hasChanged = true

  // check if end time has changed
  const oldEndTime = oldEnd.toFormat('HH:mm')
  if (oldEndTime !== editEventEndTime.value) hasChanged = true

  disallowEditing.value = !hasChanged
}

function editEventClick() {
  if (!showingEvent.value) return
  const start =
    showingEvent.value.start instanceof Date
      ? showingEvent.value.start
      : new Date(showingEvent.value.start)
  const end =
    showingEvent.value.end instanceof Date
      ? showingEvent.value.end
      : new Date(showingEvent.value.end)

  editEvent.value = showingEvent.value
  editEventStartTime.value = DateTime.fromJSDate(start).toFormat('HH:mm')
  editEventEndTime.value = DateTime.fromJSDate(end).toFormat('HH:mm')
  editEventEndDate.value = end
  editEventStartDate.value = start
  showEditEventDialog.value = true
}

function saveEditedEvent() {
  if (
    !editEvent.value ||
    !editEventStartDate.value ||
    !editEventEndDate.value ||
    !editEventStartTime.value ||
    !editEventEndTime.value
  )
    return
  const start = DateTime.fromFormat(
    `${DateTime.fromJSDate(editEventStartDate.value).toFormat('yyyy-MM-dd')} ${editEventStartTime.value}`,
    'yyyy-MM-dd HH:mm',
  )
  const end = DateTime.fromFormat(
    `${DateTime.fromJSDate(editEventEndDate.value).toFormat('yyyy-MM-dd')} ${editEventEndTime.value}`,
    'yyyy-MM-dd HH:mm',
  )
  const updatedEvent = { ...editEvent.value, start: start.toJSDate(), end: end.toJSDate() }
  updateEvent({ event: updatedEvent, originalEvent: editEvent.value })
  handleShowEventDetails(updatedEvent)
  cleanUpEditEvent()
}

function cleanUpEditEvent() {
  showEditEventDialog.value = false
  editEvent.value = null
  editEventStartTime.value = null
  editEventEndTime.value = null
  editEventEndDate.value = null
  editEventStartDate.value = null
}

async function updateEvent({
  event,
  originalEvent,
}: {
  event: VueCalEvent
  originalEvent: null | VueCalEvent
}) {
  if (!originalEvent) return // Assume new event set by new event handler
  // copy events
  let eventsCopy = [...events.value]
  // check if is a manipulatable event
  if (event.title === 'Error Overlapping' || event.title === 'My Booking') {
    // check if overlapping status has changed
    if (event.title === 'Error Overlapping' && !doesEventOverlapExistingEvents(event, eventsCopy)) {
      // update event to reflect changed overlapping status
      event.title = 'My Booking'
      event.content = 'This is my booking'
      event.class = 'my-event'
      event.externalId = await createOrUpdateEvent(event)
    } else if (event.title === 'My Booking' && doesEventOverlapExistingEvents(event, eventsCopy)) {
      // update event
      event.title = 'Error Overlapping'
      event.content = 'Not saved because of overlapping another booking'
      event.class = 'overlap-event'
    } else {
      event.externalId = await createOrUpdateEvent(event)
    }
    // update events list
    eventsCopy = eventsCopy.map((e) => {
      if (e.internalId === event.internalId) {
        return event
      }
      return e
    })

    // update other events that may have been affected by the change
    eventsCopy = await Promise.all(
      eventsCopy.map(async (e) => {
        if (e.title === 'Error Overlapping' && !doesEventOverlapExistingEvents(e, eventsCopy)) {
          e.title = 'My Booking'
          e.content = 'This is my booking'
          e.class = 'my-event'
          e.externalId = await createOrUpdateEvent(event)
        }
        return e
      }),
    )
  }
  // update events
  events.value = eventsCopy
}

// event helpers

function doesEventOverlapExistingEvents(newEvent: VueCalEvent, events: VueCalEvent[]): boolean {
  return events.some((existingEvent) => {
    if (existingEvent.internalId === newEvent.internalId) {
      return false
    }
    const newEventStart = newEvent.start instanceof Date ? newEvent.start : new Date(newEvent.start)
    const newEventEnd = newEvent.end instanceof Date ? newEvent.end : new Date(newEvent.end)
    const existingEventStart =
      existingEvent.start instanceof Date ? existingEvent.start : new Date(existingEvent.start)
    const existingEventEnd =
      existingEvent.end instanceof Date ? existingEvent.end : new Date(existingEvent.end)
    return newEventStart < existingEventEnd && newEventEnd > existingEventStart
  })
}

// data fetching

async function fetchData(passedAssetId: string | number) {
  const directusFormatEvents = await fetchAssetBookingsByAssetID(passedAssetId)
  directusFormatEvents?.forEach((event) => {
    const generatedId = generateUniqueLocalID(usedIds, 'booking')
    usedIds.push(generatedId)
    if (event.user_id === userId.value) {
      events.value.push({
        start: new Date(event.start),
        end: new Date(event.end),
        title: 'My Booking',
        content: 'This is my booking',
        class: 'my-event',
        deletable: true,
        resizable: true,
        internalId: generatedId,
        externalId: event.id,
      })
    } else {
      events.value.push({
        start: new Date(event.start),
        end: new Date(event.end),
        title: 'Booked',
        class: 'other-event',
        deletable: false,
        resizable: false,
        internalId: generatedId,
        externalId: event.id,
      })
    }
  })
}

async function createOrUpdateEvent(event: VueCalEvent): Promise<string | undefined> {
  let res: DirectusAssetBooking | undefined
  if (event.externalId) {
    res = await updateAssetBooking(event.externalId, {
      start: event.start instanceof Date ? event.start.toISOString() : event.start,
      end: event.end instanceof Date ? event.end.toISOString() : event.end,
    })
  } else {
    if (!userId.value) return
    res = await createAssetBooking({
      user_id: userId.value,
      asset_id: typeof assetId === 'number' ? assetId.toString() : assetId,
      start: event.start instanceof Date ? event.start.toISOString() : event.start,
      end: event.end instanceof Date ? event.end.toISOString() : event.end,
      asset_type: assetType,
    })
  }
  if (res) return res.id
}

onMounted(() => {
  nextTick(() => {
    fetchData(assetId)
  })
})

watch(
  () => assetId,
  () => {
    fetchData(assetId)
  },
)
</script>

<style>
.my-event {
  background-color: rgb(var(--v-theme-primary));
}
.other-event {
  background-color: rgb(var(--v-theme-secondary));
}
.overlap-event {
  background-color: rgb(var(--v-theme-error));
}

.vuecal__menu {
  border-radius: 10px 10px 0 0;
}

.vuecal__menu,
.vuecal__cell-events-count {
  background-color: rgb(var(--v-theme-primary));
  color: #fff;
}
.vuecal__title-bar {
  background-color: rgb(var(--v-theme-primary), 0.4);
}
.vuecal__cell--today,
.vuecal__cell--current {
  background-color: rgba(var(--v-theme-primary), 0.2);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
  border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(136, 236, 191, 0.25);
}
</style>
