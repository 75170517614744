import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus from '@/api/clients/directus-client'
import type {
  DirectusCompany,
  DirectusCompanyDataRes,
  DirectusScoringConfig,
} from '@/types/directus-types'

/** API Call to fetch the company details from the DB.
 *
 * @returns a user `CompanyData` object if successful. Undefined if not.
 */
export async function fetchFleetData(): Promise<DirectusCompany | undefined> {
  try {
    const res = await directus.items('Company').readByQuery({
      fields: ['Name', 'Logo', 'ManualVehicleMatching'],
    })

    return (res as unknown as DirectusCompanyDataRes | undefined)?.data
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/** API Call to fetch the config details from the DB.
 *
 * @returns a user `DirectusFleetConfig` object if successful. Undefined if not.
 */
export async function fetchFleetConfigData(): Promise<DirectusScoringConfig | undefined> {
  try {
    const res = await directus.items('ScoringConfig').readByQuery({
      fields: ['dcCostPerKwh', 'acCostPerKwh', 'petrolCostPerLitre', 'dieselCostPerLitre'],
    })

    return res as DirectusScoringConfig | undefined
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}
