<template>
  <v-card flat class="pb-5 bg-background" v-if="savingStatus === 'PROCESSING'">
    <DescriptiveLoader message="Power Trip is saving your data" />
  </v-card>
  <v-card flat class="pb-5 bg-background" v-else-if="savingStatus === 'FAILED'">
    <v-alert type="error" class="mx-2">
      Settings where updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
  <v-card flat class="pb-5 bg-background" v-else-if="savingStatus === 'SUCCESS'">
    <v-alert type="success" class="mx-2">
      Settings updated successfully and saved to the database
    </v-alert>
  </v-card>
  <v-card flat class="pb-5 bg-background" v-else style="position: relative">
    <v-row no-gutters class="flex-column flex-md-row pt-10 px-4 bg-background">
      <v-card
        class="rounded-lg px-4 mb-3 mr-2 bg-background"
        :width="mdAndUp ? 'calc(50% - 4px)' : undefined"
      >
        <v-card-title>
          <v-row no-gutters align="center">
            Departing charge
            <v-spacer></v-spacer>
            <StyledToolTip v-if="departingChargeTooltip" :data="departingChargeTooltip" />
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-slider
            :label="startingCharge + '%'"
            v-model="startingCharge"
            min="1"
            max="100"
            step="1"
            track-color="grey-lighten-2"
            @end="flagAsDirty"
            color="primary"
            thumb-size="16"
            track-size="6"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="rounded-lg px-4 mb-3 bg-background"
        :width="mdAndUp ? 'calc(50% - 4px)' : undefined"
      >
        <v-card-title>
          <v-row no-gutters align="center">
            Arrival charge
            <v-spacer></v-spacer>
            <StyledToolTip v-if="arrivalChargeTooltip" :data="arrivalChargeTooltip" />
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-slider
            :label="endingCharge + '%'"
            v-model="endingCharge"
            min="1"
            max="100"
            step="1"
            track-color="grey-lighten-2"
            @end="flagAsDirty"
            color="primary"
            thumb-size="16"
            track-size="6"
          />
        </v-card-text>
      </v-card>
    </v-row>

    <v-card-title> Extra details </v-card-title>

    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title>
        <v-row no-gutters align="center">
          Speed adjustment
          <v-spacer></v-spacer>
          <StyledToolTip v-if="speedAdjustmentTooltip" :data="speedAdjustmentTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center align-center bg-grey-lighten-3 rounded-lg pa-3">
          {{
            relativeSpeed < 0
              ? `${Math.round(relativeSpeed).toString().replace('-', '')}% slower`
              : `${Math.round(relativeSpeed)}% faster`
          }}
        </div>
      </v-card-text>
      <v-slider
        min="-50"
        max="50"
        class="mx-2"
        color="primary"
        track-color="grey-lighten-2"
        v-model="relativeSpeed"
        @update:model-value="flagAsDirty"
        thumb-size="16"
        track-size="6"
      ></v-slider>

      <v-card-title>
        <v-row no-gutters align="center">
          Acceleration adjustment
          <v-spacer></v-spacer>
          <StyledToolTip
            v-if="accelerationAdjustmentTooltip"
            :data="accelerationAdjustmentTooltip"
          />
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center align-center bg-grey-lighten-3 rounded-lg pa-3">
          {{
            accelerationAdjustment < 0.5
              ? `${Math.round(((accelerationAdjustment - 0.5) / 1) * 100)
                  .toString()
                  .replace('-', '')}% slower`
              : accelerationAdjustment > 0.5
                ? `${Math.round(((0.5 - accelerationAdjustment) / 1) * 100)
                    .toString()
                    .replace('-', '')}% faster`
                : 'average acceleration'
          }}
        </div>
      </v-card-text>
      <v-slider
        min="0.0"
        max="1.0"
        step="0.1"
        class="mx-2"
        color="primary"
        track-color="grey-lighten-2"
        v-model="accelerationAdjustment"
        @update:model-value="flagAsDirty"
        thumb-size="16"
        track-size="6"
      ></v-slider>

      <!-- charging range section -->
      <v-card-title>
        <v-row no-gutters align="center">
          Charging Range
          <v-spacer></v-spacer>
          <StyledToolTip v-if="chargingRangeTooltip" :data="chargingRangeTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Minimum Charge"
              v-model="displayRange[0]"
              suffix="%"
              type="number"
              hide-spin-buttons
              :rules="[validateRange]"
              @update:model-value="flagAsDirty"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Maximum Charge"
              v-model="displayRange[1]"
              suffix="%"
              type="number"
              hide-spin-buttons
              :rules="[validateRange]"
              @update:model-value="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-range-slider
        @end="flagAsDirty"
        v-model="displayRange"
        color="primary"
        track-color="grey-lighten-2"
        class="px-2 pt-0"
        min="1"
        max="100"
        step="1"
        hide-details
        thumb-size="16"
        track-size="6"
      ></v-range-slider>
      <!-- Extra Weight -->
      <v-row class="flex-column flex-md-row" no-gutters>
        <v-card class="pt-0 pt-md-5" flat :width="mdAndUp ? '50%' : undefined">
          <v-card-title>
            <v-row no-gutters align="center">
              Extra Weight
              <v-spacer></v-spacer>
              <StyledToolTip v-if="extraWeightTooltip" :data="extraWeightTooltip" />
            </v-row>
          </v-card-title>
          <BatteryLikeInput
            :passedValue="extraWeight"
            wholeNumber
            @update="
              ($event) => {
                extraWeight = $event
                flagAsDirty
              }
            "
            class="px-4"
          />
        </v-card>
        <v-card flat class="pt-0 pt-md-5" :width="mdAndUp ? '50%' : undefined">
          <v-card-title>
            <v-row no-gutters align="center">
              Passengers
              <v-spacer></v-spacer>
              <StyledToolTip v-if="passengersTooltip" :data="passengersTooltip" />
            </v-row>
          </v-card-title>
          <BatteryLikeInput
            :passedValue="passengers"
            wholeNumber
            @update="
              ($event) => {
                passengers = $event
                flagAsDirty
              }
            "
            class="px-4"
          />
        </v-card>
      </v-row>
    </v-card>
    <!-- savings default settings section -->
    <v-card-title> Savings settings </v-card-title>

    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title> Compare EV savings against which type of vehicle </v-card-title>
      <v-card-text class="pt-0">
        <v-radio-group v-model="calcVs" @change="flagAsDirty" hide-details dense mandatory>
          <v-radio
            label="Compare against a petrol vehicle"
            value="PETROL"
            color="primary"
          ></v-radio>
          <v-radio
            label="Compare against a diesel vehicle"
            value="DIESEL"
            color="primary"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title class="mb-5">
        <v-row no-gutters align="center">
          Petrol vehicle default settings
          <v-spacer></v-spacer>
          <StyledToolTip v-if="petrolVehicleDefaultsTooltip" :data="petrolVehicleDefaultsTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              v-model="petrolCostPerLitre"
              hide-spin-buttons
              filled
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/L"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Cost of petrol per litre' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0"> Cost of petrol per litre </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="petrolKmPerLitre"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              suffix="km/L"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'km / litre if you drove a petrol car instead' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              km / litre if you drove a petrol car instead
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="petrolRoadUserCharges"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/1000 km"
              :rules="[validateIsNumber]"
              :label="
                smAndDown
                  ? 'Road User Charges in $ per thousand km in a petrol vehicle.'
                  : undefined
              "
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Road User Charges in $ per thousand km in a petrol vehicle.
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title class="mb-5">
        <v-row no-gutters align="center">
          Diesel vehicle default settings
          <v-spacer></v-spacer>
          <StyledToolTip v-if="dieselVehicleDefaultsTooltip" :data="dieselVehicleDefaultsTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="dieselCostPerLitre"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/L"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Cost of diesel per litre' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0"> Cost of diesel per litre </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="dieselKmPerLitre"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              suffix="km/L"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'km / litre if you drove a diesel car instead' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              km / litre if you drove a diesel car instead
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="dieselRoadUserCharges"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/1000 km"
              :rules="[validateIsNumber]"
              :label="
                smAndDown
                  ? 'Road User Charges in $ per thousand km in a diesel vehicle.'
                  : undefined
              "
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Road User Charges in $ per thousand km in a diesel vehicle.
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title class="mb-5">
        <v-row no-gutters align="center">
          Electric vehicle default settings
          <v-spacer></v-spacer>
          <StyledToolTip
            v-if="electricVehicleDefaultsTooltip"
            :data="electricVehicleDefaultsTooltip"
          />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="kWhCostHome"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/kWh"
              :rules="[validateIsNumber]"
              :label="
                smAndDown
                  ? 'Cost per kWh of electricity for private charging (e.g. at home)'
                  : undefined
              "
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Cost per kWh of electricity for private charging (e.g. at home)
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="kWhCostPublic"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/kWh"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Cost per kWh of electricity in public' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Cost per kWh of electricity in public
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="costPublicPerMin"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/min"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Cost per minute of electricity in public' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Cost per minute of electricity in public
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="electricRoadUserCharges"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="/1000 km"
              :rules="[validateIsNumber]"
              :label="
                smAndDown
                  ? 'Road User Charges in $ per thousand km in an electric vehicle.'
                  : undefined
              "
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Road User Charges in $ per thousand km in an electric vehicle.
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="pa-5 mb-5 rounded-lg mx-4 bg-background">
      <v-card-title class="mb-5">
        <v-row no-gutters align="center">
          Maintenance default settings
          <v-spacer></v-spacer>
          <StyledToolTip v-if="maintenanceDefaultsTooltip" :data="maintenanceDefaultsTooltip" />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="perAnnumMaintenanceCurrentVehicle"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="pa"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Annual maintenance on your current car' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Annual maintenance on your current car
            </v-card-text>
          </v-col>
        </v-row>
        <v-row align-content="center">
          <v-col md="5">
            <v-text-field
              type="number"
              filled
              v-model="perAnnumMaintenanceEV"
              hide-spin-buttons
              dense
              class="pwt-number-font"
              @update:model-value="flagAsDirty"
              prefix="$"
              suffix="pa"
              :rules="[validateIsNumber]"
              :label="smAndDown ? 'Annual maintenance on an electric car' : undefined"
            />
          </v-col>
          <v-col v-if="mdAndUp">
            <v-card-text class="py-0 py-sm-2 px-0">
              Annual maintenance on an electric car
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- FAB -->
    <div
      class="bg-background"
      :class="mdAndUp ? 'rounded-pill' : 'rounded-lg'"
      :style="
        mdAndUp
          ? 'position: fixed; bottom: 10px; right: 10px'
          : 'position: fixed; bottom: 0px; right: 0px; width: calc(100vw - 60px); margin: 0 30px 10px'
      "
    >
      <ElevatedBtn v-if="mdAndUp" @click="handleSave" :disabled="!dirty"> Save </ElevatedBtn>
      <ElevatedBlockBtn v-else @click="handleSave" :disabled="!dirty"> Save </ElevatedBlockBtn>
    </div>
  </v-card>
</template>
<script lang="ts" setup>
import { ActionTypes, type BatchUpdatableSettings, type State } from '@/store/store_types'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import to2DP from '@/utils/to2DP'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'
import DescriptiveLoader from '../ui-elements/loaders/DescriptiveLoader.vue'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'
import StyledToolTip from '../ui-elements/displays/StyledToolTip.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import { nextTick } from 'vue'
import { useDisplay } from 'vuetify'
import BatteryLikeInput from '../ui-elements/inputs/BatteryLikeInput.vue'

const { smAndDown, mdAndUp } = useDisplay()

const store = useStore<State>()

const loading = ref(true)
const dirty = ref(false)
const displayRange = ref([10, 90])
const startingCharge = ref(0)
const endingCharge = ref(0)
const passengers = ref(0)
const extraWeight = ref(0)
const relativeSpeed = ref(100)
const accelerationAdjustment = ref(0.5)
const calcVs = ref<'PETROL' | 'DIESEL'>('PETROL')
const petrolCostPerLitre = ref(0)
const petrolKmPerLitre = ref(0)
const petrolRoadUserCharges = ref(0)
const dieselCostPerLitre = ref(0)
const dieselKmPerLitre = ref(0)
const dieselRoadUserCharges = ref(0)
const kWhCostHome = ref(0)
const kWhCostPublic = ref(0)
const costPublicPerMin = ref(0)
const electricRoadUserCharges = ref(0)
const perAnnumMaintenanceEV = ref(0)
const perAnnumMaintenanceCurrentVehicle = ref(0)

const initialRange = computed(() => [
  Math.round(store.state.SOCMin * 100),
  Math.round(store.state.SOCMax * 100),
])

const initialExtraWeight = computed(() => store.state.extraWeight)

const initialRelativeSpeed = computed(() => store.state.relativeSpeed * 100)

const initialPassengers = computed(() => store.state.passengers)

const initialSOCAct = computed(() => store.state.SOCAct)

const initialSOCEnd = computed(() => store.state.SOCEnd)

const initialCalcVs = computed(() => store.state.calcVs)

const initialPetrolCost = computed(() => to2DP(store.state.petrolCostPerLitre))

const initialPetrolKmPerLitre = computed(() => to2DP(store.state.petrolKmPerLitre))

const initialPetrolRoadUserCharges = computed(() => to2DP(store.state.petrolRoadUserCharges))

const initialDieselCost = computed(() => to2DP(store.state.dieselCostPerLitre))

const initialDieselKmPerLitre = computed(() => to2DP(store.state.dieselKmPerLitre))

const initialDieselRoadUserCharges = computed(() => to2DP(store.state.dieselRoadUserCharges))

const initialKWhCostHome = computed(() => to2DP(store.state.defaultHomeCostPerKWh))

const initialKWhCostPublic = computed(() => to2DP(store.state.defaultPublicCostPerKWh))

const initialCostPublicPerMin = computed(() => to2DP(store.state.defaultCostPerMinDC))

const initialElectricRoadUserCharges = computed(() => to2DP(store.state.electricRoadUserCharges))

const initialPerAnnumMaintenanceEV = computed(() => to2DP(store.state.perAnnumMaintenanceEV))

const initialPerAnnumMaintenanceCurrentVehicle = computed(() =>
  to2DP(store.state.perAnnumMaintenanceCurrentV),
)

const savingStatus = computed(() => store.state.settingsSavingStatus)

const departingChargeTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip details departing charge tooltip',
  ),
)

const arrivalChargeTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip details arrival charge tooltip',
  ),
)

const chargingRangeTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip details charging range tooltip',
  ),
)

const extraWeightTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip details extra weight tooltip',
  ),
)

const passengersTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'trip details passengers tooltip',
  ),
)

const petrolVehicleDefaultsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'savings settings petrol defaults tooltip',
  ),
)

const dieselVehicleDefaultsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'savings settings diesel defaults tooltip',
  ),
)

const electricVehicleDefaultsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'savings settings electric defaults tooltip',
  ),
)

const maintenanceDefaultsTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'savings settings maintenance defaults tooltip',
  ),
)

const speedAdjustmentTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'driver profile speed adjustment tooltip',
  ),
)

const accelerationAdjustmentTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'driver profile acceleration adjustment',
  ),
)

onMounted(async () => {
  await nextTick()
  displayRange.value = initialRange.value
  extraWeight.value = initialExtraWeight.value ?? 0
  relativeSpeed.value = initialRelativeSpeed.value ?? 100
  passengers.value = initialPassengers.value ?? 0
  startingCharge.value = initialSOCAct.value ? Math.round(initialSOCAct.value * 100) : 0
  endingCharge.value = initialSOCEnd.value ? Math.round(initialSOCEnd.value * 100) : 0
  calcVs.value = initialCalcVs.value ?? 'PETROL'
  petrolCostPerLitre.value = initialPetrolCost.value ?? 0
  petrolKmPerLitre.value = initialPetrolKmPerLitre.value ?? 0
  petrolRoadUserCharges.value = initialPetrolRoadUserCharges.value ?? 0
  dieselCostPerLitre.value = initialDieselCost.value ?? 0
  dieselKmPerLitre.value = initialDieselKmPerLitre.value ?? 0
  dieselRoadUserCharges.value = initialDieselRoadUserCharges.value ?? 0
  kWhCostHome.value = initialKWhCostHome.value ?? 0
  kWhCostPublic.value = initialKWhCostPublic.value ?? 0
  costPublicPerMin.value = initialCostPublicPerMin.value ?? 0
  electricRoadUserCharges.value = initialElectricRoadUserCharges.value ?? 0
  perAnnumMaintenanceEV.value = initialPerAnnumMaintenanceEV.value ?? 0
  perAnnumMaintenanceCurrentVehicle.value = initialPerAnnumMaintenanceCurrentVehicle.value ?? 0
  await nextTick()
  loading.value = false
})

// validation methods

function validateRange(val: number | string): boolean | string {
  const parsedVal = parseIntOrFloat(val)
  if (!parsedVal) return 'needs to be a number'
  if (parsedVal < 1 || parsedVal > 100) return 'needs to be 1-100'
  return true
}

function validateIsNumber(val: number | string | null): boolean | string {
  // allow no extra wight to pass validation
  if (val === null || val === 0 || val === '0') return true
  const parsedVal = parseIntOrFloat(val)
  if (!parsedVal) return 'needs to be a number'
  if (parsedVal < 0) return "can't be negative"
  return true
}

// event handlers

// function decrementVal(property: 'extraWeight' | 'passengers'): void {
//   // exit early if cant find property
//   const ref =
//     property === 'extraWeight' ? extraWeight : property === 'passengers' ? passengers : undefined
//   if (!ref) return
//   // parse value
//   const parsedVal = parseIntOrFloat(ref.value)
//   // exit early if parsing value failed or would be less than 1(would be less than 0 after decrement).
//   if (parsedVal === undefined || parsedVal < 1) return
//   // decrement property
//   ref.value = parsedVal - 1
//   flagAsDirty()
// }

// function incrementVal(property: 'extraWeight' | 'passengers'): void {
//   // exit early if cant find property
//   const ref =
//     property === 'extraWeight' ? extraWeight : property === 'passengers' ? passengers : undefined
//   if (!ref) return
//   // parse value
//   const parsedVal = parseIntOrFloat(ref.value)
//   // exit early if parsing value failed or would be less than 1(would be less than 0 after decrement).
//   if (parsedVal === undefined || parsedVal < 1) return
//   // decrement property
//   ref.value = parsedVal + 1
//   flagAsDirty()
// }

function flagAsDirty() {
  if (loading.value) return
  dirty.value = true
}

function handleSave() {
  const dataToUpdate: BatchUpdatableSettings = {
    passengers: passengers.value,
    extraWeight: extraWeight.value,
    SOCMin: to2DP(displayRange.value[0] / 100),
    SOCMax: to2DP(displayRange.value[1] / 100),
    SOCAct: to2DP(startingCharge.value / 100),
    SOCEnd: to2DP(endingCharge.value / 100),
    relativeSpeed: relativeSpeed.value / 100,
    petrolCostPerLitre: to2DP(petrolCostPerLitre.value),
    dieselCostPerLitre: to2DP(dieselCostPerLitre.value),
    dieselKmPerLitre: to2DP(dieselKmPerLitre.value),
    petrolKmPerLitre: to2DP(petrolKmPerLitre.value),
    petrolRoadUserCharges: to2DP(petrolRoadUserCharges.value),
    dieselRoadUserCharges: to2DP(dieselRoadUserCharges.value),
    defaultPublicCostPerKWh: to2DP(kWhCostPublic.value),
    defaultHomeCostPerKWh: to2DP(kWhCostHome.value),
    defaultCostPerMinDC: to2DP(costPublicPerMin.value),
    electricRoadUserCharges: to2DP(electricRoadUserCharges.value),
    perAnnumMaintenanceCurrentV: to2DP(perAnnumMaintenanceCurrentVehicle.value),
    perAnnumMaintenanceEV: to2DP(perAnnumMaintenanceEV.value),
    acceleration: accelerationAdjustment.value,
  }
  store.dispatch(ActionTypes.updatedSettingsData, dataToUpdate)
  dirty.value = false
}
</script>
<style scoped>
* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}

#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}
.right-aligned-input :deep(input) {
  text-align: right;
}

.none-underlined-input :deep(.v-input__slot::before) {
  border-style: none !important;
}

.off-set-input-message :deep(.v-messages__message) {
  padding-top: 16px;
}
</style>
