<template>
  <v-card flat class="bg-background">
    <v-card-text>
      <v-row align="center">
        <v-btn icon @click="() => emit('back')" flat color="transparent">
          <v-icon color="primary">mdi-chevron-left</v-icon>
        </v-btn>
        <span v-for="(item, index) in items" :key="item.text">
          <span v-if="index > 0" class="text-grey font-weight-bold"> / </span>
          <v-btn
            class="text-none"
            variant="text"
            @click="handelNavigation(item)"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-btn>
        </span>
        <v-spacer />
        <AvatarIconMenu small />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import AvatarIconMenu from '@/components/user/profile/AvatarIconMenu.vue'

export interface BreadcrumbsItem {
  text: string
  disabled?: boolean
}

const { items } = defineProps<{ items: BreadcrumbsItem[] }>()

const emit = defineEmits<{
  (e: 'navigation', item: BreadcrumbsItem): void
  (e: 'back'): void
}>()

function handelNavigation(item: BreadcrumbsItem) {
  emit('navigation', item)
}
</script>
