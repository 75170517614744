<template>
  <v-card flat class="pa-5">
    <!-- Recommended EVs section -->
    <v-card-title class="pt-5 text-tertiary" v-if="isShowingTrip"> Recommended EVs </v-card-title>
    <v-card-text v-if="isShowingTrip">
      <SuggestedEVsSection />
    </v-card-text>
    <!-- My Vehicles Section -->
    <v-card-title class="d-flex justify-space-between pt-5 text-tertiary">
      <span> My Vehicles </span>
      <OutlinedBtn @click="showCustom"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="customVehicles.length"
          :vehicle="customVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="customVehicles.length >= 2"
          :vehicle="customVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="customVehicles.length >= 3"
          :vehicle="customVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
    <!-- Telematics connected vehicles section -->
    <v-card-title class="d-flex justify-space-between pt-5 text-tertiary">
      <span> Connected Vehicles </span>
      <OutlinedBtn @click="showConnected"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="connectedVehicles.length"
          :vehicle="connectedVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="connectedVehicles.length >= 2"
          :vehicle="connectedVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="connectedVehicles.length >= 3"
          :vehicle="connectedVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
    <!-- Generic Evs -->
    <v-card-title class="d-flex justify-space-between pt-5 text-tertiary">
      <span> Generic EVs </span>
      <OutlinedBtn @click="showGeneric"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="genericVehicles.length"
          :vehicle="genericVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="genericVehicles.length >= 2"
          :vehicle="genericVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="genericVehicles.length >= 3"
          :vehicle="genericVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import SuggestedEVsSection from './SuggestedEVsSection.vue'
import OutlinedBtn from '../ui-elements/buttons/OutlinedBtn.vue'
import type Vehicle from '@/classes/vehicle_classes/vehicle'
import { MainDialogContent, GettersTypes } from '@/store/store_types'
import VehicleGalleryCard from './VehicleGalleryCard.vue'

const store = useStore()

const connectedVehicles = computed<Vehicle[]>(() => store.getters[GettersTypes.connectedVehicles])
const genericVehicles = computed<Vehicle[]>(() => store.getters[GettersTypes.genericVehicles])
const customVehicles = computed<Vehicle[]>(() => store.getters[GettersTypes.customVehicles])
// const rideShareAndRentals = computed(() => [])
const isShowingTrip = computed(() => !!store.state.selectedTrip)

const handleSelect = (vehicle: Vehicle) => {
  store.dispatch('selectVehicle', vehicle)
  store.commit('setMainDialogContent', undefined)
}

const showConnected = () => {
  store.commit('setMainDialogContent', MainDialogContent.VEHICLE_GALLERY_CONNECTED)
}

const showCustom = () => {
  store.commit('setMainDialogContent', MainDialogContent.VEHICLE_GALLERY_CUSTOM)
}

const showGeneric = () => {
  store.commit('setMainDialogContent', MainDialogContent.VEHICLE_GALLERY_GENERIC)
}
</script>
