/** Base Url for the Charger Database's API. */
const baseUrl = 'https://chargerdb.pt.appstack.me/api/'

// endpoints

/** Charger database endpoint to get all chargers */
export const ALL_CHARGERS_ENDPOINT = baseUrl + 'locations'

/**
 * Charger database endpoint to get EV models.
 *
 * If a query is provided, the endpoint will return the EV models that match the query.
 *
 * If no query is provided, the endpoint will return all EV models.
 */
export const ALL_EV_MODELS_ENDPOINT = baseUrl + 'vehicles'
