import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import {
  MAPS_LITTLE_MONKEY_API_KEY,
  MAPS_LITTLE_MONKEY_REVERSE_GEO_CODE_ENDPOINT,
} from '@/api/clients/maps-little-monkey-client'
import type { MapsReverseGeocodeRes } from '@/types/maps_little_monkey_types'

/**
 * Reverse geocode a given coordinate and provide the closest address to that location.
 *
 * @param latitude the latitude of the coordinate to reverse geocode.
 * @param longitude the longitude of the coordinate to reverse geocode.
 * @returns a `MapsReverseGeocodeRes` object if successful undefined if unsuccessful.
 */
export async function reverseGeocode(
  latitude: number,
  longitude: number,
): Promise<MapsReverseGeocodeRes | undefined> {
  try {
    const response = await fetch(
      `${MAPS_LITTLE_MONKEY_REVERSE_GEO_CODE_ENDPOINT}?api_key=${MAPS_LITTLE_MONKEY_API_KEY}&lat=${latitude}&lon=${longitude}&format=json`,
    )
    const convertedResponse: MapsReverseGeocodeRes = await response.json()
    return convertedResponse
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
    return
  }
}
