<template>
  <v-col cols="12" sm="6" md="4">
    <v-card
      height="100%"
      max-height="350px"
      min-height="200px"
      class="d-flex flex-column justify-center rounded-lg bg-secondary"
      @click="handleAddVehicle"
    >
      <v-icon size="70" color="white">mdi-plus</v-icon>
    </v-card>
  </v-col>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { MainDialogContent, MutationTypes } from '@/store/store_types'

const store = useStore()

function handleAddVehicle() {
  store.commit(MutationTypes.setMainDialogContent, MainDialogContent.ADD_VEHICLE)
}
</script>
