<template>
  <v-card flat>
    <DashboardBreadcrumbs
      v-if="mdAndUp"
      :items="
        showingTrip
          ? [{ text: 'Home' }, { text: 'Saved trips' }, { text: 'Trip details', disabled: true }]
          : [{ text: 'Home' }, { text: 'Saved trips', disabled: true }]
      "
      @navigation="breadcrumbNavigation"
      @back="breadcrumbNavigation({ text: 'Home' })"
    />
    <TripDetailsPage
      v-if="showingTrip"
      :trip="typedShowingTrip"
      @back="showingTrip = undefined"
      @view-trip="close"
      @view-stats="close"
    />
    <v-card v-else flat class="p7-5 pa-md-5 bg-background">
      <v-card-title> Unsaved trips </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title> Saved trips </v-card-title>
      <FrequentTripSavings />
      <v-text-field
        v-model="searchValue"
        bg-color="background"
        clearable
        placeholder="Search saved trips..."
        class="mx-4"
      />
      <StoredTripsGroupCard
        v-for="(group, groupIndex) in savedTripsGroupedByEV"
        :key="`saved-trip-group-${groupIndex}`"
        :groupedTrips="group"
        savedStyle
        @view-trip="showingTrip = $event"
      />
      <v-card v-if="!savedTrips.length" flat>
        <v-card-text class="pt-0"> You currently have no saved trips. </v-card-text>
      </v-card>
      <v-card v-else-if="!filteredSavedTrips.length && searchValue" flat>
        <v-card-text class="pt-0"> No trips match your search query. </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>
<script lang="ts" setup>
import UnsavedTripCarousel from '../trips/history/UnsavedTripCarousel.vue'
import FrequentTripSavings from '../trips/statistics/FrequentTripSavings.vue'
import Trip from '@/classes/trip_classes/trip'
import { GettersTypes, MutationTypes, type State } from '@/store/store_types'
import DashboardBreadcrumbs, { type BreadcrumbsItem } from './DashboardBreadcrumbs.vue'
import TripDetailsPage from './TripDetailsPage.vue'
import type { SortedTripsGroup } from '@/types/sheared_local_types'
import StoredTripsGroupCard from '../trips/history/StoredTripsGroupCard.vue'
import sortTripsViaEVGroups from '@/utils/sortTripsViaEVGroups'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useDisplay } from 'vuetify'

const emit = defineEmits(['close'])
const { mdAndUp } = useDisplay()
const store = useStore<State>()

const showingTrip = ref<Trip | undefined>(undefined)
const typedShowingTrip = computed(() => showingTrip.value as Trip) // Warning: only use if showingTrip is defined. Workaround foe TS cant match class private properties see https://github.com/microsoft/TypeScript/issues/42683 for unhelpful but working as intended behaviour

const searchValue = ref<string | undefined>(undefined)
const savedTrips = computed<Trip[]>(() => store.getters[GettersTypes.savedTrips])
const filteredSavedTrips = computed<Trip[]>((): Trip[] => {
  if (!searchValue.value) return savedTrips.value
  const sanitizedSearchValue = (searchValue.value || '').toString().toLocaleLowerCase().trim()
  return savedTrips.value.filter((trip) => {
    // check for trip name match
    if (trip.name && trip.name.toLowerCase().includes(sanitizedSearchValue)) return true

    // check for origin match
    if (trip.origin) {
      if (trip.origin.address && trip.origin.address.toLowerCase().includes(sanitizedSearchValue))
        return true
      if (trip.origin.name && trip.origin.name.toLowerCase().includes(sanitizedSearchValue))
        return true
    }

    // check for destination match
    if (trip.destination) {
      if (
        trip.destination.address &&
        trip.destination.address.toLowerCase().includes(sanitizedSearchValue)
      )
        return true
      if (
        trip.destination.name &&
        trip.destination.name.toLowerCase().includes(sanitizedSearchValue)
      )
        return true
    }

    // check for waypoint match
    for (const waypoint of trip.waypoints) {
      if (waypoint.address && waypoint.address.toLowerCase().includes(sanitizedSearchValue))
        return true
      if (waypoint.name && waypoint.name.toLowerCase().includes(sanitizedSearchValue)) return true
    }

    // check for vehicle match
    if (trip.vehicle) {
      if (trip.vehicle.name && trip.vehicle.name.toLowerCase().includes(sanitizedSearchValue))
        return true
      if (
        trip.vehicle.evModel &&
        trip.vehicle.evModel.name &&
        trip.vehicle.evModel.name.toLowerCase().includes(sanitizedSearchValue)
      )
        return true
    }

    // no match found
    return false
  })
})
const savedTripsGroupedByEV = computed<SortedTripsGroup[]>(() =>
  sortTripsViaEVGroups(filteredSavedTrips.value),
)

function breadcrumbNavigation(item: BreadcrumbsItem) {
  if (item.disabled) return
  if (item.text === 'Home') {
    store.commit(MutationTypes.setDashboardDialogContent, 'home')
  } else if (item.text === 'Saved trips') {
    showingTrip.value = undefined
  }
}

function close() {
  showingTrip.value = undefined
  emit('close')
}
</script>
